import * as React from 'react';

export default ({fillColor = 'white'}) => (
  <svg width="15" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.49998 8.0878C0.978899 8.0878 0.542705 7.65838 0.491285 7.09477L0.00743135 1.79121C-0.074314 0.895199 0.527697 0.0968074 1.35206 0.00795695C1.40125 0.00265556 1.45065 0 1.50007 0C2.32846 0 3 0.729909 3 1.6303C3 1.68403 2.99756 1.73774 2.99268 1.79121L2.5087 7.09477C2.45727 7.65838 2.02107 8.0878 1.49998 8.0878Z"
      fill={fillColor}
    />
    <path
      d="M1.49989 12C2.27863 12 2.90993 11.3154 2.90993 10.4708C2.90993 9.62625 2.27863 8.9416 1.49989 8.9416C0.721141 8.9416 0.0898438 9.62625 0.0898438 10.4708C0.0898438 11.3154 0.721141 12 1.49989 12Z"
      fill={fillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.49998 8.0878C5.9789 8.0878 5.5427 7.65838 5.49129 7.09477L5.00743 1.79121C4.92569 0.895199 5.5277 0.0968074 6.35206 0.00795695C6.40125 0.00265556 6.45065 0 6.50007 0C7.32846 0 8 0.729909 8 1.6303C8 1.68403 7.99756 1.73774 7.99268 1.79121L7.5087 7.09477C7.45727 7.65838 7.02107 8.0878 6.49998 8.0878Z"
      fill={fillColor}
    />
    <path
      d="M6.49989 12C7.27863 12 7.90993 11.3154 7.90993 10.4708C7.90993 9.62625 7.27863 8.9416 6.49989 8.9416C5.72114 8.9416 5.08984 9.62625 5.08984 10.4708C5.08984 11.3154 5.72114 12 6.49989 12Z"
      fill={fillColor}
    />
  </svg>
);
