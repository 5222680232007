import gql from 'graphql-tag';
import {shiftFragmentStringWithRole} from 'src/gql/fragment/ShiftFragment';
import {userFragmentString} from 'src/gql/fragment/GeneralUserFragment';
import ShiftRequestNoteFragment from 'src/gql/fragment/ShiftRequestNoteFragment';

export default gql`
  fragment MarketplacePostsFragment on Marketplace {
    id
    offerTypes
    shift { 
      ${shiftFragmentStringWithRole}
    }
    user {
      ${userFragmentString}
    }
    status
    createdAt
    updatedAt
    notes {
      ...ShiftRequestNoteFragment
    }
  }
  ${ShiftRequestNoteFragment}
`;
