import gql from 'graphql-tag';
import EscalationLadderFragment from 'src/gql/fragment/EscalationLadderFragment';

export default gql`
  query EscalationLadders($departmentId: Int!) {
    locating {
      department(id: $departmentId) {
        id
        escalationLadders {
          ...EscalationLadderFragment
        }
      }
    }
  }
  ${EscalationLadderFragment}
`;
