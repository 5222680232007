import React from 'react';
import ContentLoader from 'react-content-loader';
// import AppTheme from 'src/assets/styles/theme';

export default (props) => (
  <ContentLoader speed={2} {...props}>
    <rect x="6" y="10" rx="10" ry="10" width="340" height="35" />
    <rect x="6" y="60" rx="10" ry="10" width="340" height="35" />
    <rect x="6" y="110" rx="10" ry="10" width="340" height="35" />
    <rect x="6" y="160" rx="10" ry="10" width="340" height="35" />
    <rect x="6" y="210" rx="10" ry="10" width="340" height="35" />
    <rect x="6" y="260" rx="10" ry="10" width="340" height="35" />
  </ContentLoader>
);
