import {v4 as uuid} from 'uuid';

export default class MessageObject {
  uniqueId;
  message;
  chatId;
  selfId;
  templateData;
  responseData;
  messageId;
  messagePriority;
  consultData;
  files;

  constructor(message, messagePriority, chatId, selfId, templateData, consultData, files, responseData, messageId) {
    this.uniqueId = uuid();
    this.message = message;
    this.templateData = templateData ? templateData : null;
    this.messagePriority = messagePriority;
    this.chatId = chatId;
    this.selfId = selfId;
    this.consultData = consultData ? consultData : null;
    this.responseData = responseData ? responseData : null;
    this.messageId = messageId;
    if (files) this.files = files.map((file) => Object.assign(file, {id: uuid()}));
    else this.files = null;
  }
}
