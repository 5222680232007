import gql from 'graphql-tag';
import ActiveEscalationsFragment from 'src/gql/fragment/ActiveEscalationFragment';

export default gql`
  mutation AcknowledgeEscalation($activatedEscalationId: Int!) {
    escalation {
      activated(activatedEscalationId: $activatedEscalationId) {
        acknowledge {
          ...ActiveEscalationsFragment
        }
      }
    }
  }
  ${ActiveEscalationsFragment}
`;
