import React, {useState} from 'react';
import {PaginatedUsers} from 'src/gql/v2/query/FetchPaginatedUsersQuery';
export const usePaginatedSearchState = () => {
  const [searchUserData, setSearchUserData] = useState<PaginatedUsers | null>(null);

  const [localSearchText, setLocalSearchText] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);

  return {
    searchUserData,
    setSearchUserData,
    localSearchText,
    searchLoading,
    setLocalSearchText,
    setSearchLoading,
  };
};
