import React, {createContext, useContext, useState} from 'react';
import {UserAddress} from 'src/types';

interface IProfilePageFormFields {
  changedVisibilityAddresses: UserAddress[];
  setChangedVisibilityAddresses: React.Dispatch<React.SetStateAction<UserAddress[]>>;
  deletedAddresses: UserAddress[];
  setDeletedAddresses: React.Dispatch<React.SetStateAction<UserAddress[]>>;
  editedLabelAddresses: UserAddress[];
  setEditedLabelAddresses: React.Dispatch<React.SetStateAction<UserAddress[]>>;
}

const ProfilePageFormContext = createContext<IProfilePageFormFields>({
  changedVisibilityAddresses: [],
  setChangedVisibilityAddresses: () => {},
  deletedAddresses: [],
  setDeletedAddresses: () => {},
  editedLabelAddresses: [],
  setEditedLabelAddresses: () => {},
});

export const ProfilePageFormFieldsProvider = ({children}) => {
  const [changedVisibilityAddresses, setChangedVisibilityAddresses] = useState<UserAddress[]>([]);
  const [deletedAddresses, setDeletedAddresses] = useState<UserAddress[]>([]);
  const [editedLabelAddresses, setEditedLabelAddresses] = useState<UserAddress[]>([]);

  return (
    <ProfilePageFormContext.Provider
      value={{
        changedVisibilityAddresses,
        setChangedVisibilityAddresses,
        deletedAddresses,
        setDeletedAddresses,
        editedLabelAddresses,
        setEditedLabelAddresses,
      }}
    >
      {children}
    </ProfilePageFormContext.Provider>
  );
};

export const useProfilePageFormContext = () => {
  return useContext(ProfilePageFormContext);
};

export default ProfilePageFormContext;
