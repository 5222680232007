import gql from 'graphql-tag';

export const FETCH_FAVOURITE_ROLE = gql`
  query FavouritedRolesForSite($organizationalUnit: OrganizationalUnitInput!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        ... on Site {
          id
          name
          favouritedRoles {
            id
            name
            department {
              id
              name
            }
            pagerNumber
            startTime
            displayPosition
            updatedAt
            endTime
            currentShift {
              ...ShiftFields
            }
            nextShift {
              ...ShiftFields
            }
            notes {
              id
              note
              access
              updatedAt
            }
          }
        }
      }
    }
  }

  fragment ShiftFields on Shift {
    id
    startDate
    endDate
    user {
      ... on Error {
        message
      }
      ...NamePartsWithAvatarUserFields
      ... on Node {
        id
      }
      ... on OrganizationMember {
        workStatus {
          value
        }
      }
    }
  }

  fragment NamePartsWithAvatarUserFields on GeneralUser {
    ...NamePartsUserFields
    profilePic: avatar {
      id
      url
      fileName
    }
  }

  fragment NamePartsUserFields on GeneralUser {
    username
    firstname: firstName
    lastname: lastName
  }
`;
