import gql from 'graphql-tag';

export default gql`
  query search($text: String!, $continuationId: ID, $limit: Int, $direction: PaginationDirection) {
    searchQuery(text: $text) {
      searchChatMembers(continuationId: $continuationId, limit: $limit, direction: $direction) {
        continuationId
        totalResultsCount
        chats {
          chat {
            id
            title
            type
            image {
              id
              url
            }
            members {
              id
              firstName
              lastName
              role
            }
          }
          matchedSequences
        }
      }
    }
  }
`;
