import gql from 'graphql-tag';

export type CreateSelfProfileFieldsMutationResult = {
  selfMutation: {
    addFields: Array<{
      id: string;
      fields: Array<{
        id: string;
        label: string;
        __typename: string;
      }>;
    }>;
  };
};

export const CreateSelfProfileFieldsMutation = gql`
  mutation CreateProfileFields($sectionUniqueId: ID!, $fields: [CreateProfileFieldInput!]!) {
    selfMutation {
      profileSection(id: $sectionUniqueId) {
        addFields(fields: $fields) {
          ... on ProfileSection {
            id
            fields {
              ... on Node {
                id
              }
              label
              __typename
            }
          }
        }
      }
    }
  }
`;
