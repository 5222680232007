import gql from 'graphql-tag';

export default gql`
  fragment FileAttachmentFragment on File {
    id
    url
    mimeType
    fileName
  }
`;
