import React from 'react';
import * as R from 'ramda';
import {QueryResult} from 'react-apollo';
import {useQuery} from '@apollo/react-hooks';
import {getFullName} from 'src/utils/user';
import FetchContactList from 'src/gql/query/FetchContactList';
import {ContactQueryResult} from 'src/types/Contact';
import {User as Contact} from 'src/types/User';

interface Props {
  searchKeyword: string;
}

const useFetchContactList = ({searchKeyword}: Props) => {
  const {loading, error, data}: QueryResult<ContactQueryResult> = useQuery(FetchContactList, {
    fetchPolicy: 'no-cache',
  });

  const contactResultList: Contact[] = React.useMemo(() => {
    if (!data || !data.colleagues) return [];

    const sortContactsAlphabetically = R.sortBy(R.compose(R.prop('lastname')));

    if (Boolean(searchKeyword)) {
      const filteredContactList = data.colleagues.filter((contact) => {
        return (
          contact.role?.toLowerCase().includes(searchKeyword) ||
          getFullName(contact).toLowerCase().includes(searchKeyword)
        );
      });
      return sortContactsAlphabetically(filteredContactList);
    }
    return sortContactsAlphabetically(data.colleagues);
  }, [data, searchKeyword]);

  return {
    loading,
    error,
    contactResultList,
  };
};

export default useFetchContactList;
