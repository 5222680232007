import * as React from 'react';

export default (props) => (
  <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.41947 1.16366C7.80632 0.792711 8.41684 0.792712 8.80369 1.16366L15.3626 7.45297C15.6738 7.7513 15.6738 8.2487 15.3626 8.54702C15.0695 8.82816 14.6068 8.82816 14.3136 8.54702L8.11158 2.6L1.90959 8.54703C1.6164 8.82816 1.1537 8.82816 0.860517 8.54703C0.549398 8.2487 0.549399 7.7513 0.860517 7.45297L7.41947 1.16366Z"
      fill={props.color || '#9B9B9B'}
    />
  </svg>
);
