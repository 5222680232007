import React from 'react';
import ContentLoader from 'react-content-loader';

interface Props {
  viewBox: string;
  height?: string;
  width?: string;
}
export const RoleNoteLoader = (props: Props) => {
  return (
    <ContentLoader backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props} speed={1}>
      <rect x="3" y="8" rx="3" ry="3" width="375" height="14" />
      <rect x="3" y="30" rx="3" ry="3" width="375" height="14" />
    </ContentLoader>
  );
};
