import * as React from 'react';

export default ({styles = {}}: {styles?: React.CSSProperties}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles}>
    <g clip-path="url(#clip0_748_2586)">
      <path
        d="M8 4C8.79113 4 9.56449 4.2346 10.2223 4.67412C10.8801 5.11365 11.3928 5.73836 11.6955 6.46927C11.9983 7.20017 12.0775 8.00444 11.9231 8.78036C11.7688 9.55629 11.3878 10.269 10.8284 10.8284C10.269 11.3878 9.55629 11.7688 8.78036 11.9231C8.00444 12.0775 7.20017 11.9983 6.46927 11.6955C5.73836 11.3928 5.11365 10.8801 4.67412 10.2223C4.2346 9.56448 4 8.79113 4 8C4.00119 6.9395 4.423 5.92278 5.17289 5.17289C5.92278 4.423 6.9395 4.00119 8 4ZM8 2C6.81331 2 5.65328 2.35189 4.66658 3.01118C3.67989 3.67047 2.91085 4.60754 2.45673 5.7039C2.0026 6.80026 1.88378 8.00666 2.11529 9.17054C2.3468 10.3344 2.91825 11.4035 3.75736 12.2426C4.59648 13.0818 5.66558 13.6532 6.82946 13.8847C7.99335 14.1162 9.19975 13.9974 10.2961 13.5433C11.3925 13.0892 12.3295 12.3201 12.9888 11.3334C13.6481 10.3467 14 9.18669 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_748_2586">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
