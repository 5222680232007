import * as React from 'react';

export default (props) => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.12 0.175C3.215 0.0639311 3.35384 -9.61567e-06 3.5 0H12.5C12.6462 -9.61567e-06 12.785 0.0639311 12.88 0.175L15.639 3.394C15.8719 3.66583 16 4.01201 16 4.37V4.5H15V4.37C15 4.25083 14.9575 4.13557 14.88 4.045L12.27 1H3.73L1.12 4.045C1.04255 4.13557 0.999991 4.25083 1 4.37V4.5H0V4.37C1.25931e-05 4.01201 0.128057 3.66583 0.361 3.394L3.121 0.174L3.12 0.175Z"
      fill="black"
    />
    <path
      d="M2.375 5.875C3.135 5.875 3.75 5.259 3.75 4.5H4.75C4.75 5.25939 5.36561 5.875 6.125 5.875C6.88439 5.875 7.5 5.25939 7.5 4.5H8.5C8.5 5.25939 9.11561 5.875 9.875 5.875C10.6344 5.875 11.25 5.25939 11.25 4.5H12.25C12.25 5.25939 12.8656 5.875 13.625 5.875C14.3844 5.875 15 5.25939 15 4.5H16C16.0001 5.51474 15.3555 6.41746 14.3957 6.74674C13.4358 7.07602 12.3728 6.75911 11.75 5.958C11.3004 6.53712 10.6082 6.87567 9.875 6.875C9.1418 6.87582 8.44951 6.53724 8 5.958C7.55049 6.53724 6.8582 6.87582 6.125 6.875C5.3918 6.87582 4.69951 6.53724 4.25 5.958C3.62717 6.75911 2.56416 7.07602 1.60432 6.74674C0.64449 6.41746 -0.000111962 5.51474 0 4.5H1C1 5.26 1.616 5.875 2.375 5.875Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25 5C4.52614 5 4.75 4.77614 4.75 4.5C4.75 4.22386 4.52614 4 4.25 4C3.97386 4 3.75 4.22386 3.75 4.5C3.75 4.77614 3.97386 5 4.25 5ZM8 5C8.27614 5 8.5 4.77614 8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5C7.5 4.77614 7.72386 5 8 5ZM12.25 4.5C12.25 4.77614 12.0261 5 11.75 5C11.4739 5 11.25 4.77614 11.25 4.5C11.25 4.22386 11.4739 4 11.75 4C12.0261 4 12.25 4.22386 12.25 4.5ZM2 14V7.854C1.647 7.815 1.311 7.723 1 7.583V14H0.5C0.223858 14 0 14.2239 0 14.5C0 14.7761 0.223858 15 0.5 15H15.5C15.7761 15 16 14.7761 16 14.5C16 14.2239 15.7761 14 15.5 14H15V7.584C14.6827 7.72524 14.3453 7.81632 14 7.854V14H2ZM2 6.846V6H1V6.437C1.291 6.644 1.632 6.787 2 6.846ZM14 6.846C14.368 6.787 14.709 6.644 15 6.437V6H14V6.846Z"
      fill="black"
    />
    <path d="M4 12V8H3V12C3 12.5523 3.44772 13 4 13H12C12.5523 13 13 12.5523 13 12V8H12V12H4Z" fill="black" />
  </svg>
);
