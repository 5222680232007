import gql from 'graphql-tag';

export default gql`
  query search(
    $text: String!
    $messageContinuationId: Int
    $chatContinuationId: ID
    $chatLimit: Int
    $messageLimit: Int
    $direction: PaginationDirection
  ) {
    searchQuery(text: $text) {
      searchChats(continuationId: $chatContinuationId, limit: $chatLimit, direction: $direction) {
        continuationId
        totalResultsCount
        chats {
          chat {
            id
            title
            type
            image {
              id
              url
            }
            members {
              id
              role
              firstName
              lastName
            }
          }
          searchMessages(continuationId: $messageContinuationId, limit: $messageLimit, direction: $direction) {
            continuationId
            totalResultsCount
            messages {
              message {
                id
                message
                type
                dateCreated
              }
              matchedSequences
            }
          }
        }
      }
    }
  }
`;
