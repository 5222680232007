import {RetryLink} from 'apollo-link-retry';
import {USER_AUTH_EXPIRATION_NAMES} from 'src/constants/user';

// note: retry link is only for networkErrors
// https://www.apollographql.com/docs/link/links/retry/
const retryLink = new RetryLink({
  delay: {
    initial: 500,
  },
  attempts: {
    max: 2,
    retryIf: (error, _operation) => {
      // this is for retrying none auth-related errors
      // such errors and token refresh are handled in graphql errors in errorLink
      if (
        error.result &&
        error.result.errors &&
        error.result.errors[0] &&
        USER_AUTH_EXPIRATION_NAMES.includes(error.result.errors[0].name)
      )
        return !error;

      return !!error;
    },
  },
});

export default retryLink;
