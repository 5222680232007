// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
// https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc

export const IMAGETYPEARRAY = ['image/png', 'image/jpeg', 'image/gif', 'image/tiff', 'image/bmp', 'image/heic'];

export const FILETYPEARRAY = [
  // microsoft office types:
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  'application/vnd.ms-access',
  // end of microsoft office types
  'application/pdf',
  'application/json',
  'video/mp4',
  'video/quicktime',
  'audio/mpeg',
  // chromium bug: https://bugs.chromium.org/p/chromium/issues/detail?id=227004
  'audio/mp3',
  'text/plain',
  'audio/m4a',
  'audio/x-m4a',
  'audio/mp4',
  ...IMAGETYPEARRAY,
];

export const IMAGEFILETYPES = IMAGETYPEARRAY.join(', ');
export const FILETYPES = FILETYPEARRAY.join(', ');

// 50mib
export const FILESIZELIMIT = 52428800;

export const UPLOAD_PROFILE_PICTURE = 'profile';

export const UPLOAD_CHAT_PICTURE = 'chat';
