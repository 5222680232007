import gql from 'graphql-tag';

export const CREATE_STA_USER_MUTATION = gql`
  mutation CreateAccount(
    $challengeId: String!
    $organizationUrl: URL!
    $userDetails: SignupUserDetails!
    $inviteCode: String
  ) {
    createAccount(
      challengeId: $challengeId
      organizationUrl: $organizationUrl
      userDetails: $userDetails
      inviteCode: $inviteCode
    ) {
      ... on SelfUser {
        id
        firstName
        lastName
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;
