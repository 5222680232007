import React from 'react';
import {SignupProgressContainer, Progress} from '../styled/signup.styled';

interface ISignupProgressProps {
  value: number;
}

export const SignupProgress = (props: ISignupProgressProps) => {
  return (
    <SignupProgressContainer>
      <Progress style={{width: `${props.value}px`}} />
    </SignupProgressContainer>
  );
};
