import * as React from 'react';

export default ({styles = {}}: {styles?: React.CSSProperties}) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_879_2606)">
      <path
        d="M8.29289 6.33211C8.68342 5.94158 9.31658 5.94158 9.70711 6.33211L14.2313 10.8563C14.4487 11.0737 14.4487 11.4263 14.2313 11.6437C14.0138 11.8612 13.6612 11.8612 13.4438 11.6437L9 7.2L4.55625 11.6437C4.33879 11.8612 3.98621 11.8612 3.76875 11.6437C3.55129 11.4263 3.55129 11.0737 3.76875 10.8562L8.29289 6.33211Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_879_2606">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
