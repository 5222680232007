import * as React from 'react';

export default (props) => (
  <svg
    width={props.width || '12'}
    height={props.height || '8'}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.70711 7.04289C6.31658 7.43342 5.68342 7.43342 5.29289 7.04289L0.1875 1.9375C-0.054125 1.69587 -0.0541245 1.30412 0.1875 1.0625C0.429125 0.820875 0.820875 0.820875 1.0625 1.0625L6 6L10.9375 1.0625C11.1791 0.820875 11.5709 0.820875 11.8125 1.0625C12.0541 1.30412 12.0541 1.69587 11.8125 1.9375L6.70711 7.04289Z"
      fill={props.color || '#00859A'}
    />
  </svg>
);
