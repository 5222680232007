import gql from 'graphql-tag';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';
import {LocatingRoleFragment} from 'src/gql/fragment/RoleFragment';

export const FETCH_CODE_TEAM_FOR_DEPARTMENT = gql`
  query FetchTeamsForDepartment($departmentId: Int!) {
    locating {
      department(id: $departmentId) {
        id
        teams {
          ...CodeFragment
        }
      }
    }
  }

  fragment CodeFragment on Code {
    id
    name
    members {
      user {
        ...GeneralUserFragment
        __typename
      }
      role {
        ...LocatingRoleFragment
        __typename
      }
    }
  }

  ${GeneralUserFragment}
  ${LocatingRoleFragment}
`;
