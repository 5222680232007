import gql from 'graphql-tag';
import ActivatedLevelFragment from 'src/gql/fragment/ActivatedLevelFragment';
import EscalationLadderFragment from 'src/gql/fragment/EscalationLadderFragment';
import EscalationMembersFragment from 'src/gql/fragment/EscalationMembersFragment';
import EscalatedUsersFragment from 'src/gql/fragment/EscalatedUsersFragment';

// must be a subset of default exported ActiveEscalationsFragment
export const CustomChatsEscalationFragment = gql`
  fragment CustomChatsEscalationFragment on ActiveEscalation {
    id
    state
    type
    updatedAt
    endedAt
    endedBy {
      id
      firstname
      lastname
    }
    escalationLadder {
      id
      name
      department {
        id
        name
      }
    }
    escalatedUsers {
      ...EscalatedUsersFragment
    }
    activatedLevel {
      ...ActivatedLevelFragment
    }
    message {
      id
      message
      type
    }
  }
  ${EscalatedUsersFragment}
  ${ActivatedLevelFragment}
`;

export default gql`
  fragment ActiveEscalationsFragment on ActiveEscalation {
    id
    type
    state
    startingLevel
    activatedBy {
      id
      firstname
      lastname
    }
    activatedAt
    updatedAt
    endedAt
    endedThrough
    endedBy {
      id
      firstname
      lastname
    }
    chat {
      id
      title
    }
    escalatedUsers {
      ...EscalatedUsersFragment
    }
    escalationLadder {
      ...EscalationLadderFragment
    }
    activatedLevel {
      ...ActivatedLevelFragment
    }
    activatedLevels {
      ...ActivatedLevelFragment
    }
    message {
      id
      message
      type
    }
  }
  ${EscalationLadderFragment}
  ${EscalationMembersFragment}
  ${EscalatedUsersFragment}
  ${ActivatedLevelFragment}
`;
