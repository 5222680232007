import gql from 'graphql-tag';
import ActiveEscalationsFragment from 'src/gql/fragment/ActiveEscalationFragment';

export default gql`
  mutation ActivateIndividualEscalation($userId: ID!, $activatedChatId: ID!, $messageId: Int!, $message: String) {
    escalation {
      activatePersonalEscalation(
        userId: $userId
        chatId: $activatedChatId
        messageId: $messageId
        customMessage: $message
      ) {
        ...ActiveEscalationsFragment
      }
    }
  }
  ${ActiveEscalationsFragment}
`;
