import gql from 'graphql-tag';

// better backend fetch response time without addresses
export const userFragmentString = `
  id
  username
  firstname
  lastname
  profilePic {
    url
  }
  role
  status
  workStatus
  statusExpiryDate
  statusDescription
  workStatusProxy {
    id
    firstname
    lastname
    username
    status
  }
`;

export const userFragmentStringWithAddresses = `
  ${userFragmentString}
  addresses {
    type
    ordinal
    access
    address
  }
`;

export default gql`
  fragment GeneralUserFragment on GeneralUser {
    ${userFragmentString}
  }
`;
