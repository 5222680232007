import React from 'react';
import ContentLoader from 'react-content-loader';

export const ChatInformationAddressLoader = () => {
  return (
    <ContentLoader width={400} height={300} backgroundColor="#dadada" foregroundColor="#fafafa" speed={1}>
      <rect x="45" y="0" rx="5" ry="5" width="225" height="16" />
      <rect x="0" y="0" rx="5" ry="5" width="29" height="16" />

      <rect x="45" y="50" rx="5" ry="5" width="225" height="16" />
      <rect x="0" y="50" rx="5" ry="5" width="29" height="16" />

      <rect x="45" y="100" rx="5" ry="5" width="225" height="16" />
      <rect x="0" y="100" rx="5" ry="5" width="29" height="16" />

      <rect x="45" y="150" rx="5" ry="5" width="225" height="16" />
      <rect x="0" y="150" rx="5" ry="5" width="29" height="16" />

      <rect x="45" y="200" rx="5" ry="5" width="225" height="16" />
      <rect x="0" y="200" rx="5" ry="5" width="29" height="16" />

      <rect x="45" y="250" rx="5" ry="5" width="225" height="16" />
      <rect x="0" y="250" rx="5" ry="5" width="29" height="16" />

      <rect x="45" y="300" rx="5" ry="5" width="225" height="16" />
      <rect x="0" y="300" rx="5" ry="5" width="29" height="16" />
    </ContentLoader>
  );
};
