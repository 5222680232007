import gql from 'graphql-tag';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';

export default gql`
  mutation setChatPicture(
    $imageId: Int! #fileId
  ) {
    user {
      updateProfilePicture(imageId: $imageId) {
        ...GeneralUserFragment
      }
    }
  }
  ${GeneralUserFragment}
`;
