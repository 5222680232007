import gql from 'graphql-tag';
import {userFragmentStringWithAddresses} from 'src/gql/fragment/GeneralUserFragment';

export default gql`
  mutation AddMembers($chatId: ID!, $userIds: [ID!]!) {
    chat(chatId: $chatId) {
      addMembers(userIds: $userIds) {
        members {
          privilege
          ${userFragmentStringWithAddresses}
        }
      }
    }
  }
`;
