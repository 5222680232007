import gql from 'graphql-tag';

export default gql`
  mutation RegisterPushToken(
    $environment: PushServiceEnvironment = sandbox
    $pushService: PushService!
    $token: String!
    $deviceId: ID!
  ) {
    selfMutation {
      device(id: $deviceId) {
        registerPushToken(token: $token, pushService: $pushService, environment: $environment) {
          secret
        }
      }
    }
  }
`;
