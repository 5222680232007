import gql from 'graphql-tag';
import FileAttachmentFragment from 'src/gql/fragment/FileAttachmentFragment';

export default gql`
  query GetMessageAttachmentById($chatId: ID!, $messageId: Int!) {
    chat(chatId: $chatId) {
      id
      message(id: $messageId) {
        id
        attachment {
          ...FileAttachmentFragment
          blob(encoding: "base64")
        }
      }
    }
  }
  ${FileAttachmentFragment}
`;
