import React from 'react';

export const ChevronRight = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1639_1824)">
      <path
        d="M15.7931 11.7704C16.1836 12.161 16.1836 12.7941 15.7931 13.1846L9.5252 19.4525C9.23525 19.7425 8.76514 19.7425 8.4752 19.4525C8.18525 19.1626 8.18525 18.6925 8.4752 18.4025L14.4002 12.4775L8.4752 6.55254C8.18525 6.26259 8.18525 5.79249 8.47519 5.50254C8.76514 5.21259 9.23525 5.21259 9.5252 5.50254L15.7931 11.7704Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1639_1824">
        <rect width="24" height="24" fill="white" transform="translate(0 0.477539)" />
      </clipPath>
    </defs>
  </svg>
);
