import React from 'react';

const SyncIcon = ({styles = {}}: {styles?: React.CSSProperties}) => (
  <svg
    width={styles.width || '16'}
    height={styles.height || '16'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00033 2.66661V1.47327C8.00033 1.17327 7.64033 1.02661 7.43366 1.23994L5.56699 3.09994C5.43366 3.23327 5.43366 3.43994 5.56699 3.57327L7.42699 5.43327C7.64033 5.63994 8.00033 5.49327 8.00033 5.19327V3.99994C10.207 3.99994 12.0003 5.79327 12.0003 7.99994C12.0003 8.52661 11.9003 9.03994 11.707 9.49994C11.607 9.73994 11.6803 10.0133 11.8603 10.1933C12.2003 10.5333 12.7737 10.4133 12.9537 9.96661C13.2003 9.35994 13.3337 8.69327 13.3337 7.99994C13.3337 5.05327 10.947 2.66661 8.00033 2.66661ZM8.00033 11.9999C5.79366 11.9999 4.00033 10.2066 4.00033 7.99994C4.00033 7.47327 4.10033 6.95994 4.29366 6.49994C4.39366 6.25994 4.32033 5.98661 4.14033 5.80661C3.80033 5.46661 3.22699 5.58661 3.04699 6.03327C2.80033 6.63994 2.66699 7.30661 2.66699 7.99994C2.66699 10.9466 5.05366 13.3333 8.00033 13.3333V14.5266C8.00033 14.8266 8.36033 14.9733 8.56699 14.7599L10.427 12.8999C10.5603 12.7666 10.5603 12.5599 10.427 12.4266L8.56699 10.5666C8.52 10.5205 8.46041 10.4894 8.39575 10.4771C8.3311 10.4649 8.26426 10.472 8.20366 10.4977C8.14306 10.5234 8.09141 10.5664 8.05523 10.6214C8.01904 10.6763 7.99994 10.7408 8.00033 10.8066V11.9999Z"
      fill="#767676"
    />
  </svg>
);

export default SyncIcon;
