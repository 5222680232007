import React from 'react';

export default function BriefcaseIcon(props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26.667 9.3335H5.33366C3.8609 9.3335 2.66699 10.5274 2.66699 12.0002V25.3335C2.66699 26.8063 3.8609 28.0002 5.33366 28.0002H26.667C28.1398 28.0002 29.3337 26.8063 29.3337 25.3335V12.0002C29.3337 10.5274 28.1398 9.3335 26.667 9.3335Z"
        stroke="#222222"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3337 28V6.66667C21.3337 5.95942 21.0527 5.28115 20.5526 4.78105C20.0525 4.28095 19.3742 4 18.667 4H13.3337C12.6264 4 11.9481 4.28095 11.448 4.78105C10.9479 5.28115 10.667 5.95942 10.667 6.66667V28"
        stroke="#222222"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
