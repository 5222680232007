import {pathOr} from 'ramda';

import {Chat} from 'src/types';
import parseDate from 'src/utils/parseDate';

export default function sortChatsByDate(chat1: Chat, chat2: Chat) {
  const lastMessage1 = chat1.lastMessage;
  const lastMessage2 = chat2.lastMessage;

  const date1 = pathOr('0', ['dateCreated'], lastMessage1);
  const date2 = pathOr('0', ['dateCreated'], lastMessage2);

  const actualDate1 = parseDate(date1);
  const actualDate2 = parseDate(date2);

  return actualDate1 > actualDate2 ? -1 : 1;
}
