import gql from 'graphql-tag';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';

export default gql`
  fragment DeliveryReceiptFragment on DeliveryReceipt {
    user {
      ...GeneralUserFragment
    }
    messageId
    timestamp
  }
  ${GeneralUserFragment}
`;
