import gql from 'graphql-tag';
import ChatFragment from 'src/gql/fragment/ChatFragment';

export default gql`
  mutation setChatPicture($chatId: ID!, $fileId: Int!) {
    chat(chatId: $chatId) {
      setPicture(fileId: $fileId) {
        ...ChatFragment
      }
    }
  }
  ${ChatFragment}
`;
