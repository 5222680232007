import {RoleResultInfo, MarketplacePosts} from 'src/types';

export const getUserIdSetFromMarketplacePosts = (payload: MarketplacePosts[]) => {
  const userIdSet = new Set<string>();
  payload.forEach((post) => {
    userIdSet.add(post.shift.user.id);
  });
  return userIdSet;
};

export default function getUserIdSet(payload: RoleResultInfo[]) {
  const userIdSet = new Set<string>();
  payload?.forEach((role) => {
    role.shifts?.forEach((shift) => {
      userIdSet.add(shift.user.id);
    });
  });
  return userIdSet;
}
