import gql from 'graphql-tag';

export default gql`
  query search($text: String!, $continuationId: ID, $limit: Int, $direction: PaginationDirection) {
    searchQuery(text: $text) {
      searchUsers(continuationId: $continuationId, limit: $limit, direction: $direction) {
        continuationId
        totalResultsCount
        users {
          user {
            id
            firstName
            lastName
            role
            avatar {
              id
              url
            }
          }
          matchedSequences
        }
      }
    }
  }
`;
