import gql from 'graphql-tag';
import {OrganizationFragments} from 'src/gql/fragment/OrganizationScopeFragment';

export interface CreateOrganizationResponse {
  createNewOrganization: {
    image: string;
    name: string;
    id: number;
  };
}

export default gql`
  mutation CreateOrganization($domain: String, $organizationDetails: CreateOrganizationDetails!) {
    createNewOrganization(domain: $domain, details: $organizationDetails) {
      image
      id
      name
    }
  }
`;
