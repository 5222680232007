import React from 'react';

export const MenuDragIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_719_2164)">
      <path d="M7 3H5V5H7V3Z" fill="#4A4A4A" />
      <path d="M11 3H9V5H11V3Z" fill="#4A4A4A" />
      <path d="M7 7H5V9H7V7Z" fill="#4A4A4A" />
      <path d="M11 7H9V9H11V7Z" fill="#4A4A4A" />
      <path d="M7 11H5V13H7V11Z" fill="#4A4A4A" />
      <path d="M11 11H9V13H11V11Z" fill="#4A4A4A" />
    </g>
    <defs>
      <clipPath id="clip0_719_2164">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
