import gql from 'graphql-tag';
import GeneralUserFragment from '../fragment/GeneralUserFragment';

export default gql`
  query FetchDepartmentMembers($departmentId: Int!) {
    locating {
      department(id: $departmentId) {
        members {
          ...GeneralUserFragment
        }
      }
    }
  }
  ${GeneralUserFragment}
`;
