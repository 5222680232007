import * as R from 'ramda';
import client from 'src/apollo';
import {ApolloQueryResult} from 'apollo-client';
import {Message, Chat} from 'src/types';
import {Subscription} from 'apollo-client/util/Observable';
import getMessageFragment from 'src/utils/messengerHelper/getMessageFragment';
import GetMessageQuery from 'src/gql/query/GetMessageQuery';
// import GetChatQuery from 'src/gql/query/GetChatQuery';

/**
 * BUG: (might related to chatList's message not sync with messages' last message)
 *
 * currently there exist overlapping problem where if spamming group of messages
 * in really close gap, some of the writeFragment from socket will fail to notify watchQuery
 * or content got overwritten by one another (from socket writeFragment?) thats causing outdated data for readby receipts
 */

class MessageBodyPresenter {
  public viewController: any;
  public chatId: string;
  public messageId: string;
  public messageHash: string; // self: id-type-readbyLength, other: id-type-repliedToFlag(0 or 1)
  public querySubscription: Subscription;
  public isSelf: boolean;

  constructor(viewController, chatId, messageId, isSelf) {
    this.viewController = viewController;
    this.chatId = chatId;
    this.messageId = messageId;
    this.messageHash = '';
    this.isSelf = isSelf;
  }

  public async init() {
    const message = getMessageFragment(this.messageId, client);
    // for subscription:
    // if GetMessageQuery was never write in to store, use data from getChatquery to write to cache

    try {
      client.readQuery({
        query: GetMessageQuery,
        variables: {
          chatId: this.chatId,
          messageId: this.messageId,
        },
      });
    } catch (e) {
      client.writeQuery({
        query: GetMessageQuery,
        variables: {
          chatId: this.chatId,
          messageId: this.messageId,
        },
        data: {
          chat: {
            id: this.chatId,
            message,
            __typename: 'Chat',
          },
        },
      });
    }

    this.setWatchQuery();
  }

  public async destory() {
    // console.log(`unsubscribing ${this.messageId} subscription:\n`, this.querySubscription)
    if (this.querySubscription) this.querySubscription.unsubscribe();
  }

  private setWatchQuery() {
    let subscription = client
      .watchQuery({
        query: GetMessageQuery,
        variables: {
          chatId: this.chatId,
          messageId: this.messageId,
        },
        fetchPolicy: 'cache-only',
      })
      .subscribe({
        next: (result) => this.handleCacheMessagesUpdate(result),
        error: (e) => console.error(e),
      });
    this.querySubscription = subscription;
  }

  private handleCacheMessagesUpdate(result: ApolloQueryResult<{chat: Chat}>) {
    // console.log(`message ${this.messageId} subscribed result:\n`, result)
    let message = R.pathOr([], ['chat', 'message'], result.data);
    const newHash = this.transLateMessageIntoMessageHashString(message);

    if (newHash !== this.messageHash) {
      if (
        // prevent readReceipts overwritten(see *BUG) updated read receipts count should never less than previous
        this.isSelf &&
        this.messageHash.split('-')[2] &&
        newHash.split('-')[2] < this.messageHash.split('-')[2]
      ) {
        return;
      }
      this.messageHash = newHash;
      this.viewController.update(message);
    }
  }

  private transLateMessageIntoMessageHashString(msg: Message) {
    if (!msg) return this.messageHash;
    const {id, type} = msg;
    const readByLength = msg.readBy.length;
    const hasRepliedTo = Number(Boolean(msg.repliedTo));
    return this.isSelf ? `${type}-${id}-${readByLength}` : `${type}-${id}-${hasRepliedTo}`;
  }
}

export default MessageBodyPresenter;
