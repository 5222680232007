import {toast} from 'react-toastify';

import {User} from 'src/types';
import createNewChat from './createNewChat';
import {MESSENGER} from 'src/constants/routerPathName';

export default async function handleGotoAlternativeContact(workStatusProxy: User) {
  if (!workStatusProxy) return;

  try {
    if (workStatusProxy.status && workStatusProxy.status === 'shell') {
      window.routerHistory.push(`/${MESSENGER}/user/${workStatusProxy.id}`);
    } else {
      toast.success('Starting the chat...', {
        autoClose: false,
      });
      const newChatId = await createNewChat([workStatusProxy]);
      toast.dismiss();

      if (!newChatId) throw new Error('failed');

      window.routerHistory.push(`/${MESSENGER}/${newChatId}`);
    }
  } catch (e) {
    console.error(e);
    toast.error('Failed to start new chat, please check your internet connection and try again');
  }
}
