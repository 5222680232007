function isIOS() {
  return window.webkit != null && window.webkit.messageHandlers != null;
}

export function callNative(functionName, messageObject) {
  if (isIOS()) {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers[functionName]) {
      window.webkit.messageHandlers[functionName].postMessage(messageObject);
    } else {
      console.log(`Function ${functionName} is not defined on window.webkit.messageHandlers`);
    }
  } else {
    if (window.AndroidBridge && window.AndroidBridge[functionName]) {
      window.AndroidBridge[functionName](JSON.stringify(messageObject));
    } else {
      console.log(`Function ${functionName} is not defined on window.AndroidBridge`);
    }
  }
}
