import * as React from 'react';
import EulaModal from 'src/auth/EulaModal';
import {AuthPayload} from 'src/types';
import client from 'src/apollo';
import AcceptEulaMutation from 'src/gql/mutation/AcceptEulaMutation';
import {toast} from 'react-toastify';
import {AuthHelper} from 'src/auth';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';

interface Props {
  authClone: AuthPayload;
  clearAuthClone: () => void;
  onLoginSuccess: (authClone: AuthPayload) => void;
  setIsLoggedin: (authClone: AuthPayload) => void;
}

class EulaContainer extends React.PureComponent<Props> {
  public state = {
    showModal: false,
    isLoading: false,
  };

  public componentDidMount() {
    const {authClone} = this.props;
    // only mount when authInfo is provided
    if (authClone) {
      this.setState({
        showModal: true,
      });
    }
  }

  public render() {
    const {showModal, isLoading} = this.state;
    const {authClone} = this.props;
    return (
      showModal &&
      authClone && (
        <EulaModal
          onAgreementSubmit={this.onAgreementSubmit}
          closeModal={this.closeModal}
          reactModalProps={{isOpen: showModal}}
          isLoading={isLoading}
        />
      )
    );
  }

  private closeModal = () => {
    const {clearAuthClone} = this.props;
    this.setState(
      {
        showModal: false,
      },
      () => clearAuthClone(),
    );
  };

  private onAgreementSubmit = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      const {onLoginSuccess, authClone, setIsLoggedin} = this.props;
      await onLoginSuccess(authClone);
      await client.mutate({
        mutation: AcceptEulaMutation,
        fetchPolicy: 'no-cache',
      });
      this.closeModal();
      setIsLoggedin(authClone);

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.agreeEULA,
      });

      window.routerHistory.push(`/messenger/`);
    } catch {
      AuthHelper.logout();
      this.setState({isLoading: false});
      toast.error('Failed to make the network request, please check your internet connection and try again');
    }
  };
}

export default EulaContainer;
