export enum OperatingSystem {
  WINDOWS = 'Windows',
  MAC = 'MacOS',
  UNIX = 'UNIX',
  LINUX = 'Linux',
}

export default function getOperatingSystem() {
  let OSName = OperatingSystem.WINDOWS;
  if (navigator.userAgent.indexOf('Win') !== -1) OSName = OperatingSystem.WINDOWS;
  if (navigator.userAgent.indexOf('Mac') !== -1) OSName = OperatingSystem.MAC;
  if (navigator.userAgent.indexOf('X11') !== -1) OSName = OperatingSystem.UNIX;
  if (navigator.userAgent.indexOf('Linux') !== -1) OSName = OperatingSystem.LINUX;
  return OSName;
}
