import gql from 'graphql-tag';

export default gql`
  query GetSelfInviteCode {
    me {
      id
      inviteCode
    }
  }
`;
