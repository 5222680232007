import gql from 'graphql-tag';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';

export default gql`
  fragment ShiftRequestNoteFragment on Note {
    id
    note
    status
    user {
      ...GeneralUserFragment
    }
    createdAt
  }
  ${GeneralUserFragment}
`;
