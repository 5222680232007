import * as React from 'react';
import PasswordCheckList from 'src/components/PasswordCheckList';
import PasswordForm from 'src/pages/ChangePasswordPage/change-password/PasswordForm';

class PasswordBox extends React.Component {
  public state = {
    oldpassword: '',
    newpassword: '',
    confirmpassword: '',
  };
  public handleOnChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  public render() {
    const {newpassword, confirmpassword} = this.state;
    return (
      <React.Fragment>
        <PasswordCheckList showConfirm={true} newpassword={newpassword} confirmpassword={confirmpassword} />
        <PasswordForm handleOnChange={this.handleOnChange} />
      </React.Fragment>
    );
  }
}

export default PasswordBox;
