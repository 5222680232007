import {InMemoryCache, defaultDataIdFromObject, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import {CONNECTED} from 'src/constants/connectionStatuses';

const introspectionQueryResultData = {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'GeneralUser',
        possibleTypes: [
          {
            name: 'FullUser',
          },
          {
            name: 'PublicUser',
          },
          {
            name: 'ChatMember',
          },
        ],
      },
    ],
  },
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionQueryResultData,
});

// doc: https://www.apollographql.com/docs/react/advanced/caching/#cache-redirects-with-cacheredirects
const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case 'Chat':
        return object.id;
      case 'ChatMember':
        return `User:${object.id}`;
      case 'PublicUser':
        return `User:${object.id}`;
      case 'FullUser':
        return `User:${object.id}`;
      default:
        return defaultDataIdFromObject(object);
    }
  },
  cacheRedirects: {
    Query: {
      chat: (_, args, context) => {
        const {getCacheKey} = context;
        return getCacheKey({__typename: 'Chat', id: args.chatId});
      },
    },
  },
  fragmentMatcher,
});

// init @client data
cache.writeData({
  data: {
    connectionStatus: CONNECTED,
  },
});

export default cache;
