import React from 'react';

export const HospitalIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_447_7660)">
      <path d="M13.125 6.25H10.625V3.75H9.375V6.25H6.875V7.5H9.375V10H10.625V7.5H13.125V6.25Z" fill="#4A4A4A" />
      <path
        d="M17.5 6.25H16.25V2.5C16.2496 2.1686 16.1178 1.85087 15.8835 1.61654C15.6491 1.3822 15.3314 1.25038 15 1.25H5C4.6686 1.25038 4.35087 1.3822 4.11654 1.61654C3.8822 1.85087 3.75038 2.1686 3.75 2.5V6.25H2.5C2.1686 6.25038 1.85087 6.3822 1.61654 6.61654C1.3822 6.85087 1.25038 7.1686 1.25 7.5V18.75H18.75V7.5C18.7496 7.1686 18.6178 6.85087 18.3835 6.61654C18.1491 6.3822 17.8314 6.25038 17.5 6.25ZM8.75 17.5V13.75H11.25V17.5H8.75ZM12.5 17.5V13.125C12.5 12.9592 12.4342 12.8003 12.3169 12.6831C12.1997 12.5658 12.0408 12.5 11.875 12.5H8.125C7.95924 12.5 7.80027 12.5658 7.68306 12.6831C7.56585 12.8003 7.5 12.9592 7.5 13.125V17.5H2.5V7.5H5V2.5H15V7.5H17.5V17.5H12.5Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_447_7660">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
