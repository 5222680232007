import * as React from 'react';

export default ({styles = {}}: {styles?: React.CSSProperties}) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles}>
    <g clip-path="url(#clip0_488_74637)">
      <path
        d="M9.16667 15C8.93056 15 8.73278 14.92 8.57333 14.76C8.41333 14.6006 8.33333 14.4028 8.33333 14.1667C8.33333 13.9306 8.41333 13.7328 8.57333 13.5733C8.73278 13.4133 8.93056 13.3333 9.16667 13.3333H10.8333C11.0694 13.3333 11.2675 13.4133 11.4275 13.5733C11.5869 13.7328 11.6667 13.9306 11.6667 14.1667C11.6667 14.4028 11.5869 14.6006 11.4275 14.76C11.2675 14.92 11.0694 15 10.8333 15H9.16667ZM3.33333 6.66667C3.09722 6.66667 2.89944 6.58694 2.74 6.4275C2.58 6.2675 2.5 6.06944 2.5 5.83333C2.5 5.59722 2.58 5.39917 2.74 5.23917C2.89944 5.07972 3.09722 5 3.33333 5H16.6667C16.9028 5 17.1006 5.07972 17.26 5.23917C17.42 5.39917 17.5 5.59722 17.5 5.83333C17.5 6.06944 17.42 6.2675 17.26 6.4275C17.1006 6.58694 16.9028 6.66667 16.6667 6.66667H3.33333ZM5.83333 10.8333C5.59722 10.8333 5.39917 10.7533 5.23917 10.5933C5.07972 10.4339 5 10.2361 5 10C5 9.76389 5.07972 9.56583 5.23917 9.40583C5.39917 9.24639 5.59722 9.16667 5.83333 9.16667H14.1667C14.4028 9.16667 14.6006 9.24639 14.76 9.40583C14.92 9.56583 15 9.76389 15 10C15 10.2361 14.92 10.4339 14.76 10.5933C14.6006 10.7533 14.4028 10.8333 14.1667 10.8333H5.83333Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_488_74637">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
