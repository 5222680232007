import {RoleResultInfo, LocatingRole} from 'src/types';
import moment from 'moment';

type SortRolesParam = RoleResultInfo | LocatingRole;
/**
 * Sort roles based on displayPosition value in the role object, if displayPosition for 2 roles is same then sort
 * on the basis of updatedAt date time object
 * @param payloadA RoleA object to compare
 * @param payloadB RoleB object to compare
 * @return sortOrder number
 */
export default function sortRoles(payloadA: SortRolesParam, payloadB: SortRolesParam): number {
  let updatedAtA = moment(payloadA.updatedAt);
  let updatedAtB = moment(payloadB.updatedAt);
  if (payloadA.displayPosition === payloadB.displayPosition) {
    if (updatedAtA.isBefore(updatedAtB)) return -1;
    if (updatedAtA.isAfter(updatedAtB)) return 1;
    return 0;
  }

  return payloadA.displayPosition - payloadB.displayPosition;
}
