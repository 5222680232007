import GetChatsQuery from 'src/gql/query/GetChatsQuery';
import GetColleaguesQuery from 'src/gql/query/GetColleaguesQuery';
import GetUserOrganizations from 'src/gql/query/GetUserOrganizations';
import client from 'src/apollo';

// until https://github.com/apollographql/apollo-feature-requests/issues/1 fixed
export async function tryReadColleaguesQuery() {
  try {
    return client.readQuery({query: GetColleaguesQuery});
  } catch {
    return null;
  }
}

export async function tryReadUserOrganizations() {
  try {
    return client.readQuery({query: GetUserOrganizations});
  } catch {
    return null;
  }
}

/**
 * note: this is only for none-priority chat, could be potentially a bug:
 * when user creating new chat, handle incoming chat, or handle member left,
 * when sockets event coming, those event that calling this query is handled when
 * the user already opened the chat, which the prioiriy message should be already read
 * but those cases are not guaranteed
 */
export default async function () {
  try {
    return client.readQuery({query: GetChatsQuery});
  } catch {
    return null;
  }
}
