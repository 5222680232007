import gql from 'graphql-tag';
import ActiveEscalationsFragment from 'src/gql/fragment/ActiveEscalationFragment';

export default gql`
  mutation CancelEscalation($activatedEscalationId: Int!, $reasonForCancellation: String) {
    escalation {
      activated(activatedEscalationId: $activatedEscalationId) {
        cancel(reasonForCancellation: $reasonForCancellation) {
          ...ActiveEscalationsFragment
        }
      }
    }
  }
  ${ActiveEscalationsFragment}
`;
