import React from 'react';

export default ({width = 24, height = 24, viewBox = '0 0 24 24'}) => (
  <svg width={width} height={height} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" fill="none">
    <rect width={width || '24'} height={height || '24'} fill="white" fillOpacity="0.01" />
    <path
      d="M23.1378 11.5868L17.9163 2.649C17.8458 2.52766 17.7447 2.42695 17.6231 2.35694C17.5014 2.28692 17.3636 2.25004 17.2233 2.25H6.77726C6.63706 2.25017 6.49936 2.28711 6.37789 2.35712C6.25642 2.42713 6.15544 2.52777 6.08501 2.649L0.862761 11.5875C0.789554 11.7129 0.750977 11.8555 0.750977 12.0007C0.750977 12.146 0.789554 12.2886 0.862761 12.414L6.08576 21.3517C6.15619 21.473 6.25717 21.5736 6.37864 21.6436C6.50011 21.7136 6.63781 21.7506 6.77801 21.7507H17.224C17.3642 21.7506 17.5019 21.7136 17.6234 21.6436C17.7449 21.5736 17.8458 21.473 17.9163 21.3517L23.1378 12.4125C23.211 12.2871 23.2495 12.1445 23.2495 11.9992C23.2495 11.854 23.211 11.7122 23.1378 11.5868ZM11.1573 6H12.8448V14.25H11.1573V6ZM12.0003 18.75C11.7778 18.75 11.5603 18.684 11.3752 18.5604C11.1902 18.4368 11.046 18.2611 10.9609 18.0555C10.8757 17.85 10.8535 17.6238 10.8969 17.4055C10.9403 17.1873 11.0474 16.9868 11.2048 16.8295C11.3621 16.6722 11.5626 16.565 11.7808 16.5216C11.999 16.4782 12.2252 16.5005 12.4308 16.5856C12.6363 16.6708 12.812 16.815 12.9357 17C13.0593 17.185 13.1253 17.4025 13.1253 17.625C13.1253 17.9234 13.0067 18.2095 12.7958 18.4205C12.5848 18.6315 12.2986 18.75 12.0003 18.75Z"
      fill="#ECAD0B"
    />
  </svg>
);
