import React from 'react';
import * as Yup from 'yup';
import {LoginForm} from './LoginForm';
import {
  LOGIN_PAGE_EMAIL_ADDRESS_VIEW_EMPTY_EMAIL,
  LOGIN_PAGE_EMAIL_ADDRESS_VIEW_SEARCH_BAR_LABEL,
  LOGIN_PAGE_EMAIL_ADDRESS_VIEW_SEARCH_BAR_PLACEHOLDER,
  LOGIN_PAGE_EMAIL_ADDRESS_VIEW_TITLE,
} from '../../constants/strings';
import {CurrentSelectedOrganization} from '../types/login.types';

interface ILoginEmailAddressViewProps {
  handleGoBack: () => void;
  handleNext: (address: string) => void;
  organization?: CurrentSelectedOrganization;
  error: string;
  isLoading: boolean;
}

export const LoginEmailAddressView = ({
  handleGoBack,
  handleNext,
  organization,
  error,
  isLoading,
}: ILoginEmailAddressViewProps) => {
  return (
    <LoginForm
      initialValues={{address: ''}}
      validationSchema={Yup.object().shape({
        address: Yup.string().required(LOGIN_PAGE_EMAIL_ADDRESS_VIEW_EMPTY_EMAIL),
      })}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        await handleNext(values.address);
        actions.setSubmitting(false);
      }}
      handleGoBack={handleGoBack}
      organization={organization}
      error={error}
      type={'text'}
      isLoading={isLoading}
    >
      {{
        title: LOGIN_PAGE_EMAIL_ADDRESS_VIEW_TITLE,
        placeholder: LOGIN_PAGE_EMAIL_ADDRESS_VIEW_SEARCH_BAR_PLACEHOLDER,
        label: LOGIN_PAGE_EMAIL_ADDRESS_VIEW_SEARCH_BAR_LABEL,
        name: 'address',
        dataTestId: 'addressInput',
      }}
    </LoginForm>
  );
};
