import {ApolloConsumer} from 'react-apollo';
import {ApolloClient} from 'apollo-client';
import React, {Component} from 'react';

import {AuthContext} from 'src/auth';
import {AuthPayload, ChatUser, Chat} from 'src/types';
import ChatInformationMemberListItem from 'src/pages/MessengerPage/messenger/chat-info-layout/ChatInformationMemberListItem';
import {ADMIN} from 'src/constants/privileges';

interface Props {
  client: ApolloClient<any>;
  members: ChatUser[];
  chat: Chat;
}
interface WithAuth {
  authInfo: AuthPayload;
}
class ChatInformationMemberList extends Component<Props & WithAuth> {
  public render() {
    const {chat, members, client, authInfo} = this.props;
    const me = members.find((member) => member.id === authInfo.user.id);
    const hasAuthorization = Boolean(me && me.privilege === ADMIN);
    return (
      <div>
        {members.map((member) => {
          return (
            <ChatInformationMemberListItem
              chat={chat}
              myId={authInfo.user.id}
              key={member.id}
              member={member}
              client={client}
              isMe={me === member}
              canChangeUsers={hasAuthorization}
            />
          );
        })}
      </div>
    );
  }
}

export default (props) => (
  <AuthContext.Consumer>
    {({authInfo}) => (
      <ApolloConsumer>
        {(client) => <ChatInformationMemberList {...props} authInfo={authInfo} client={client} />}
      </ApolloConsumer>
    )}
  </AuthContext.Consumer>
);
