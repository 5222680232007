import moment from 'moment';

export default (date: Date) => {
  const now = moment(new Date()).startOf('day');
  const givenDate = moment(date);
  const days = now.diff(givenDate.startOf('day'), 'days');

  if (days < 1) {
    return moment(date).format('h:mm a');
  }
  if (days === 1) {
    return 'Yesterday';
  }
  if (days <= 7) {
    return moment(date).format('dddd');
  }
  return moment(date).format('MMM D');
};

export function getDetailedReadableDate(date: Date) {
  const now = moment(new Date()).startOf('day');
  const givenDate = moment(date);
  const days = now.diff(givenDate.startOf('day'), 'days');

  if (days < 1) {
    return moment(date).format('h:mm a');
  }
  if (days <= 7) {
    return moment(date).format('dddd h:mm a');
  }
  return moment(date).format('MMM D, h:mm a');
}

export function getDetailedPrettyReadableDate(date: Date) {
  const now = moment(new Date()).startOf('day');
  const givenDate = moment(date);
  const days = now.diff(givenDate.startOf('day'), 'days');

  if (days < 1) {
    return moment(date).format('[Today], h:mm a');
  }
  if (days === 1) {
    return moment(date).format('[Yesterday], h:mm a');
  }
  if (days <= 7) {
    return moment(date).format('dddd, h:mm a');
  }
  return moment(date).format('MMM D, YYYY [at] h:mm a');
}

export function getChatDetailedPrettyReadableDate(date: Date) {
  const now = moment(new Date()).startOf('day');
  const givenDate = moment(date);
  const days = now.diff(givenDate.startOf('day'), 'days');

  if (days < 1) {
    return moment(date).format('h:mm a');
  }
  if (days === 1) {
    return 'Yesterday';
  }
  if (days <= 7) {
    return moment(date).format('dddd');
  }
  return moment(date).format('DD/MMM/YY');
}
