import {Chat, Message} from 'src/types';
import client from 'src/apollo';
import ChatFragment from 'src/gql/fragment/ChatListFragment';

export default function getLatestMessage(chat: Chat): Message | null {
  const chatFragment = client.readFragment({
    id: `${chat.id}`,
    fragment: ChatFragment,
    fragmentName: 'ChatFragment',
  });
  if (chatFragment) {
    const {lastMessage} = chatFragment;
    return lastMessage;
  } else {
    return null;
  }
}
