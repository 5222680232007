import gql from 'graphql-tag';
import MessageTemplateFormFragment from 'src/gql/fragment/MessageTemplateFormFragment';

export default gql`
  query FetchMessageTemplates($chatId: ID!) {
    chat(chatId: $chatId) {
      id
      messageTemplates {
        templates {
          ...MessageTemplateFormFragment
        }
      }
    }
  }
  ${MessageTemplateFormFragment}
`;

export const FetchFavouriteTemplates = gql`
  query FetchFavouritedMessageTemplates($chatId: ID!) {
    chat(chatId: $chatId) {
      id
      messageTemplates {
        favouriteTemplates {
          ...MessageTemplateFormFragment
        }
      }
    }
  }
  ${MessageTemplateFormFragment}
`;
