import gql from 'graphql-tag';
import ActiveEscalationsFragment from 'src/gql/fragment/ActiveEscalationFragment';

export default gql`
  mutation ActivateDepartmentsEscalation(
    $escalationLadderId: ID!
    $startingLevel: Int!
    $activatedChatId: ID
    $messageId: Int
    $message: String
  ) {
    escalation {
      ladder(escalationLadderId: $escalationLadderId) {
        activate(
          startingLevel: $startingLevel
          chatId: $activatedChatId
          messageId: $messageId
          customMessage: $message
        ) {
          ...ActiveEscalationsFragment
        }
      }
    }
  }
  ${ActiveEscalationsFragment}
`;
