import gql from 'graphql-tag';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';

export const GetColleaguesIDList = gql`
  query GetColleaguesCount {
    colleagues {
      id
    }
  }
`;

export default gql`
  query GetColleagues {
    colleagues {
      ...GeneralUserFragment
    }
  }
  ${GeneralUserFragment}
`;
