import gql from 'graphql-tag';

export default gql`
  mutation unRegisterPush($deviceId: ID!) {
    selfMutation {
      device(id: $deviceId) {
        unregisterPushToken {
          pushToken
        }
      }
    }
  }
`;
