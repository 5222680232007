import React from 'react';

export default (props) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 8.29295V6.5C12.9996 5.71738 12.8139 4.94597 12.4584 4.2488L15 1.70705L14.2929 1L1 14.2929L1.70705 15L4.70705 12H5.5V12.5C5.5 13.163 5.76339 13.7989 6.23223 14.2678C6.70107 14.7366 7.33696 15 8 15C8.66304 15 9.29893 14.7366 9.76777 14.2678C10.2366 13.7989 10.5 13.163 10.5 12.5V12H14C14.1326 12 14.2598 11.9473 14.3536 11.8536C14.4473 11.7598 14.5 11.6326 14.5 11.5V10C14.5 9.8674 14.4473 9.74025 14.3535 9.6465L13 8.29295ZM9.5 12.5C9.5 12.8978 9.34196 13.2794 9.06066 13.5607C8.77936 13.842 8.39782 14 8 14C7.60218 14 7.22064 13.842 6.93934 13.5607C6.65804 13.2794 6.5 12.8978 6.5 12.5V12H9.5V12.5ZM13.5 11H5.70705L11.7059 5.0012C11.8999 5.47709 11.9998 5.98609 12 6.5V8.5C12 8.6326 12.0527 8.75975 12.1465 8.8535L13.5 10.2071V11Z"
      fill={props.fill || '#FF3E55'}
    />
    <path
      d="M3.8535 8.8535C3.94727 8.75975 3.99997 8.6326 4 8.5V6.5C4.00122 5.43951 4.42304 4.4228 5.17292 3.67292C5.9228 2.92304 6.93951 2.50122 8 2.5C8.90768 2.50048 9.78767 2.81256 10.4929 3.38405L11.206 2.6709C10.4402 2.02488 9.4969 1.62557 8.5 1.5254V0.5H7.5V1.5254C6.26734 1.65082 5.12496 2.22884 4.29377 3.1477C3.46257 4.06655 3.0016 5.26098 3 6.5V8.29295L1.5 9.79295L2.20705 10.5L3.8535 8.8535Z"
      fill={props.fill || '#FF3E55'}
    />
  </svg>
);
