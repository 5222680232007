import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import {QueryResult} from 'react-apollo';
import FetchInvitedUsers_contact from 'src/gql/query/FetchInvitedUsers_Contact';
import {getFullName} from 'src/utils/user';
import {InvitedUser, InvitedUserQueryResult} from 'src/types';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
interface Props {
  searchKeyword: string;
}

const useFetchInvitedUsers = ({searchKeyword}: Props) => {
  const coSigningInvitesFF = IsFeatureFlagEnabled('coSigningInvites');
  const {loading, error, data, refetch}: QueryResult<InvitedUserQueryResult> = useQuery(FetchInvitedUsers_contact, {
    variables: {
      status: coSigningInvitesFF ? ['pending_admin_approval', 'pending_user_acceptance'] : ['pending'],
    },
  });

  const invitationResultList: InvitedUser[] = React.useMemo(() => {
    let userInvitationList;

    if (!data || !data.me) return [];
    userInvitationList = data.me.sentInvites.filter((users) => users.address || users.user);

    userInvitationList.sort(function (inviteA: InvitedUser, inviteB: InvitedUser) {
      return new Date(inviteB.updatedAt).getTime() - new Date(inviteA.updatedAt).getTime();
    });

    if (Boolean(searchKeyword)) {
      const filteredList = userInvitationList.filter((invitedUser) => {
        if (invitedUser.address) {
          return invitedUser.address.address.toLowerCase().includes(searchKeyword);
        } else {
          return getFullName(invitedUser.user).toLowerCase().includes(searchKeyword);
        }
      });
      return filteredList;
    }
    return userInvitationList;
  }, [data, searchKeyword]);

  return {
    loading,
    error,
    refetch,
    invitationResultList,
  };
};

export default useFetchInvitedUsers;
