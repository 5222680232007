import moment from 'moment';
import {getFullName} from 'src/utils/user';

export const ON_SHIFT = 'on_shift';
export const BUSY = 'busy';
export const UNAVAILABLE = 'unavailable';

const isStatusExpiryDateToday = (statusExpiryDate) => moment().diff(statusExpiryDate, 'days') === 0;

export const BusyUnavailableDialogText = {
  [BUSY]: {
    title: ({firstname, lastname, statusExpiryDate}) =>
      `${firstname} ${lastname} is Busy and will not receive any general message notifications until ${moment(
        statusExpiryDate,
      ).format('HH:mm')} ${
        isStatusExpiryDateToday(statusExpiryDate) ? 'today' : `at ${moment(statusExpiryDate).format('DD MMM YYYY')}`
      }`,
    subtitle: ({workStatusProxy}) =>
      `Notifications for Urgent & STAT messages will still go through. ${
        Boolean(workStatusProxy) ? `They have assigned ${getFullName(workStatusProxy)} as their alternate contact.` : ''
      }`,
  },
  [UNAVAILABLE]: {
    title: ({firstname, lastname, statusExpiryDate}) =>
      `${firstname} ${lastname} is Unavailable and will not receive ANY notifications until ${moment(
        statusExpiryDate,
      ).format('HH:mm')} ${
        isStatusExpiryDateToday(statusExpiryDate) ? 'today' : `at ${moment(statusExpiryDate).format('DD MMM YYYY')}`
      }`,
    subtitle: ({workStatusProxy}) =>
      Boolean(workStatusProxy) ? `They have assigned ${getFullName(workStatusProxy)} as their alternate contact.` : '',
  },
};
