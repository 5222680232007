import getMembersFromChat from 'src/utils/messengerHelper/getMembersFromChat';
import * as R from 'ramda';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import {Chat} from 'src/types';

export default (chat: Chat): string => {
  const parsedAuthInfo = getParsedAuthInfo();

  const me = parsedAuthInfo && parsedAuthInfo.user;

  const newChat = R.evolve(
    {
      members: (members) => members.filter((member) => me && member.id !== me.id),
    },
    chat,
  );

  const {title} = chat;
  const names = getMembersFromChat(newChat);
  return title || names.join(', ') || (me && `${me.firstname} ${me.lastname}`) || '';
};

export const getInitials = (user) => {
  if (!user) {
    return 'N/A';
  }
  const {firstName, lastName} = user;
  return `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`;
};
