import gql from 'graphql-tag';
import UserInviteFragment from 'src/gql/fragment/InviteFragment';

export const InviteUserByIds = gql`
  mutation InviteUsers($userIds: [ID!]!, $organizationId: Int!) {
    self {
      inviteUsersToOrganization(userIds: $userIds, organizationId: $organizationId)
    }
  }
`;

export const InviteUserByAddresses = gql`
  mutation InviteUsers($addresses: [CreateAddressInput!]!, $organizationId: Int!) {
    self {
      inviteAddressesToOrganization(addresses: $addresses, organizationId: $organizationId)
    }
  }
`;

export const InviteUserByInviteId = gql`
  mutation cancelInvite($inviteId: Int!) {
    self {
      resendInvite(inviteId: $inviteId) {
        ...UserInviteFragment
      }
    }
  }
  ${UserInviteFragment}
`;
