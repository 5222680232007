import React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '27'} height={props.height || '18'} viewBox="0 0 27 18">
    <path
      fill={props.fill || '#FF3E55'}
      fillRule="evenodd"
      d="M1.378 2.411v12.862l.2.129L8.22 8.113 1.378 2.411zm17.055 5.942l7.114 7.067V2.423l-7.114 5.93zM2.116 16.508h22.71l-7.383-7.326-3.974 3.293L9.492 9.18l-7.376 7.327zm.052-15.11c.249.21.328.278.408.344 3.476 2.897 6.96 5.785 10.42 8.702.449.38.696.202 1.045-.09 3.37-2.82 6.749-5.631 10.124-8.446.15-.126.285-.269.54-.51H2.168zM13.492.001c3.386 0 6.772-.003 10.158.001 2.164.003 3.287 1.15 3.289 3.361.003 3.735.003 7.47 0 11.204-.002 2.207-1.127 3.355-3.295 3.357-6.772.003-13.544.003-20.316 0-2.22-.001-3.325-1.134-3.326-3.402-.002-3.735-.003-7.47 0-11.204C.005 1.159 1.136.005 3.256.003 6.668-.002 10.08 0 13.492 0z"
    />
  </svg>
);
