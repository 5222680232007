import React from 'react';
import {useDispatch} from 'react-redux';
import {HYPERCARE_REGION} from 'src/constants/storageKeys';
import {AuthRegion, typedUseSelector} from 'src/types';
import {authRegionData} from './AuthRegionData';
import {actions} from 'src/redux/actions/authRegion';

export const ChangeRegionViewModel = () => {
  const dispatch = useDispatch();
  const AuthRegionData = authRegionData;

  const currentAuthRegion = typedUseSelector((state) => state.hypercareRegion.currentAuthRegion);

  const submitRegionChange = (selectedRegion: AuthRegion) => {
    try {
      dispatch(actions.setHypercareRegion(selectedRegion));
      window.localStorage.setItem(HYPERCARE_REGION, selectedRegion);
      return {success: true, error: null};
    } catch (err) {
      return {success: null, error: err};
    }
  };

  return {currentAuthRegion, AuthRegionData, submitRegionChange};
};
