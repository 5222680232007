import * as React from 'react';

export default ({styles = {}}: {styles?: React.CSSProperties}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles}>
    <g clip-path="url(#clip0_748_4613)">
      <path
        d="M8 15C6.61553 15 5.26216 14.5895 4.11101 13.8203C2.95987 13.0511 2.06266 11.9579 1.53285 10.6788C1.00303 9.3997 0.86441 7.99224 1.13451 6.63437C1.4046 5.2765 2.07129 4.02922 3.05026 3.05026C4.02922 2.07129 5.2765 1.4046 6.63437 1.13451C7.99224 0.86441 9.3997 1.00303 10.6788 1.53285C11.9579 2.06266 13.0511 2.95987 13.8203 4.11101C14.5895 5.26216 15 6.61553 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 2C6.81332 2 5.65328 2.3519 4.66658 3.01119C3.67989 3.67047 2.91085 4.60755 2.45673 5.7039C2.0026 6.80026 1.88378 8.00666 2.11529 9.17054C2.3468 10.3344 2.91825 11.4035 3.75736 12.2426C4.59648 13.0818 5.66558 13.6532 6.82946 13.8847C7.99335 14.1162 9.19975 13.9974 10.2961 13.5433C11.3925 13.0892 12.3295 12.3201 12.9888 11.3334C13.6481 10.3467 14 9.18669 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2Z"
        fill="#4A4A4A"
      />
      <path
        d="M10.6477 10.6473C10.4529 10.8421 10.1371 10.8421 9.94232 10.6473L7.79289 8.49789C7.60536 8.31036 7.5 8.056 7.5 7.79079V4C7.5 3.72386 7.72386 3.5 8 3.5C8.27614 3.5 8.5 3.72386 8.5 4V7.79L10.648 9.94232C10.8425 10.1372 10.8423 10.4527 10.6477 10.6473Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_748_4613">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
