import gql from 'graphql-tag';
import MessageTemplateFormFragment from '../fragment/MessageTemplateFormFragment';

export default gql`
  query FetchMessageTemplate($chatId: ID!, $templateId: ID!) {
    chat(chatId: $chatId) {
      messageTemplates {
        template(id: $templateId) {
          ...MessageTemplateFormFragment
        }
      }
    }
  }
  ${MessageTemplateFormFragment}
`;
