import gql from 'graphql-tag';

export type DeleteSelfProfileCustomFieldMutationResult = {
  selfMutation: {
    profileField: {
      delete: {
        customFields: Array<{
          id: string;
          sectionId: string;
          sections: Array<{
            id: string;
          }>;
          fields: Array<{
            id: string;
            fieldId: string;
            __typename: string;
          }>;
        }>;
      };
    };
  };
};

export const DeleteSelfProfileCustomFieldMutation = gql`
  mutation DeleteProfileField($fieldId: ID!) {
    selfMutation {
      profileField(id: $fieldId) {
        deleteField {
          ... on SelfUser {
            customFields {
              id
              sectionId
              sections {
                id
              }
              fields {
                ... on Node {
                  id
                }
                fieldId
                __typename
              }
              __typename
            }
          }
        }
      }
    }
  }
`;
