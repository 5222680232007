import React from 'react';

export default (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00016 23.3335C5.92064 23.3335 6.66683 24.0797 6.66683 25.0002V31.6668C6.66683 32.1089 6.84242 32.5328 7.15498 32.8453C7.46755 33.1579 7.89147 33.3335 8.3335 33.3335H31.6668C32.1089 33.3335 32.5328 33.1579 32.8453 32.8453C33.1579 32.5328 33.3335 32.1089 33.3335 31.6668V25.0002C33.3335 24.0797 34.0797 23.3335 35.0002 23.3335C35.9206 23.3335 36.6668 24.0797 36.6668 25.0002V31.6668C36.6668 32.9929 36.14 34.2647 35.2024 35.2024C34.2647 36.14 32.9929 36.6668 31.6668 36.6668H8.3335C7.00741 36.6668 5.73564 36.14 4.79796 35.2024C3.86028 34.2647 3.3335 32.9929 3.3335 31.6668V25.0002C3.3335 24.0797 4.07969 23.3335 5.00016 23.3335Z"
      fill="#4A4A4A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8217 3.82165C19.4725 3.17078 20.5278 3.17078 21.1787 3.82165L29.512 12.155C30.1629 12.8059 30.1629 13.8611 29.512 14.512C28.8611 15.1629 27.8059 15.1629 27.155 14.512L20.0002 7.35719L12.8453 14.512C12.1945 15.1629 11.1392 15.1629 10.4883 14.512C9.83744 13.8611 9.83744 12.8059 10.4883 12.155L18.8217 3.82165Z"
      fill="#4A4A4A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0002 3.3335C20.9206 3.3335 21.6668 4.07969 21.6668 5.00016V25.0002C21.6668 25.9206 20.9206 26.6668 20.0002 26.6668C19.0797 26.6668 18.3335 25.9206 18.3335 25.0002V5.00016C18.3335 4.07969 19.0797 3.3335 20.0002 3.3335Z"
      fill="#4A4A4A"
    />
  </svg>
);
