import gql from 'graphql-tag';

export const PostToMarketplaceMutation = gql`
  mutation CreateMarketplacePost($shiftId: Int!, $offerTypes: [MarketplaceOfferType!]!, $note: String) {
    locating {
      postToMarketplace(note: $note, shiftId: $shiftId, offerTypes: $offerTypes) {
        id
      }
    }
  }
`;

export const CancelMarketplaceShiftMutation = gql`
  mutation CancelMarketplaceShiftMutation($postId: Int!) {
    locating {
      marketplacePost(postId: $postId) {
        cancel {
          id
        }
      }
    }
  }
`;

export const PickUpMarketplaceShiftMutation = gql`
  mutation AcceptPost($postId: Int!, $note: String, $shouldIgnoreWarnings: Boolean = false) {
    locating {
      marketplacePost(postId: $postId) {
        take(note: $note, shouldIgnoreWarnings: $shouldIgnoreWarnings) {
          id
        }
      }
    }
  }
`;
