import * as React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="4" viewBox="0 0 22 4" {...props}>
    <g fill="#9B9B9B" fillRule="evenodd">
      <circle cx="2" cy="2" r="2" />
      <circle cx="11" cy="2" r="2" />
      <circle cx="20" cy="2" r="2" />
    </g>
  </svg>
);
