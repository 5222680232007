import gql from 'graphql-tag';
import ChatFragment from 'src/gql/fragment/ChatFragment';

export default gql`
  mutation PromoteChatMember($chatId: ID!, $userId: ID!, $privilege: ChatMemberPrivilege!) {
    chat(chatId: $chatId) {
      admin {
        promoteMember(userId: $userId, privilege: $privilege) {
          ...ChatFragment
        }
      }
    }
  }
  ${ChatFragment}
`;
