import gql from 'graphql-tag';

export type RequestAddressVerificationResponse = {
  verification: {
    request: boolean;
  };
};
export const REQUEST_ADDRESS_VERIFICATION = gql`
  mutation RequestAddressVerification($address: String!, $type: AddressType!) {
    verification(address: $address, type: $type) {
      request
    }
  }
`;
