import React from 'react';
import {ChatRepository} from './ChatRepository';

export default function ChatViewModel() {
  const {messageDelivered} = ChatRepository();
  const handleMessageDelivered = async (messageIds: string[]) => {
    return messageDelivered({messageIds});
  };

  return {handleMessageDelivered};
}
