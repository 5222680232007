import * as React from 'react';

export default () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="18pt"
    height="18pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M2420 4834 c-103 -27 -180 -72 -260 -153 -73 -74 -97 -113 -1068
-1798 -547 -948 -1007 -1747 -1023 -1777 -38 -69 -69 -192 -69 -270 0 -198
137 -420 310 -504 137 -66 -25 -62 2250 -62 1817 0 2073 2 2125 15 238 61 413
269 432 515 7 95 -15 204 -60 293 -18 34 -480 839 -1027 1787 -973 1687 -997
1727 -1070 1801 -81 82 -158 126 -265 154 -74 19 -202 18 -275 -1z m233 -300
c27 -9 66 -34 90 -57 31 -31 296 -482 1043 -1777 550 -954 1007 -1751 1015
-1770 20 -51 16 -146 -8 -201 -26 -57 -87 -117 -145 -140 -40 -17 -162 -18
-2088 -18 -1926 0 -2048 1 -2088 18 -58 23 -119 83 -145 140 -24 55 -28 150
-8 201 8 19 465 816 1015 1770 750 1299 1012 1745 1044 1777 70 69 176 91 275
57z"
      />
      <path d="M2410 2645 l0 -775 150 0 150 0 0 775 0 775 -150 0 -150 0 0 -775z" />
      <path
        d="M2506 1560 c-89 -27 -152 -120 -144 -211 10 -99 98 -179 198 -179 62
0 128 35 164 87 103 149 -45 355 -218 303z"
      />
    </g>
  </svg>
);
