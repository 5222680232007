import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import {MessageTemplate} from 'src/types';
import FetchTemplateList from 'src/gql/query/FetchTemplateList';

interface Props {
  chatId: string;
  searchMessageTemplateString: string;
  isSingleChat: boolean;
}

/**
 * View modal to fetch -> process/search -> return the data to the view
 * @param {string} chatId fetch the templates for this chatId.
 * @param searchMessageTemplateString
 * @return {Array<MessageTemplate> | null} return the list of templates
 */
const useTemplateListViewModalData = ({chatId, searchMessageTemplateString, isSingleChat}: Props) => {
  const {
    loading: allTemplateListLoadingState,
    error: allTemplatesError,
    data: allTemplatesResult,
    refetch: allTemplateListRefetch,
  } = useQuery(FetchTemplateList, {variables: {chatId}});

  return React.useMemo(() => {
    if (allTemplateListLoadingState || allTemplatesError || !allTemplatesResult) return {};

    const messageTemplates: Array<MessageTemplate> = allTemplatesResult.chat.messageTemplates.templates;
    let allFilteredTemplates: Array<MessageTemplate> = messageTemplates;

    // Filter for search in template messages
    if (searchMessageTemplateString !== '') {
      allFilteredTemplates = messageTemplates.filter(
        (template) =>
          template.formTitle.toLowerCase().includes(searchMessageTemplateString.toLowerCase()) ||
          template.description.toLowerCase().includes(searchMessageTemplateString.toLowerCase()),
      );
    }

    // Filter for showing template messages specific for group chats
    if (!isSingleChat) {
      allFilteredTemplates = allFilteredTemplates.filter((template) => template?.metadata?.isEnabledForGroupChat);
    }
    return {allFilteredTemplates, allTemplateListLoadingState, allTemplatesError, allTemplateListRefetch};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, searchMessageTemplateString, allTemplatesResult, allTemplateListRefetch]);
};

export default useTemplateListViewModalData;
