import moment from 'moment';

// schedule page tabs
export const SCHEDULES = 'schedules';
export const REQUESTS = 'requests';
export const MARKETPLACE = 'marketplace';
export const MYSCHEDULE = 'myschedule';

// schedule state
export const DRAFT = 'draft';
export const PUBLISHED = 'published';

// Scheduling views
export const GRIDSCHEDULINGVIEW = 'grid';
export const LISTSCHEDULINGVIEW = 'list';
export const SWAPSCHEDULINGVIEW = 'swap';
export const GIVEAWAYSHIFTVIEW = 'give_away';

// Reducuer
export const REDUCER_DATE_FORMAT = 'YYYY-MM-DD';

export const ARBITRARY_DATE = moment().format('YYYY/MM/DD');
