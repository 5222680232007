import React from 'react';
import {withFormik} from 'formik';
import MultiStepForm from 'src/pages/ResetPasswordPage/reset-password/ResetPasswordMultiStepFormWrapper';
import {ResetPasswordFormValues} from 'src/types';

const ResetPasswordForm = ({onResetComplete}) => {
  const FormikHOC = withFormik({
    mapPropsToValues: (): ResetPasswordFormValues => ({
      username: '',
      password: '',
    }),
    handleSubmit: async (values: ResetPasswordFormValues, {setSubmitting}) => {
      setSubmitting(false);
      // onResetComplete()
    },
    displayName: 'ResetPasswordForm',
  })(MultiStepForm);

  return <FormikHOC />;
};

export default ResetPasswordForm;
