import {StateType} from 'typesafe-actions';
import {createStore, applyMiddleware, compose} from 'redux';
import {logger} from 'redux-logger';
import thunk from 'redux-thunk';
import reducer from 'src/redux/reducers';
import {saveState, loadState} from 'src/redux/persisReduxState';
import {useSelector, TypedUseSelectorHook} from 'react-redux';

const persistedState = loadState();

// prettier-ignore
const composeEnhancers = process.env.NODE_ENV === 'development'
// @ts-ignore
? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
: compose

const nologger = (s) => (next) => (action) => next(action);

const store = createStore(
  reducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk, process.env.NODE_ENV === 'production' ? nologger : logger)),
);

store.subscribe(() => {
  saveState({
    organization: store.getState().organization,
  });
});

export type RootState = StateType<typeof reducer>;

export type AppDispatch = typeof store.dispatch;

export const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
