import {ThemeProvider as StyledThemeProvider} from '@emotion/react';
import React, {useState} from 'react';
import {ApolloProvider} from 'react-apollo';
import {Provider} from 'react-redux';
import client from 'src/apollo';
import store from 'src/redux';
import AuthProvider from 'src/auth/AuthProvider';
import SocketIOClient from 'src/api/ApolloSocketIOListenerComponent';
import SocketIOClientWithFirebase from 'src/api/ApolloSocketIOListenerComponentWithFirebase';
import Router from 'src/router';
import StyledToastContainer from 'src/components/StyledToastContainer';
import {Auth0Provider} from '@auth0/auth0-react';

import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import IdleTimerController from 'src/components/SessionTimeout/IdleTimerHandler';
import ForcedLogoutTimer from 'src/components/SessionTimeout/forcedLogoutTimerHandler';

import NotificationHandlerComponent from 'src/notifications/NotificationHandlerComponent';
import FirebaseNotificationHandler from 'src/notifications/FirebaseNotificationHandler';
import * as serviceWorker from './serviceWorker';

import 'react-toastify/dist/ReactToastify.css';
import 'src/App.css';
import HypercareAuthRegionContext from 'src/contexts/HypercareLoginCoordinatorContext';
import {muiTheme} from 'src/styles/theme';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {useSetUserGeographicalLocation} from './hooks/useGetGeographicalLocation';
import {FeatureFlagResult} from './utils/FeatureFlags';
import {useFeatureFlagQueryParamOverride} from './hooks/useFeatureFlagQueryParamOverride';
import {datadogLogs} from '@datadog/browser-logs';
// import {datadogRum} from '@datadog/browser-rum';
import {useRegionParamOverride} from './hooks/useRegionParamOverride';

datadogLogs.init({
  clientToken: `${process.env.REACT_APP_DATADOG_CLIENT_TOKEN}`,
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: 'clientwebapp',
  env: `${process.env.REACT_APP_ENV}`,
  version: `${process.env.REACT_APP_VERSION}`,
});

const onRedirectCallback = () => {
  window.history.replaceState({}, document.title, redirectUri);
  window.routerHistory.replace(`/ssoredirect`);
};

const redirectUri = `${window.location.origin}/ssoredirect`;

const App = () => {
  const sessionTimeoutFlag = IsFeatureFlagEnabled(FeatureFlagResult.sessionTimeout);
  const firebaseNotificationFlag = IsFeatureFlagEnabled(FeatureFlagResult.firebaseCloudMessaging);
  const [changeRegionModalOpenStatus, setChangeRegionModalOpenStatus] = useState<boolean>(false);

  // datadogRum.init({
  //   applicationId: `${process.env.REACT_APP_DATADOG_APPLICATION_ID}`,
  //   clientToken: `${process.env.REACT_APP_DATADOG_CLIENT_TOKEN}`,
  //   site: 'datadoghq.eu',
  //   service: 'clientwebapp',
  //   env: `${process.env.REACT_APP_ENV}`,
  //   version: `${process.env.REACT_APP_VERSION}`,
  //   sessionSampleRate: 100,
  //   trackUserInteractions: true,
  //   defaultPrivacyLevel: 'mask-user-input',
  //   sessionReplaySampleRate: 100,
  //   trackResources: true,
  //   trackLongTasks: true,
  // });

  if (firebaseNotificationFlag === false) {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register({
      onUpdate: (registration: ServiceWorkerRegistration) => {
        // TODO: implement custom ui snackbar for such confirmation/alerts
        // TODO: once versioning is set, force auto update for crucial changes
        if (registration && registration.waiting) {
          setTimeout(() => {
            // setTimeout as main thread being blocked
            // https://github.com/facebook/create-react-app/issues/5316
            registration.waiting?.postMessage({type: 'SKIP_WAITING'});
          }, 800);
        }
      },
    });
  }

  const {region} = useSetUserGeographicalLocation();

  datadogLogs.setGlobalContextProperty('region', region);

  useFeatureFlagQueryParamOverride();
  useRegionParamOverride();

  if (!region) {
    return null;
  }

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <StyledThemeProvider theme={muiTheme}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
              <Auth0Provider
                domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
                clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
                redirectUri={redirectUri}
                onRedirecting={onRedirectCallback}
              >
                <AuthProvider>
                  <HypercareAuthRegionContext.Provider
                    value={{changeRegionModalOpenStatus, setChangeRegionModalOpenStatus}}
                  >
                    <Router />
                    {firebaseNotificationFlag && (
                      <>
                        <FirebaseNotificationHandler />
                        <SocketIOClientWithFirebase>
                          {sessionTimeoutFlag && (
                            <>
                              <IdleTimerController />
                              <ForcedLogoutTimer />
                            </>
                          )}
                          <StyledToastContainer
                            position="top-center"
                            closeOnClick={true}
                            autoClose={4000}
                            hideProgressBar={true}
                          />
                        </SocketIOClientWithFirebase>
                      </>
                    )}
                    {firebaseNotificationFlag === false && (
                      <>
                        <NotificationHandlerComponent />
                        <SocketIOClient>
                          {sessionTimeoutFlag && (
                            <>
                              <IdleTimerController />
                              <ForcedLogoutTimer />
                            </>
                          )}
                          <StyledToastContainer
                            position="top-center"
                            closeOnClick={true}
                            autoClose={4000}
                            hideProgressBar={true}
                          />
                        </SocketIOClient>
                      </>
                    )}
                  </HypercareAuthRegionContext.Provider>
                </AuthProvider>
              </Auth0Provider>
            </ThemeProvider>
          </StyledEngineProvider>
        </StyledThemeProvider>
      </ApolloProvider>
    </Provider>
  );
};

export default App;
