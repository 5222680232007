import gql from 'graphql-tag';

export const OrganizationMemberFragment = gql`
  fragment OrganizationMemberFragment on OrganizationMember {
    id
    firstname: firstName
    lastname: lastName
    accountStatus
    role
    profilePic: avatar {
      id
      url
    }
  }
`;

export const PaginatedUsersFragment = gql`
  fragment PaginatedUsersFragment on PaginatedUsers {
    continuationId
    totalResultsCount
    users {
      ...OrganizationMemberFragment
    }
  }
  ${OrganizationMemberFragment}
`;

export const UserInviteFragment = gql`
  fragment UserInviteFragment on UserInvite {
    id
    user {
      id
    }
    #status
    organization {
      id
    }
    invitedBy {
      id
    }
    history {
      action
      createdAt
    }
    inviteStatus
    address {
      address
    }
  }
`;

export const PaginatedPendingInvitesFragment = gql`
  fragment PaginatedPendingInvitesFragment on PaginatedPendingInvites {
    continuationId
    totalResultsCount
    pageSize
    invites {
      ...UserInviteFragment
    }
  }
  ${UserInviteFragment}
`;
