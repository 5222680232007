import * as React from 'react';
import {PageBody, PageContainer} from 'src/styles/styled-components/CommonPage';
import Helmet from 'react-helmet';
import {AuthContext} from 'src/auth/AuthProvider';
import Header from 'src/pages/MessengerPage/messenger/PageHeader';
import {AuthPayload, AuthRegion} from 'src/types';
import {Redirect} from 'react-router-dom';
import PageBanner from 'src/components/PageBanner';
import TemplateReport from 'src/pages/TemplateReportPage/TemplateReport';

interface Props {
  logout: () => void;
  isLoggedIn: boolean;
  authInfo: AuthPayload;
  authRegion: AuthRegion;
}

class TemplateReportPage extends React.PureComponent<Props> {
  public render() {
    const {isLoggedIn, authInfo, authRegion, logout} = this.props;

    const user = authInfo?.user;

    if (!isLoggedIn) {
      return <Redirect to="/login" />;
    }

    return (
      <PageContainer>
        <Helmet>
          <title>Template Report - Hypercare</title>
        </Helmet>
        <Header user={user} logout={logout} authRegion={authRegion} />
        <PageBanner />
        <PageBody>
          <TemplateReport />
        </PageBody>
      </PageContainer>
    );
  }
}

export default () => (
  <AuthContext.Consumer>
    {({logout, isLoggedIn, authRegion, authInfo}) => (
      <TemplateReportPage isLoggedIn={isLoggedIn} authInfo={authInfo} authRegion={authRegion} logout={logout} />
    )}
  </AuthContext.Consumer>
);
