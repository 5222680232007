import * as React from 'react';

export default () => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="PDF-Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="doc_color" fill="#8B8B8B" fillRule="nonzero">
        <path
          d="M16,0 L2,0 C0.9,0 0,0.9 0,2 L0,16 C0,17.1 0.9,18 2,18 L16,18 C17.1,18 18,17.1 18,16 L18,2 C18,0.9 17.1,0 16,0 L16,0 Z M14,6 L4,6 L4,4 L14,4 L14,6 L14,6 Z M14,10 L4,10 L4,8 L14,8 L14,10 L14,10 Z M11,14 L4,14 L4,12 L11,12 L11,14 L11,14 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);
