import * as React from 'react';

export default (props) => (
  <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.86231 7.83653C8.47545 8.20748 7.86493 8.20748 7.47808 7.83653L0.919113 1.54722C0.607993 1.24889 0.607994 0.751497 0.919114 0.453168C1.2123 0.172034 1.675 0.172034 1.96819 0.453168L8.17019 6.4002L14.3722 0.453168C14.6654 0.172034 15.1281 0.172034 15.4213 0.453168C15.7324 0.751497 15.7324 1.24889 15.4213 1.54722L8.86231 7.83653Z"
      fill={props.color || '#9B9B9B'}
    />
  </svg>
);
