import {HCTimeFilter} from 'src/@hc-ui/components/filters/variants/HCTimeFilter';
import {defaultTimeFilter} from '../../hooks/useAuditLogFilters';
import Popup from 'reactjs-popup';
import React, {useMemo, useState} from 'react';
import TimeIcon from 'src/svgs/TimeIcon';
import {TableFilterContainer} from './TableFilters';
import moment from 'moment';
import ChevronUpIcon from 'src/svgs/ChevronUpIcon';
import ChevronDownIcon from 'src/svgs/ChevronDownIcon';
import {timeToRealTime} from '../../hooks/useAuditLogQuery';

export const EventTimeRangeFilter = ({auditLogFilters}) => {
  const {timeFilter, setTimeFilter} = auditLogFilters;
  const [isOpen, setIsOpen] = useState(false);

  const defaultDates = useMemo(() => {
    const {timeFilter} = auditLogFilters;

    if (timeFilter.customRange) {
      return {
        fromDate: moment(timeFilter.customRange.startDate),
        toDate: moment(timeFilter.customRange.endDate),
      };
    } else if (timeFilter) {
      const times = timeToRealTime({startTime: timeFilter.type});
      return {
        fromDate: moment(times.startTime),
        toDate: moment(times.endTime),
      };
    } else {
      return {
        fromDate: undefined,
        toDate: undefined,
      };
    }
  }, [auditLogFilters.timeFilter]);

  const timeFilterDisplay = () => {
    if (timeFilter.type !== 'custom') {
      return `Past ${timeFilter.type}`;
    }

    const dateFormat = 'MM/DD/YY, HH:mm';

    return `${moment(timeFilter?.customRange.startDate).format(dateFormat)} to ${moment(
      timeFilter.customRange.endDate,
    ).format(dateFormat)}`;
  };

  const TimeFilter = React.forwardRef(({...props}, ref) => (
    <TableFilterContainer
      ref={ref as any}
      {...props}
      hasSelection={timeFilter.type !== defaultTimeFilter.type}
      isOpen={isOpen}
    >
      <TimeIcon /> {timeFilterDisplay()}
      {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </TableFilterContainer>
  ));

  return (
    <Popup
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={() => <TimeFilter />}
      position="bottom left"
      closeOnDocumentClick
    >
      {(close) => (
        <HCTimeFilter
          title="Show events from"
          onClose={close}
          onToggle={(filter) => {
            setTimeFilter({type: filter});
            close();
          }}
          onCustomRangeToggle={(from, to) => {
            setTimeFilter({
              type: 'custom',
              customRange: {
                startDate: from.format(),
                endDate: to.format(),
              },
            });
            close();
          }}
          onClear={() => setTimeFilter(defaultTimeFilter)}
          selectedIds={timeFilter ? [timeFilter.type] : []}
          defaultFromDate={defaultDates.fromDate}
          defaultToDate={defaultDates.toDate}
        />
      )}
    </Popup>
  );
};
