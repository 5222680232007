import gql from 'graphql-tag';

export default gql`
  fragment EscalationMembersFragment on EscalationMember {
    id
    user {
      id
      firstname
      lastname
      role
    }
    role {
      id
      name
      currentShift {
        id
        user {
          id
          firstname
          lastname
        }
      }
    }
  }
`;
