import {
  CANADA,
  CANADA_ABBREVIATION,
  EUROPE,
  EUROPE_ABBREVIATION,
  UNITED_STATES,
  UNITED_STATES_ABBREVIATION,
} from 'src/constants/strings';
import {IAuthRegionData} from 'src/types';
import CanadaFlagIcon from '../../svgs/CanadaFlagIcon.svg';
import USFlagIcon from '../../svgs/USFlagIcon.svg';
import EuropeFlagIcon from '../../svgs/EuropeFlagIcon.svg';

export const FlagIcons = {
  [CANADA_ABBREVIATION]: CanadaFlagIcon,
  [UNITED_STATES_ABBREVIATION]: USFlagIcon,
  [EUROPE_ABBREVIATION]: EuropeFlagIcon,
};

export const authRegionData: IAuthRegionData[] = [
  {
    region: CANADA_ABBREVIATION,
    regionName: CANADA,
    flagIcon: FlagIcons[CANADA_ABBREVIATION],
  },
  {
    region: UNITED_STATES_ABBREVIATION,
    regionName: UNITED_STATES,
    flagIcon: FlagIcons[UNITED_STATES_ABBREVIATION],
  },
  {
    region: EUROPE_ABBREVIATION,
    regionName: EUROPE,
    flagIcon: FlagIcons[EUROPE_ABBREVIATION],
  },
];
