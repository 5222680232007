import gql from 'graphql-tag';
import {DepartmentFragments} from 'src/gql/fragment/OrganizationScopeFragment';

export default gql`
  query FetchDepartmentsOnCallFor($userId: ID!) {
    profile(id: $userId) {
      departmentsOnCall {
        ...DepartmentFragments
      }
    }
  }
  ${DepartmentFragments}
`;
