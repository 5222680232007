import {toast} from 'react-toastify';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import client from 'src/apollo';
import {MESSENGER} from 'src/constants/routerPathName';
import LeaveChatMutation from 'src/gql/mutation/LeaveChatMutation';
import GetChatsQuery, {chatsPath, getChats} from 'src/gql/query/GetChatsQuery';
import * as R from 'ramda';

export const leaveChat = async (chatId: string) => {
  if (window.confirm('Are you sure you want to leave this chat?')) {
    try {
      await client.mutate({
        mutation: LeaveChatMutation,
        variables: {
          chatId,
        },
        update: () => {
          const chatsQuery = client.cache.readQuery({
            query: GetChatsQuery,
          });
          if (chatsQuery) {
            const chats = getChats(chatsQuery);
            const newChats = R.reject((c) => chatId === c.id, chats);
            const newQuery = R.assocPath(chatsPath, newChats, chatsQuery);
            client.writeQuery({
              query: GetChatsQuery,
              data: newQuery,
            });
            window.routerHistory.push(`/${MESSENGER}`);
          }
        },
      });

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.leaveChat,
        params: {
          chat_id: chatId,
        },
      });
    } catch (e) {
      console.error('Unable to leave chat', e);
      toast.error('Unable to leave chat, please promote someone to admin first');
    }
  }
};
