import * as React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '22'} height={props.height || '22'} viewBox="0 0 27 27">
    <path
      fill="none"
      fillRule="evenodd"
      stroke={props.stroke || '#FF3E55'}
      strokeWidth="1.5"
      d="M1.33 10.273C.92 8.636.875 6.92 1.294 5.285c.33-1.287.887-2.359 1.766-3.304C4.17.788 4.943.68 6.202 1.675a36.496 36.496 0 0 1 3.595 3.219c1.052 1.086 1.075 2.015.267 3.28a13.483 13.483 0 0 1-1.427 1.837c-.363.398-.446.696-.17 1.197 1.812 3.293 4.507 5.646 7.788 7.371.228.12.72.013.935-.17.568-.481 1.035-1.08 1.57-1.601.77-.75 1.37-.895 2.238-.277 1.403 1 2.8 2.037 4.037 3.227 1.306 1.256 1.247 2.503.061 3.895-2.054 2.412-4.646 2.78-7.525 1.892-4.032-1.243-7.305-3.729-10.395-6.478-2.256-2.007-4.231-4.27-5.332-7.128-.213-.556-.379-1.13-.514-1.666"
    />
  </svg>
);
