import * as React from 'react';
import Helmet from 'react-helmet';
import {Chat, User} from 'src/types';

interface Props {
  chats?: Chat[];
  priorityChats?: Chat[];
  currentUser?: User;
}

const normalIconPath = '/favicon-normal.ico';
const unreadIconPath = '/favicon-unread.ico';

// TODO-BUG: helmet should count notification numbers instead of unread chat numbers
// the unread indication will lost when not in messenger page with chatlist
// this also will not count for chat beyounds pagination, as well as cross-organization level
const isChatsContainUnread = (chat: Chat) => !!chat.lastUnreadMessage;

const HeaderModifier = ({chats, priorityChats}: Props) => {
  const hasUnreadChats = chats ? chats.some(isChatsContainUnread) : false;
  const hasUnreadPriorityChats = priorityChats ? priorityChats.some(isChatsContainUnread) : false;

  const iconPath = hasUnreadChats || hasUnreadPriorityChats ? unreadIconPath : normalIconPath;

  return (
    <Helmet defer={false}>
      <link rel="shortcut icon" href={iconPath} />
    </Helmet>
  );
};

export default HeaderModifier;
