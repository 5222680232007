import store from 'src/redux';

export const getOrganizationalUnitObject = () => {
  const {organizationId, type} = store.getState().organization;

  return {
    id: organizationId,
    type: type.toLowerCase(),
  };
};
