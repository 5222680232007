import gql from 'graphql-tag';

export const MESSAGES_READ = gql`
  mutation ReadMessages($chatId: ID!, $messageIds: [Int!]!) {
    chat(id: $chatId) {
      __typename
      ... on ChatMutation {
        messagesRead(ids: $messageIds) {
          __typename
          ... on AsyncResponse {
            requestId
          }
          ... on Error {
            message
          }
        }
      }
    }
  }
`;
