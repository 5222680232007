import gql from 'graphql-tag';

export type VerifyAddressValidationCodeResponse = {
  addressVerification: {
    verify: boolean;
  };
};

export const VERIFY_ADDRESS_VALIDATION_CODE = gql`
  mutation RequestVerification($address: String!, $type: AddressType!, $token: String!) {
    addressVerification(address: $address, type: $type) {
      verify(token: $token)
    }
  }
`;
