import * as React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" fillOpacity="0.4" d="M-899-173H541v921H-899z" />
      <path
        className="search-icon"
        fill={props.color || '#696969'}
        d="M18.202 16.082c.045.034.089.071.13.113l7.452 7.452c.45.449.451 1.176.005 1.622l-.522.522a1.15 1.15 0 0 1-1.623-.005l-7.451-7.452a1.164 1.164 0 0 1-.113-.13 10.041 10.041 0 0 1-5.993 1.972C4.517 20.176 0 15.66 0 10.088 0 4.517 4.516 0 10.087 0s10.087 4.517 10.087 10.088c0 2.245-.733 4.318-1.972 5.994zm-8.115 2.077a8.07 8.07 0 0 0 8.07-8.07 8.07 8.07 0 1 0-16.14 0 8.07 8.07 0 0 0 8.07 8.07z"
      />
    </g>
  </svg>
);
