import gql from 'graphql-tag';

export default gql`
  mutation RequestSwapShift(
    $desiredShiftId: Int!
    $surrenderingShiftId: Int!
    $note: String
    $shouldIgnoreWarnings: Boolean = false
  ) {
    locating {
      shift(id: $surrenderingShiftId) {
        requestSwap(desiredShiftId: $desiredShiftId, note: $note, shouldIgnoreWarnings: $shouldIgnoreWarnings) {
          id
        }
      }
    }
  }
`;
