import gql from 'graphql-tag';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';

export default gql`
  query FetchSupportUser {
    supportUser {
      ...GeneralUserFragment
    }
  }
  ${GeneralUserFragment}
`;
