import gql from 'graphql-tag';

export default gql`
  query ListSSOProfiles {
    listSSOProfiles {
      organization {
        id
        name
        imageUrl
      }
      auth0Id
      domain
      ssoVendor
    }
  }
`;
