import * as React from 'react';

export default (props) => (
  <svg
    height="17.333332"
    id="svg4537"
    width="25.333332"
    version="1.1"
    viewBox="0 0 25.333332 17.333332"
    xmlSpace="preserve"
  >
    <defs id="defs4541">
      <clipPath id="clipPath4555">
        <path
          id="path4553"
          d="m 9.456322,10.38929 c 0.663378,0 1.201148,0.5448 1.201148,1.21686 0,0.67205 -0.53777,1.21685 -1.201148,1.21685 -0.663377,0 -1.20115,-0.5448 -1.20115,-1.21685 0,-0.67206 0.537773,-1.21686 1.20115,-1.21686 z"
        />
      </clipPath>
      <clipPath id="clipPath4561">
        <path id="path4559" d="M -282,-438 H 93 v 667 h -375 z" />
      </clipPath>
      <clipPath id="clipPath4571">
        <path
          id="path4569"
          d="m 18.03908,7.092713 c 0.5307,0 0.96092,0.435844 0.96092,0.973485 0,0.537642 -0.43022,0.973486 -0.96092,0.973486 -0.5307,0 -0.96092,-0.435844 -0.96092,-0.973486 0,-0.537641 0.43022,-0.973485 0.96092,-0.973485 z"
        />
      </clipPath>
      <clipPath id="clipPath4577">
        <path id="path4575" d="M -282,-438 H 93 v 667 h -375 z" />
      </clipPath>
      <clipPath id="clipPath4587">
        <path
          id="path4585"
          d="m 0.9827586,7.092713 c 0.5307014,0 0.9609194,0.435844 0.9609194,0.973485 0,0.537642 -0.430218,0.973486 -0.9609194,0.973486 -0.5307012,0 -0.96091952,-0.435844 -0.96091952,-0.973486 0,-0.537641 0.43021832,-0.973485 0.96091952,-0.973485 z"
        />
      </clipPath>
      <clipPath id="clipPath4593">
        <path id="path4591" d="M -282,-438 H 93 v 667 h -375 z" />
      </clipPath>
      <clipPath id="clipPath4603">
        <path
          id="path4601"
          d="M 1.550575,6.93784 C 1.506897,6.915716 1.485057,6.871466 1.506897,6.805092 L 3.472414,0.7429324 C 3.625287,0.300439 4.018391,0.01281826 4.477011,0.01281826 H 14.50115 c 0.45862,0 0.85172,0.28762074 1.0046,0.73011414 l 2.00919,6.0621596 c 0.02184,0.04425 0,0.110624 -0.04368,0.132748 -0.15287,0.088499 -0.30574,0.199122 -0.41494,0.33187 -0.04368,0.04425 -0.08735,0.04425 -0.13103,0.022125 -2.24943,-1.570852 -5.35058,-0.70799 -6.50805,1.814223 L 10.04598,9.924671 C 10.02414,9.96892 9.958621,9.991045 9.914943,9.96892 9.783908,9.924671 9.631034,9.880421 9.478161,9.880421 c -0.152874,0 -0.305747,0.022125 -0.436782,0.088499 -0.043678,0.022125 -0.109195,0 -0.131034,-0.044249 L 8.53908,9.128183 C 7.403448,6.650219 4.345977,5.809482 2.14023,7.358209 2.096552,7.380334 2.031034,7.380334 2.009195,7.336084 1.878161,7.159087 1.725287,7.026339 1.550575,6.93784 Z"
        />
      </clipPath>
      <clipPath id="clipPath4609">
        <path id="path4607" d="M -282,-438 H 93 v 667 h -375 z" />
      </clipPath>
      <clipPath id="clipPath4619">
        <path
          id="path4617"
          style={{clipRule: 'evenodd'}}
          d="m 11.55,1.497157 h -0.57699 v 2.89048 H 10.93418 L 9.760788,1.613664 H 9.289212 L 8.115822,4.387637 H 8.076986 V 1.497157 H 7.5 V 5.5 H 8.226781 L 9.500034,2.459726 H 9.547192 L 10.82322,5.5 H 11.55 Z"
        />
      </clipPath>
      <clipPath id="clipPath4625">
        <path id="path4623" d="M -282,-438 H 93 v 667 h -375 z" />
      </clipPath>
      <clipPath id="clipPath4635">
        <path id="path4633" d="M -282,-438 H 93 v 667 h -375 z" />
      </clipPath>
    </defs>
    <g id="g4545" transform="matrix(1.3333333,0,0,-1.3333333,0,17.333333)">
      <g id="g4547" />
      <g id="g4549">
        <g id="g4551" clipPath="url(#clipPath4555)">
          <g id="g4557" clipPath="url(#clipPath4561)">
            <path
              id="path4563"
              style={{
                fill: '#ffc705',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 3.255172,5.389289 h 12.4023 v 12.43371 h -12.4023 z"
            />
          </g>
        </g>
      </g>
      <g id="g4565">
        <g id="g4567" clipPath="url(#clipPath4571)">
          <g id="g4573" clipPath="url(#clipPath4577)">
            <path
              id="path4579"
              style={{
                fill: '#ffc705',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="M 12.07816,2.092713 H 24 v 11.94697 H 12.07816 Z"
            />
          </g>
        </g>
      </g>
      <g id="g4581">
        <g id="g4583" clipPath="url(#clipPath4587)">
          <g id="g4589" clipPath="url(#clipPath4593)">
            <path
              id="path4595"
              style={{
                fill: '#ffc705',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m -4.978161,2.092713 h 11.92184 v 11.94697 h -11.92184 z"
            />
          </g>
        </g>
      </g>
      <g id="g4597">
        <g id="g4599" clipPath="url(#clipPath4603)">
          <g id="g4605" clipPath="url(#clipPath4609)">
            <path
              id="path4611"
              style={{
                fill: '#ffc705',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m -3.501671,-4.987182 h 26.02518 v 19.96478 h -26.02518 z"
            />
          </g>
        </g>
      </g>
      <g id="g4613">
        <g id="g4615" clipPath="url(#clipPath4619)">
          <g id="g4621" clipPath="url(#clipPath4625)">
            <path
              id="path4627"
              style={{
                fill: '#4a4a4a',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 2.5,-3.502843 h 14.05 v 14.00284 H 2.5 Z"
            />
          </g>
        </g>
      </g>
      <g id="g4629">
        <g id="g4631" clipPath="url(#clipPath4635)">
          <g id="g4637" transform="matrix(1,0,0,-1,7.5,5.5)">
            <path
              id="path4639"
              style={{
                fill: 'none',
                stroke: '#4a4a4a',
                strokeWidth: 0.25,
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                strokeDasharray: 'none',
                strokeOpacity: 1,
              }}
              d="M 4.05,4.002843 H 3.473014 V 1.112363 H 3.434178 L 2.260788,3.886336 H 1.789212 L 0.6158219,1.112363 H 0.5769863 v 2.89048 H 0 V 0 H 0.7267809 L 2.000034,3.040274 H 2.047192 L 3.323219,0 H 4.05 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
