import gql from 'graphql-tag';

export const OrganizationUserResultFields = gql`
  fragment OrganizationUserResultFields on OrganizationUserResult {
    __typename
    ... on Node {
      id
    }
    ... on OrganizationMember {
      firstName
      lastName
      username
      role
      id
    }
    ... on PublicUser {
      username
      firstName
      lastName
    }
  }
`;
