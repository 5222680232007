import {toast} from 'react-toastify';
import client from 'src/apollo';
import GetSupportUser from 'src/gql/query/FetchSupportUser';
import createNewChat from './createNewChat';

const messageSupportUser = async () => {
  try {
    toast.warn('Creating Chat with Support...', {
      autoClose: false,
    });
    const fetchSupportResult = await client.query({query: GetSupportUser});
    const {supportUser} = fetchSupportResult.data;
    const newChatId = await createNewChat([supportUser]);
    window.routerHistory.push(`/messenger/${newChatId}`);
    toast.dismiss();
  } catch (e) {
    console.error(e);
    toast.dismiss();
    toast.error('Failed to start chat with support, please check your internet connection and try again');
  }
};

export default messageSupportUser;
