import React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '29'} height={props.height || '29'} viewBox="0 0 29 29">
    <path
      fill="#FF3E55"
      fillRule="evenodd"
      stroke="#FF3E55"
      strokeWidth=".75"
      d="M23.31 23.779a21.053 21.053 0 0 0-2.977-.986c-.717-.176-1.444-.328-2.114-.656-.274-.135-.488-.34-.545-.654-.11-.612-.22-1.227-.193-1.855.005-.113.024-.206.112-.287.417-.387.609-.885.702-1.436.093-.551.135-1.112.308-1.65.044-.133.102-.249.24-.3.306-.117.422-.373.469-.664a5.92 5.92 0 0 1 .304-1.158c.152-.404.22-.828.174-1.26-.021-.205-.053-.439-.34-.42-.19.013-.178-.064-.143-.199.167-.637.28-1.284.342-1.942.08-.855.075-1.702-.105-2.546-.086-.398-.366-.69-.53-1.044-.18-.39-.455-.694-.856-.84-.935-.341-1.847-.751-2.818-.99a3.17 3.17 0 0 0-1.815.09c-.362.124-.72.262-1.084.38a3.531 3.531 0 0 0-1.548.964 1.126 1.126 0 0 1-.49.309c-.23.075-.457.17-.602.378-.262.376-.44.792-.563 1.234-.223.8.015 1.592.036 2.387.017.618.117 1.233.275 1.842-.384-.035-.514.086-.525.463-.006.188.019.372.055.557.106.534.4 1.013.447 1.563.036.427.187.775.612.955.099.042.118.15.144.245.114.422.188.852.236 1.285.077.693.29 1.32.793 1.822.08.08.128.161.11.283-.054.379-.096.759-.149 1.137-.15 1.1-.244 1.209-1.312 1.541-.025.008-.05.02-.076.026-1.471.383-2.918.833-4.312 1.429a12.838 12.838 0 0 1-4.013-9.341c0-7.12 5.76-12.88 12.88-12.88 7.12 0 12.88 5.76 12.88 12.88a12.835 12.835 0 0 1-4.008 9.338M14.44 1C7.01 1 1 7.011 1 14.44c0 7.43 6.011 13.44 13.44 13.44 7.43 0 13.44-6.01 13.44-13.44C27.88 7.011 21.87 1 14.44 1"
    />
  </svg>
);
