import * as React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '24'}
    height={props.height || '24'}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || '#FF3E55'}
      fillRule="evenodd"
      d="M12.707 5.707c.39-.39.39-1.024 0-1.414-.39-.39-1.024-.39-1.414 0l-7 7c-.39.39-.39 1.024 0 1.414l7 7c.39.39 1.024.39 1.414 0 .39-.39.39-1.024 0-1.414L7.414 13H19c.552 0 1-.448 1-1s-.448-1-1-1H7.414l5.293-5.293z"
      clipRule="evenodd"
    />
  </svg>
);
