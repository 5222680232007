import gql from 'graphql-tag';
import {OrganizationUserResultFragment} from '../fragment/OrganizationUserResultFragment';

export const UPDATE_SELF_PROFILE_NOTE = gql`
  mutation UpdateProfileNote($id: ID!, $details: UpdateHiddenNoteDetails!) {
    selfMutation {
      note(id: $id) {
        __typename
        ... on AccessForbiddenError {
          __typename
          message
        }
        ... on NoteNotFoundError {
          __typename
          message
        }
        ... on NoteDeletedError {
          __typename
          message
        }
        ... on ProfileNoteMutation {
          update(details: $details) {
            ... on ProfileNote {
              __typename
              id
              note
              access
              createdAt
              updatedAt
              editedBy {
                ...OrganizationUserResultFragment

                __typename
              }
              createdBy {
                ...OrganizationUserResultFragment

                __typename
              }
            }
            ... on ExceedMaxWordCountError {
              __typename
              message
              maxCount
            }
          }
        }
      }
    }
  }
  ${OrganizationUserResultFragment}
`;
