import client from 'src/apollo';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';

export default function getGeneralUserFragment(userId: string) {
  const userFragment = client.readFragment({
    id: `User:${userId}`,
    fragment: GeneralUserFragment,
    fragmentName: 'GeneralUserFragment',
  });
  return userFragment;
}
