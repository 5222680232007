import React from 'react';

export const RefreshIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_26)">
      <path
        d="M6 5.49998C6 5.22384 5.77614 4.99998 5.5 4.99998H3.39C4.03667 4.00614 4.98721 3.24789 6.09991 2.83827C7.21261 2.42864 8.4279 2.38957 9.56462 2.72687C10.7013 3.06418 11.6986 3.7598 12.4078 4.71004C13.0169 5.52619 13.3855 6.49262 13.4773 7.50037C13.5024 7.77552 13.724 7.99998 14.0003 7.99998C14.2763 7.99998 14.5022 7.776 14.4813 7.50072C14.3931 6.33995 13.9944 5.2208 13.3227 4.26253C12.5552 3.16748 11.4686 2.3358 10.2111 1.88091C8.95358 1.42602 7.58637 1.37006 6.29589 1.72065C5.00541 2.07124 3.85447 2.81132 3 3.83998V2.49998C3 2.22384 2.77614 1.99998 2.5 1.99998C2.22386 1.99998 2 2.22384 2 2.49998V4.99998C2 5.55226 2.44772 5.99998 3 5.99998H5.5C5.77614 5.99998 6 5.77612 6 5.49998Z"
        fill="#4A4A4A"
      />
      <path
        d="M10 10.5C10 10.7761 10.2239 11 10.5 11H12.61C11.9633 11.9938 11.0128 12.7521 9.90009 13.1617C8.78739 13.5713 7.5721 13.6104 6.43539 13.2731C5.29867 12.9358 4.30139 12.2402 3.59221 11.2899C2.98311 10.4738 2.61455 9.50736 2.5227 8.49961C2.49763 8.22446 2.27602 8 1.99973 8C1.72366 8 1.49777 8.22398 1.51869 8.49926C1.60689 9.66003 2.00561 10.7792 2.67727 11.7374C3.4448 12.8325 4.53141 13.6642 5.78892 14.1191C7.04642 14.574 8.41363 14.6299 9.70411 14.2793C10.9946 13.9287 12.1455 13.1887 13 12.16V13.5C13 13.7761 13.2239 14 13.5 14C13.7761 14 14 13.7761 14 13.5V11C14 10.4477 13.5523 10 13 10H10.5C10.2239 10 10 10.2239 10 10.5Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_26">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
