import gql from 'graphql-tag';
import {
  AddressFragment,
  WorkStatusDirSyncFragment,
  AddressDirSyncFragment,
} from 'src/gql/v2/fragment/OrganizationUserResultDirSyncFragment';
import {FullOrganizationMember} from 'src/types';

export type FetchSelfOrgMemberResult = {
  organizationalUnitQuery: {
    organizationalUnit: {
      me: FullOrganizationMember;
    };
  };
};

export const FETCH_SELF_ORG_MEMBER = gql`
  query GetSelfOrgMember($organizationalUnit: OrganizationalUnitInput!, $isProfileAddressLabelFlagEnabled: Boolean!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on OrganizationalUnitNotFoundError {
          __typename
          message
        }
        ... on Organization {
          __typename
          me {
            id
            firstName
            lastName
            username
            avatar {
              url
            }
            role
            workStatus {
              ...WorkStatusDirSyncFragment
            }
            addresses {
              ...AddressFragment
            }
          }
        }
      }
    }
  }
  ${WorkStatusDirSyncFragment}
  ${AddressFragment}
`;

export const FETCH_SELF_ORG_DIR_SYNC_MEMBER = gql`
  query GetSelfOrgMember($organizationalUnit: OrganizationalUnitInput!, $isProfileAddressLabelFlagEnabled: Boolean!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on OrganizationalUnitNotFoundError {
          __typename
          message
        }
        ... on Organization {
          __typename
          id
          name
          me {
            id
            firstName
            lastName
            avatar {
              url
            }
            username
            role
            workStatus {
              ...WorkStatusDirSyncFragment
            }
            isDirectorySynced
            isSSOEnabled
            addresses {
              ...AddressDirSyncFragment
            }
          }
        }
      }
    }
  }
  ${WorkStatusDirSyncFragment}
  ${AddressDirSyncFragment}
`;
