import React from 'react';
import {Formik} from 'formik';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import CreateNewOrg from 'src/microfrontend/joinCreateOrg/CreateNewOrg';
import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import NewNextStepButton from '../components/NextStepButton';

interface ICreateNewOrgViewProps {
  inputData?: any;
  onBackButtonClick?: any;
  viewModel?: any;
}

const CreateNewOrgView = ({inputData, onBackButtonClick, viewModel}: ICreateNewOrgViewProps) => {
  const onNextClick = (values: {orgName: string}) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signupPersonalInfoNextPressed,
      params: {
        organizatin_code: values.orgName,
      },
    });
  };

  return (
    <div>
      <CreateNewOrg
        onBackButtonClick={onBackButtonClick}
        currentStep={6}
        content={
          <Formik
            initialValues={{
              orgName: '',
            }}
            onSubmit={async (values, actions) => {
              console.log(values);
              const result = await viewModel.handleNextButtonPressed(values);
              console.log(result);
              if (result?.error) {
                actions.setFieldError('orgName', result.error);
              }
            }}
          >
            {({handleSubmit, values, isValid, handleChange, handleBlur, setFieldValue, touched, errors}) => (
              console.log(errors),
              (
                <form onSubmit={handleSubmit}>
                  <StyledTextField
                    fullWidth={true}
                    margin="normal"
                    variant="outlined"
                    label="Org name"
                    value={values.orgName}
                    name="orgName"
                    placeholder="Enter Organization Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.orgName && errors.orgName}
                    error={touched.orgName && Boolean(errors.orgName)}
                  />
                  <NewNextStepButton label="Next" onClick={() => onNextClick(values)} disabled={!isValid}>
                    Next
                  </NewNextStepButton>
                </form>
              )
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default CreateNewOrgView;
