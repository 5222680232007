import gql from 'graphql-tag';

export default gql`
  query GetUser($userId: ID!, $organizationId: Int!) {
    organizationQuery {
      organization(id: $organizationId) {
        user(id: $userId) {
          __typename
        }
      }
    }
  }
`;
