import gql from 'graphql-tag';
import EscalationMembersFragment from 'src/gql/fragment/EscalationMembersFragment';

export default gql`
  fragment EscalationLadderFragment on EscalationLadder {
    id
    name
    validFrom
    validUntil
    escalationLevels {
      id
      level
      name
      validFrom
      escalationMembers {
        ...EscalationMembersFragment
      }
      escalationPolicy {
        id
        name
        channels {
          numAttempts
          timeBetweenAttempts
        }
      }
    }
    organization {
      id
      name
    }
    department {
      id
      name
    }
  }
  ${EscalationMembersFragment}
`;
