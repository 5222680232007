import gql from 'graphql-tag';
import {userFragmentString} from '../fragment/GeneralUserFragment';

export const FETCH_SCHEDULES = gql`
  query FetchSchedule(
    $departmentId: Int!
    $shiftStartDate: String!
    $shiftEndDate: String!
    $month: ScheduleMonths
    $year: Int
  ) {
    locating {
      department(id: $departmentId) {
        id
        name
        schedule(month: $month, year: $year) {
          ...ScheduleFragment
        }
        __typename
      }
    }
  }
  fragment ScheduleFragment on Schedule {
    id
    name
    startDate
    endDate
    state
    roles {
      ...RoleFragment
    }
    createdAt
    updatedAt
    __typename
  }
  fragment RoleFragment on Role {
    id
    name
    auditId
    startTime
    endTime
    duration
    repeatRule
    pagerNumber
    required
    displayPosition
    currentShift {
      ...ShiftFragment
    }
    nextShift {
      ...ShiftFragment
    }
    shifts(startDate: $shiftStartDate, endDate: $shiftEndDate) {
      ...ShiftFragment
    }
    createdAt
    updatedAt
    __typename
  }
  fragment ShiftFragment on Shift {
    id
    status
    user {
     ${userFragmentString}
    }
    startDate
    endDate
    warningShifts {
      conflictShift {
        ...ConflictShiftFragment
      }
    }
    conflictingShifts {
      conflictShift {
        ...ConflictShiftFragment
      }
    }
    __typename
  }
  fragment ConflictShiftFragment on Shift {
    id
    startDate
    endDate
    role {
      id
      name
      department {
        id
        name
      }
      site {
        id
        name
      }
    }
  }
`;
