import gql from 'graphql-tag';
export default gql`
  query FetchOrganizationForEmail($email: String!) {
    organizationForEmail(email: $email) {
      image
      name
      id
      termsOfServiceUrl
      privacyPolicyUrl
    }
  }
`;
