import gql from 'graphql-tag';
import EscalationMembersFragment from 'src/gql/fragment/EscalationMembersFragment';

export default gql`
  fragment ActivatedLevelFragment on ActivatedLevel {
    id
    name
    level
    currentAttempt
    nextAttemptAt
    escalationMembers {
      ...EscalationMembersFragment
    }
  }
  ${EscalationMembersFragment}
`;
