import gql from 'graphql-tag';
import UserInviteFragment from 'src/gql/fragment/InviteFragment';

export default gql`
  query FetchInvites($status: [UserInvitationStatus!], $afterDate: String, $beforeDate: String) {
    me {
      sentInvites(status: $status, afterDate: $afterDate, beforeDate: $beforeDate) {
        ...UserInviteFragment
      }
    }
  }
  ${UserInviteFragment}
`;
