import gql from 'graphql-tag';
import MessageFragment from 'src/gql/fragment/MessageFragment';

export const chatFragmentString = `
  id
  type
  title
  muted
  unreadPriorityMessages
  dateCreated
  image {
    id
    url
  }
  status
`;

export default gql`
  fragment ChatFragment on Chat {
    ${chatFragmentString}
    lastUnreadMessage {
      id
    }
    lastMessage {
      ...MessageFragment
    }
  }
  ${MessageFragment}
`;
