import gql from 'graphql-tag';
import {chatFragmentString} from 'src/gql/fragment/ChatFragment';
import {userFragmentStringWithAddresses} from 'src/gql/fragment/GeneralUserFragment';

export default gql`
  query ChatMembersQuery($chatId: ID!) {
    chat(chatId: $chatId) {
      id
      type
      title
      muted
      image {
        id
        url
        __typename
      }
      status
      members {
        privilege
        id
        firstname
        lastname
        profilePic {
          url
          __typename
        }
        role
        status
        workStatus
        statusExpiryDate
        __typename
      }
      __typename
    }
  }
`;
