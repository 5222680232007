import {initializeApp} from 'firebase/app';
import {getRemoteConfig, isSupported} from 'firebase/remote-config';
import {getMessaging} from 'firebase/messaging';

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
};

const app = initializeApp(firebaseConfig);

// firebase remote config is not supported on incognito for mozilla
export const isFirebaseRemoteConfigSupported = async () => {
  try {
    return isSupported();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const remoteConfigObject = () => {
  const remoteConfig = getRemoteConfig(app);
  remoteConfig.defaultConfig = {idle_timeout: 900000, forced_timeout: 28800000};

  return remoteConfig;
};

export const getMessagingInstance = () => {
  const messagingInstance = getMessaging(app);
  return messagingInstance;
};
