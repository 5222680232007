import React from 'react';

export default (props) => (
  <svg
    height={props.height || '24'}
    width={props.width || '24'}
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 12.4394V12.6465L17.1464 12.793L19.1768 14.8233L19.5303 14.4697L19.1768 14.8233C19.2236 14.8702 19.25 14.9337 19.25 15C19.25 15 19.25 15 19.25 15.0001V17.25C19.25 17.3163 19.2237 17.3799 19.1768 17.4268C19.1299 17.4737 19.0663 17.5 19 17.5H13.75H13.25V18V18.7494C13.2495 19.2036 13.1539 19.6526 12.9694 20.0676C12.785 20.4826 12.5157 20.8543 12.1789 21.159C11.8421 21.4637 11.4453 21.6945 11.0139 21.8366C10.5848 21.9779 10.131 22.0284 9.68133 21.9848C8.8622 21.8787 8.11064 21.4749 7.56996 20.8503C7.02777 20.2239 6.73577 19.4195 6.74993 18.5911L6.75 18.5911V18.5826V18V17.5H6.25H1C0.933695 17.5 0.870107 17.4737 0.823223 17.4268C0.776339 17.3799 0.75 17.3163 0.75 17.25V15.0001C0.75 15 0.75 15 0.75 15C0.750022 14.9337 0.77636 14.8702 0.823225 14.8233L0.469675 14.4697L0.823228 14.8233L2.85355 12.793L3 12.6465V12.4394V9.75029C3.00234 8.01561 3.64774 6.3434 4.81145 5.05697C5.97523 3.77044 7.57472 2.96113 9.30061 2.78553L9.75 2.73981V2.2881V1.25H10.25V2.2881V2.73981L10.6994 2.78553C12.4253 2.96113 14.0248 3.77044 15.1886 5.05697C16.3523 6.34349 16.9978 8.01584 17 9.75065V12.4394ZM7.75 17.5H7.25V18V18.75C7.25 19.4793 7.53973 20.1788 8.05546 20.6945C8.57118 21.2103 9.27065 21.5 10 21.5C10.7293 21.5 11.4288 21.2103 11.9445 20.6945C12.4603 20.1788 12.75 19.4793 12.75 18.75V18V17.5H12.25H7.75ZM18.25 17H18.75V16.5V15.3106V15.1035L18.6036 14.957L16.5733 12.9267L16.5732 12.9266C16.5264 12.8798 16.5 12.8163 16.5 12.75C16.5 12.75 16.5 12.7499 16.5 12.7499V9.75C16.5 8.02609 15.8152 6.37279 14.5962 5.15381C13.3772 3.93482 11.7239 3.25 10 3.25C8.27609 3.25 6.62279 3.93482 5.40381 5.15381C4.18482 6.37279 3.5 8.02609 3.5 9.75V12.7499C3.5 12.7499 3.5 12.75 3.5 12.75C3.49996 12.8163 3.47361 12.8798 3.42675 12.9266L3.42669 12.9267L1.39644 14.957L1.25 15.1035V15.3106V16.5V17H1.75H18.25Z"
      fill={props.fill || '#FF3E55'}
      stroke={props.fill || '#FF3E55'}
    />
  </svg>
);
