import {NO_RECORD, UNKNOWN_ERROR_STRING} from 'src/constants/networkError';

export const determineError = (e: any) => {
  if (!navigator.onLine) return 'Unable to connect to the server. Please check your internet connection.';

  if (e.networkError && e.networkError.result) {
    if (e.networkError.result.errors) return e.networkError.result.errors[0].message;
    if (e.networkError.result.inner && e.networkError.result.inner.code === NO_RECORD) return false;
  }
  return UNKNOWN_ERROR_STRING;
};
