import React from 'react';
import ContentLoader from 'react-content-loader';

const DepartmentListLoader = (props) => {
  return (
    <ContentLoader backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props} speed={1}>
      <rect x="17" y="12" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="62" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="112" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="162" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="212" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="262" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="312" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="362" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="412" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="462" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="512" rx="3" ry="3" width="522" height="42" />
    </ContentLoader>
  );
};

export default DepartmentListLoader;
