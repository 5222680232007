import {localStorageService} from '../../../../services/localStorageService';
import {OrganizationAccountsCacheData} from '../../../../types/sta';
import {ORGANIZATION_ACCOUNTS_DATA} from '../../../../constants/storageKeys';
import {AuthPayloadDTO} from '../../../../types';
import getParsedAuthInfo from '../../../../utils/localStorageHandler';

export const LogoutViewModel = (logout?: any) => {
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
  const getAuthInfo = getParsedAuthInfo();

  const logoutOfAccount = (currentSelectedAcc: AuthPayloadDTO | null) => {
    if (!currentSelectedAcc) {
      return {
        success: false,
      };
    }

    try {
      if (currentCacheData) {
        let newCachedData = currentCacheData;

        newCachedData.savedOrganizations = currentCacheData?.savedOrganizations?.map((org) =>
          org.user.id === currentSelectedAcc?.user.id
            ? {
                ...org,
                accessToken: null,
                refreshToken: null,
              }
            : org,
        );

        if (currentSelectedAcc.user.id === getAuthInfo?.user.id) {
          logout && logout();
        }

        localStorageService.setItem(ORGANIZATION_ACCOUNTS_DATA, newCachedData);

        return {
          success: true,
        };
      }
    } catch (err) {
      console.log(err, 'err');
      return {
        success: false,
      };
    }
  };

  const removeAccount = (currentSelectedAcc: AuthPayloadDTO | null) => {
    if (!currentSelectedAcc) {
      return {
        success: false,
      };
    }

    try {
      if (currentCacheData) {
        let newCachedData = currentCacheData;

        newCachedData.savedOrganizations = currentCacheData?.savedOrganizations?.filter(
          (org) => org.user.id !== currentSelectedAcc?.user.id,
        );

        localStorageService.setItem(ORGANIZATION_ACCOUNTS_DATA, newCachedData);

        if (newCachedData.savedOrganizations.length === 0 || !window.location.href.includes('/login')) {
          logout();
        }

        return {
          success: true,
        };
      }
    } catch (err) {
      return {
        success: false,
      };
    }
  };

  return {logoutOfAccount, removeAccount};
};
