import gql from 'graphql-tag';
import {userFragmentStringWithAddresses} from 'src/gql/fragment/GeneralUserFragment';

export default gql`
  mutation RemoveChatMember($chatId: ID!, $userId: ID!) {
    chat(chatId: $chatId) {
      admin {
        removeMember(userId: $userId) {
          members {
            privilege
            ${userFragmentStringWithAddresses}
          }
        }
      }
    }
  }
`;
