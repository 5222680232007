// ones without params
export const CHANGEPASSWORD = 'changepassword';
export const ARCHIVED = 'archived';
export const TEMPLATEREPORT = 'templatereport';
export const MESSENGER = 'messenger';
export const LOCATING = 'on-call';
export const CONTACTS = 'contacts';
export const SCHEDULING = 'scheduling';
export const JOINORGANIZATION = 'joinorganization';
export const SCHEDULINGQUERYPARAM = 'view';
export const AUDIT_LOGS = 'audit-logs';
export const DEBUG = 'debug';
