import React from 'react';
import LocatingPageRepository from '../../../pages/LocatingPage/LocatingPageRepository';
import getParsedAuthInfo from '../../../utils/localStorageHandler';
import {UserRepository} from '../../../data/repository/UserRepository';
import {AddressType} from '../../../types';
import {MissingBannerType} from '../../../microfrontend/components/AddressBanner';
import {CustomFieldsRepository} from '../../../pages/LocatingPage/locating/profile-section/custom-fields-section/CustomFieldsRepository';

export default function AddressBannerViewModel() {
  const repo = UserRepository();

  const getMissingAddressInformation = () => {
    const {data, error} = repo.useFetchUserAddresses();

    if (!data || error) {
      return {error: error || 'No addresses found'};
    }

    const addresses = data.organizationalUnitQuery.organizationalUnit.me.addresses;

    const isPhoneNumberPresent = addresses.some((address) => address.type === AddressType.PHONE_NUMBER);
    const isEmailPresent = addresses.some((address) => address.type === AddressType.EMAIL);

    if (!isEmailPresent && !isPhoneNumberPresent) {
      return {type: MissingBannerType.BOTH};
    }

    if (!isEmailPresent) {
      return {type: MissingBannerType.EMAIL};
    }

    if (!isPhoneNumberPresent) {
      return {type: MissingBannerType.PHONE};
    }

    return {type: null, error: error || 'Error occurred when getting address information for user'};
  };

  return {getMissingAddressInformation};
}
