import gql from 'graphql-tag';
import {ProfileFieldFragmentType} from '../fragment/ProfileFieldFragment';
import {ProfileSectionFragment} from '../fragment/ProfileSectionFragment';

const UserProfileResultFragment = gql`
  fragment UserProfileResultFragment on UserProfileResult {
    ... on UserNotFoundError {
      __typename
      message
    }
    ... on UserNotMemberOfOrganization {
      __typename
      message
    }
    ... on GeneralUser {
      firstname: firstName
      lastName
      username
      __typename
    }

    ... on Node {
      id
    }

    ... on OrganizationMember {
      customFields {
        ...ProfileSectionFragment
      }
    }

    ... on FullOrganizationMember {
      customFields {
        ...ProfileSectionFragment
      }
    }
  }

  ${ProfileSectionFragment}
`;

export type FetchUserProfileCustomFieldsQueryResult = {
  organizationalUnitQuery: {
    organizationalUnit: {
      member: {
        id: string;
        customFields: Array<{
          id: string;
          sectionId: string;
          label: string;
          labelOptions: string[];
          maxFields: number;
          sections: Array<{id: string; label: string}>;
          fields: Array<ProfileFieldFragmentType & {id: string}>;
          __typename: string;
        }>;
      };
    };
  };
};

export const FetchUserProfileCustomFieldsQuery = gql`
  query FetchUserProfileFields($organizationalUnit: OrganizationalUnitInput!, $userId: ID!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on Error {
          __typename
          message
        }
        ... on Organization {
          member(id: $userId) {
            ...UserProfileResultFragment
            __typename
          }
        }
      }
    }
  }

  ${UserProfileResultFragment}
`;
