import {
  UNAUTH_REQUEST_UNDERSCORE,
  INVALID_TOKEN_UNDERSCORE,
  ACCESS_TOKEN_UNDERSCORE,
  INVALID_ACCESS_TOKEN,
} from 'src/constants/networkError';
import {AuthRegion, User} from '../types';

export const ALLOWED_LOCAL_STORAGE_USER_KEY_LIST = ['username', 'firstname', 'lastname', 'organizationId', 'id'];

export const USER_AUTH_EXPIRATION_NAMES = [
  UNAUTH_REQUEST_UNDERSCORE,
  INVALID_TOKEN_UNDERSCORE,
  ACCESS_TOKEN_UNDERSCORE,
  INVALID_ACCESS_TOKEN,
];

export const SINGLE = 'single';
export const GROUP = 'group';
export const USERS = 'Users';
export const GROUPS = 'Groups';
export const MESSAGES = 'Messages';
export const CONTACT_LIST_ERROR = 'An error occured while loading the contacts.';
export const NO_USERS = 'No contacts found';

export enum PagerType {
  ALPHANUMERIC_PAGER = 'alphanumeric_pager',
  NUMERIC_PAGER = 'pager',
}

export enum PagerTypeEvent {
  ALPHANUMERIC_PAGER = 'alphanumeric_pager',
  NUMERIC_PAGER = 'numeric_pager',
}

export type UserGeoData = {
  country: string;
  country_code: AuthRegion | 'US';
  country_code_iso3: AuthRegion | 'US';
  ip: string;
};
