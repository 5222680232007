import React, {useEffect} from 'react';
import {HYPERCARE_REGION} from '../constants/storageKeys';
import {REGION_CODE_PARAM_OVERRIDE} from '../constants/strings';

export const useRegionParamOverride = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const regionCodeOverride = params.get(REGION_CODE_PARAM_OVERRIDE) || '';
    if (regionCodeOverride) {
      window.localStorage.setItem(HYPERCARE_REGION, regionCodeOverride);
    }
  }, []);
  return null;
};
