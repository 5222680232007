import gql from 'graphql-tag';

export const UPDATE_PROFILE_FIELDS = gql`
  mutation UpdateProfileFields($details: MemberDetails!) {
    selfMutation {
      updateProfile(details: $details) {
        ... on UserNotFoundError {
          __typename
          message
        }
        ... on CannotModifyDirSyncManagedFields {
          __typename
          message
        }
        ... on FullOrganizationMember {
          __typename
          id
          firstname: firstName
          lastname: lastName
          username
        }
      }
    }
  }
`;
