import * as React from 'react';

export default (props) => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.33332 13.6667C1.99999 13.6667 1.66666 13.6 1.33332 13.4C0.399991 12.8667 0.066658 11.6 0.599991 10.6667L6.26666 1.2C6.46666 0.933337 6.66666 0.66667 6.93332 0.533337C7.39999 0.26667 7.93332 0.200004 8.46666 0.333337C8.99999 0.46667 9.39999 0.800004 9.73333 1.26667L15.3333 10.6667C15.5333 11 15.6 11.3333 15.6 11.6667C15.6 12.2 15.4 12.7333 15 13.0667C14.6667 13.4667 14.2 13.6667 13.6667 13.6667H2.33332ZM7.39999 1.93334L1.79999 11.3333C1.59999 11.6667 1.73332 12.0667 2.06666 12.2667C2.13332 12.3333 2.26666 12.3333 2.33332 12.3333H13.6C13.8 12.3333 13.9333 12.2667 14.0667 12.1333C14.2 12 14.2667 11.8667 14.2667 11.6667C14.2667 11.5333 14.2667 11.4667 14.2 11.3333L8.59999 1.93334C8.39999 1.6 7.99999 1.53334 7.66666 1.66667C7.53332 1.73334 7.46666 1.8 7.39999 1.93334Z"
      fill="#4A4A4A"
    />
    <path
      d="M7.99999 8.33334C7.59999 8.33334 7.33332 8.06667 7.33332 7.66667V5C7.33332 4.6 7.59999 4.33334 7.99999 4.33334C8.39999 4.33334 8.66666 4.6 8.66666 5V7.66667C8.66666 8.06667 8.39999 8.33334 7.99999 8.33334Z"
      fill="#4A4A4A"
    />
    <path
      d="M7.99999 11C7.79999 11 7.66666 10.9333 7.53332 10.8C7.39999 10.6667 7.33332 10.5333 7.33332 10.3333C7.33332 10.2667 7.33332 10.1333 7.39999 10.0667C7.46666 10 7.46666 9.93334 7.53332 9.86667C7.59999 9.8 7.66666 9.73334 7.73333 9.73334C7.86666 9.66667 7.99999 9.66667 8.13332 9.66667C8.19999 9.66667 8.19999 9.66667 8.26666 9.73334C8.33332 9.73334 8.33332 9.73334 8.39999 9.8C8.39999 9.8 8.46666 9.86667 8.53333 9.86667C8.59999 9.93334 8.66666 10 8.66666 10.0667C8.66666 10.1333 8.73333 10.2667 8.73333 10.3333C8.73333 10.5333 8.66666 10.6667 8.53333 10.8C8.33332 10.9333 8.19999 11 7.99999 11Z"
      fill="#4A4A4A"
    />
  </svg>
);
