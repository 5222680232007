import {Chat} from 'src/types';
import GetChatQuery from 'src/gql/query/GetChatQuery';
import ApolloClient from 'apollo-client';
// import ChatFragment from 'src/gql/ChatFragment';

export function tryReadChatQuery(client: ApolloClient<any>, id: string) {
  try {
    const chatQuery = client.readQuery({
      query: GetChatQuery,
      variables: {chatId: id},
    }) as {chat: Chat};
    return chatQuery;
  } catch {
    return null;
  }
}

export default async function (id: string, client: ApolloClient<any>): Promise<any> {
  const tryGetChatQuery = await tryReadChatQuery(client, id);
  let chatQuery;

  if (tryGetChatQuery) {
    chatQuery = tryGetChatQuery;
  } else {
    chatQuery = (
      await client.query({
        query: GetChatQuery,
        variables: {chatId: id},
      })
    ).data;
  }
  return chatQuery;
}
