import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import {MessageTemplate} from 'src/types';
import {FetchFavouriteTemplates} from 'src/gql/query/FetchTemplateList';

interface Props {
  chatId: string;
  searchMessageTemplateString: string;
  isSingleChat: boolean;
}

/**
 * View modal to fetch -> process/search -> return the data to the view for all templates and favourite template list
 * @param {string} chatId fetch the templates for this chatId.
 * @param searchMessageTemplateString
 * @return {Array<MessageTemplate> | null} return the list of templates
 */
const useFavouriteTemplateListViewModal = ({chatId, searchMessageTemplateString, isSingleChat}: Props) => {
  const {
    loading: favouriteTemplateListLoadingState,
    error: favouriteTemplateListError,
    data: favouriteTemplateListResult,
    refetch: favouriteTemplateListRefetch,
  } = useQuery(FetchFavouriteTemplates, {variables: {chatId}});

  return React.useMemo(() => {
    if (favouriteTemplateListLoadingState || favouriteTemplateListError || !favouriteTemplateListResult) return {};

    let favouriteFilteredTemplates: Array<MessageTemplate> =
      favouriteTemplateListResult?.chat.messageTemplates.favouriteTemplates;

    if (searchMessageTemplateString !== '') {
      favouriteFilteredTemplates = favouriteFilteredTemplates.filter(
        (template) =>
          template.formTitle.toLowerCase().includes(searchMessageTemplateString.toLowerCase()) ||
          template.description.toLowerCase().includes(searchMessageTemplateString.toLowerCase()),
      );
    }

    // Filter for showing template messages specific for group chats
    if (!isSingleChat) {
      favouriteFilteredTemplates = favouriteFilteredTemplates.filter(
        (template) => template?.metadata?.isEnabledForGroupChat,
      );
    }
    return {
      favouriteFilteredTemplates,
      favouriteTemplateListLoadingState,
      favouriteTemplateListError,
      favouriteTemplateListRefetch,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, searchMessageTemplateString, favouriteTemplateListResult, favouriteTemplateListRefetch]);
};

export default useFavouriteTemplateListViewModal;
