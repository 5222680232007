import gql from 'graphql-tag';

export default gql`
  query GetOrganizationInvites {
    me {
      id
      invites {
        inviteStatus
        id
        invitedBy {
          id
          firstname
          lastname
        }
        organization {
          id
          image
          name
          admins {
            id
            firstname
            lastname
          }
        }
      }
    }
  }
`;
