import gql from 'graphql-tag';
import ActiveEscalationsFragment from 'src/gql/fragment/ActiveEscalationFragment';

export default gql`
  query FetchActiveEscalationByID($activeEscalationId: Int!) {
    me {
      id
      activeEscalation(activeEscalationId: $activeEscalationId) {
        ...ActiveEscalationsFragment
      }
    }
  }
  ${ActiveEscalationsFragment}
`;
