import * as R from 'ramda';
import gql from 'graphql-tag';
import ChatFragment from 'src/gql/fragment/ChatListFragment';
import {Chat} from 'src/types';
import {userFragmentString} from 'src/gql/fragment/GeneralUserFragment';

export default gql`
  query ChatsQueryOptimized($priority: MessagePriority = regular, $continuationId: ID) {
    chats: chats(priority: $priority, continuationId: $continuationId) @connection(key: "chats", filter: ["priority"]) {
      continuationId
      chats {
        ...ChatFragment
        members {
          privilege
          id
          username
          firstname
          lastname
          role
          profilePic {
            url
          }
          status
          workStatus
          statusExpiryDate
          statusDescription
          workStatusProxy {
            id
            firstname
            lastname
            username
            status
          }
        }
      }
    }
  }

  fragment ChatFragment on Chat {
    id
    type
    title
    muted
    unreadPriorityMessages
    dateCreated
    image {
      id
      url
      __typename
    }
    status
    lastUnreadMessage {
      id
      __typename
    }
    lastMessage {
      id
      message
      type
      dateCreated
      priorityType
      sender {
        id
        username
        firstname

        lastname
        profilePic {
          url
        }
        role
        status
        workStatus

        __typename
      }
      readBy {
        user {
          id
          __typename
        }
        messageId
        timestamp
        __typename
      }
      __typename
    }
    __typename
  }
`;

export const chatsPath = ['chats', 'chats'];

export const getChats = (chatsQuery: any): Chat[] => {
  return R.pathOr([], chatsPath, chatsQuery) as Chat[];
};
