import gql from 'graphql-tag';

export const FetchScopesQuery = gql`
  query FetchScopes {
    me {
      scopes {
        organizationId
        siteId
        departmentId
        status
      }
    }
  }
`;
