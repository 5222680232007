import {ApolloClient} from 'apollo-client';
import {HttpLink} from 'apollo-link-http';
import {ApolloLink} from 'apollo-link';
import cache from 'src/apollo/inMemoryCache';
import getGqlEndpoint from 'src/apollo/getGqlEndpoint';
import resolvers from 'src/apollo/resolvers';
import {print} from 'graphql/language/printer';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import errorLink from 'src/apollo/errorLink';
import requestLink from 'src/apollo/requestLink';
import retryLink from 'src/apollo/retryLink';

// https://www.apollographql.com/docs/link/links/http/#context
const parseResponse = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    const context = operation.getContext();
    const {response, headers} = context;

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.networkRequest,
      params: {
        status_code: response.status,
        query: print(operation.query),
        duration: new Date().getTime() - operation.getContext().start,
        error_code: '',
        url_path: response.url,
        trace_id: headers['X-Request-ID'],
      },
    });

    return result;
  });
});

const afterwareLink = parseResponse.concat(
  new HttpLink({
    uri: getGqlEndpoint(),
  }),
);

// note: order matters - errorLink has to be set at beginning of the pipeline
// error -> retry -> request with auth -> afterware with analytics
// https://www.apollographql.com/docs/link/composition/

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, retryLink, requestLink, afterwareLink]),
  cache,
  resolvers,
});

export default client;
