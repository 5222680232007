import React, {CSSProperties} from 'react';

export default function LockIcon(props: {style?: CSSProperties}) {
  return (
    <svg
      style={props.style}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.3333 14.6665H6.66667C5.19391 14.6665 4 15.8604 4 17.3332V26.6665C4 28.1393 5.19391 29.3332 6.66667 29.3332H25.3333C26.8061 29.3332 28 28.1393 28 26.6665V17.3332C28 15.8604 26.8061 14.6665 25.3333 14.6665Z"
        stroke={props?.style?.color ?? '#222222'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33301 14.6665V9.33317C9.33301 7.56506 10.0354 5.86937 11.2856 4.61913C12.5359 3.36888 14.2316 2.6665 15.9997 2.6665C17.7678 2.6665 19.4635 3.36888 20.7137 4.61913C21.964 5.86937 22.6663 7.56506 22.6663 9.33317V14.6665"
        stroke={props?.style?.color ?? '#222222'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
