export const resetReduxStore = () => {
  return {
    type: 'RESET',
  };
};

export const updateTokenLastUpdatedAt = () => {
  return {
    type: 'AUTH_ALERT',
  };
};
