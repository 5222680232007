import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {AuthSSOVendors} from 'src/types';
import ApiHelper from 'src/api';
import {AuthContext} from '../../auth';
import {HYPERCARE_REGION, SSO_VENDOR} from '../../constants/storageKeys';

interface SSOCoordinatorProps {
  isLoggedIn: boolean;
}

const SSOCoordinator = ({isLoggedIn}: SSOCoordinatorProps) => {
  const {search} = useLocation();
  let urlSearchParams = new URLSearchParams(search);
  const region = urlSearchParams.get('region') || '';
  const provider = (urlSearchParams.get('provider') || '') as AuthSSOVendors;
  const connectionId = urlSearchParams.get('connection_id') || '';

  localStorage.setItem(HYPERCARE_REGION, region);
  localStorage.setItem(SSO_VENDOR, provider);

  const getSSOAuthorizationUrl = async (connectionId: string, provider: AuthSSOVendors) => {
    const result = await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(connectionId, provider);
    return result;
  };
  useEffect(() => {
    if (!isLoggedIn) {
      getSSOAuthorizationUrl(connectionId, provider);
    } else {
      window.routerHistory.push(`/messenger/home`);
    }
  }, []);

  return null;
};

export const SSORedirectCoordinator = () => {
  return <AuthContext.Consumer>{({isLoggedIn}) => <SSOCoordinator isLoggedIn={isLoggedIn} />}</AuthContext.Consumer>;
};
