import gql from 'graphql-tag';

export const AcceptShiftRequest = gql`
  mutation AcceptShiftRequest($note: String, $requestId: Int!, $shouldIgnoreWarnings: Boolean = false) {
    locating {
      shiftRequest(requestId: $requestId) {
        accept(note: $note, shouldIgnoreWarnings: $shouldIgnoreWarnings) {
          id
        }
      }
    }
  }
`;

export const DeclineShiftRequest = gql`
  mutation DeclineShiftRequest($note: String, $requestId: Int!) {
    locating {
      shiftRequest(requestId: $requestId) {
        decline(note: $note) {
          id
        }
      }
    }
  }
`;

export const WithdrawalShiftRequest = gql`
  mutation CancelShiftRequest($note: String, $requestId: Int!) {
    locating {
      shiftRequest(requestId: $requestId) {
        cancel(note: $note) {
          id
        }
      }
    }
  }
`;
