import * as React from 'react';
// import theme from 'src/styles/theme';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd">
      {/* <rect width="40" height="40" fill={props.color || theme.messageActionIconGrey} rx="20" /> */}
      <path
        fill={props.color || '#FFF'}
        fillRule="nonzero"
        d="M28.854 8.8H10.612c-1.148 0-2.079.93-2.079 2.079v18.242c0 1.148.931 2.079 2.08 2.079h18.241c1.149 0 2.08-.93 2.08-2.079V10.879c0-1.148-.931-2.079-2.08-2.079zm.68 20.321a.679.679 0 0 1-.68.679H10.612a.679.679 0 0 1-.679-.679v-4.872l7.312-6.559 5.834 7.298a.7.7 0 0 0 1.096-.875l-2.513-3.15 4.529-2.825 3.342 2.8v8.183zm0-10.006l-2.832-2.366a.7.7 0 0 0-.82-.056l-5.099 3.177-2.905-3.633a.7.7 0 0 0-.49-.258.7.7 0 0 0-.525.175l-6.93 6.216V10.879c0-.375.304-.679.68-.679h18.241c.375 0 .68.304.68.679v8.236zM13.87 10.987a2.67 2.67 0 1 0 0 5.34 2.67 2.67 0 0 0 0-5.34zm0 3.937a1.27 1.27 0 1 1 0-2.541 1.27 1.27 0 0 1 0 2.541z"
      />
    </g>
  </svg>
);
