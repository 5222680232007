import {GetOrganizationScope} from '../../../utils/organizationHelper/getOrganizationScope';
import {IsFeatureFlagEnabled} from '../../../utils/FeatureFlagManager';

export const useIsAuthorizedAuditLogs = () => {
  const data = GetOrganizationScope();
  const isAdmin = data?.me?.scopes?.some(
    (elem) => elem.status === 'admin' && elem.siteId === null && elem.departmentId === null,
  );

  const isAuditLogsEnabled = IsFeatureFlagEnabled('selfServeAuditTrail') && isAdmin;

  return {
    isAuditLogsEnabled,
  };
};
