import gql from 'graphql-tag';
import {ShiftFragmentWithRoleAndDepartment} from '../fragment/ShiftFragment';

export default gql`
  query FetchMyShifts($startDate: String!, $endDate: String!, $limit: Int!) {
    me {
      shifts(startDate: $startDate, endDate: $endDate, limit: $limit) {
        startDate
        endDate
        offset
        shifts {
          ...ShiftFragmentWithRoleAndDepartment
        }
      }
    }
  }
  ${ShiftFragmentWithRoleAndDepartment}
`;
