import * as React from 'react';

export default (props) => (
  <svg
    width={props.width || '12'}
    height={props.height || '8'}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.29289 0.957107C5.68342 0.566582 6.31658 0.566583 6.70711 0.957107L11.8125 6.0625C12.0541 6.30413 12.0541 6.69588 11.8125 6.9375C11.5709 7.17912 11.1791 7.17912 10.9375 6.9375L6 2L1.0625 6.9375C0.820876 7.17912 0.429125 7.17912 0.1875 6.9375C-0.0541246 6.69588 -0.0541246 6.30412 0.1875 6.0625L5.29289 0.957107Z"
      fill={props.color || '#00859A'}
    />
  </svg>
);
