import gql from 'graphql-tag';
import {OrganizationFragments, DepartmentFragments} from 'src/gql/fragment/OrganizationScopeFragment';

export const FetchSelfScopedDepartments = gql`
  query FetchSelfScopedOrganizations {
    me {
      id
      departments {
        ...DepartmentFragments
      }
    }
  }
  ${DepartmentFragments}
`;

export default gql`
  query GetSelfOrganizations {
    me {
      id
      email
      organizations {
        ...OrganizationFragments
      }
    }
  }
  ${OrganizationFragments}
`;
