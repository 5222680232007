import gql from 'graphql-tag';
import ScheduleFragment from 'src/gql/fragment/ScheduleFragment';

export const FETCH_DEPARTMENTS_AND_ROLES = gql`
  query FetchDepartmentsAndRoles(
    $siteId: Int!
    $startDate: String
    $endDate: String
    $month: ScheduleMonths!
    $year: Int!
  ) {
    locating {
      site(id: $siteId) {
        departments: allDepartments {
          id
          name
          schedule(startDate: $startDate, endDate: $endDate, month: $month, year: $year) {
            ...ScheduleFragment
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
  ${ScheduleFragment}
`;

export const FETCH_DEPARTMENTS_METADATA_FOR_SITE = gql`
  query FetchDepartmentsAndRoles($siteId: Int!) {
    locating {
      site(id: $siteId) {
        departments: allDepartments {
          id
          name
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
