/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {AddressType, User} from 'src/types';
import {getFullName} from 'src/utils/user';
import MemberIcon from 'src/svgs/MemberIcon';
import PagerIcon from 'src/svgs/PagerIcon';
import EmailIcon from 'src/svgs/EmailIcon';
import PhoneIcon from 'src/svgs/PhoneIcon';
import styled from '@emotion/styled';
import {ARCHIVED, MESSENGER} from 'src/constants/routerPathName';
import formatNationalNumber from 'src/utils/locating/formatNationalNumber';
import {UserAddressType} from 'src/types';
import PagerAlertModals from 'src/components/PagerAlertModals';
import {PagerType, PagerTypeEvent} from 'src/constants/user';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import convertAddressTypeToString from 'src/utils/convertAddressTypeToString';
import HypercareUserProfileViewModel from '../../../LocatingPage/viewModels/HypercareUserProfileViewModel';
import {ChatInformationAddressLoader} from '../../../../components/loaders/ChatInformationAddressLoader';
import {TextField} from '@mui/material';

const ChatinformationSingleWrapper = styled.div`
  padding: 2em 1em;
  color: ${(props) => props.theme.colors.greyishBrown};
`;

const OptionsWrapper = styled.div`
  padding: 1em;
`;

const OptionActionCopy = styled.div`
  margin-left: 1em;
  cursor: pointer;
  max-width: 85%;
  overflow-wrap: break-word;
  padding: 5px 0;
  a {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
  }
`;

const OptionHolder = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1em;
`;

const Notes = styled(TextField)`
  textarea {
    color: ${(props) => props.theme.colors.greyishBrown};
  }
`;

const MuteChatinformationSingleWrapper = styled.div`
  color: ${(props) => props.theme.colors.greyishBrown};
`;

export const OpenActions = styled.div`
  cursor: pointer;
  max-width: 85%;
  overflow-wrap: break-word;
  padding: 5px 0;
  a {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
  }
`;

interface Props {
  isSelf: boolean;
  user: User;
}

const AddressIcons = {
  email: <EmailIcon />,
  phoneNumber: <PhoneIcon width="24" height="24" />,
  pager: <PagerIcon />,
  alphanumeric_pager: <PagerIcon />,
  numeric_pager: <PagerIcon />,
};

const ChatinformationSingle = ({user, isSelf}: Props) => {
  const [showCallbackPageModal, setShowCallbackPageModal] = React.useState(false);
  const [selectedPagerAddress, setSelectedPagerAddress] = React.useState<string | null>(null);
  const routePath = window.location.href.includes(ARCHIVED) ? `/${ARCHIVED}/user` : `/${MESSENGER}/user`;
  const PagerAddressType = ['pager', 'alphanumeric_pager', 'numeric_pager'];
  const [pagerSelected, setPagerSelected] = React.useState<UserAddressType>('alphanumeric_pager');

  if (!user) return null;

  const viewModel = HypercareUserProfileViewModel();

  const {data, loading: isUserAddressesLoading} = viewModel.useGetProfileInformation(user.id);

  const handlePagerMixpanel = (pagerType: string, address: string) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.chatSpecificPagerButtonPressed,
      params: {
        address: address,
        type:
          pagerType === PagerType.ALPHANUMERIC_PAGER ? PagerTypeEvent.ALPHANUMERIC_PAGER : PagerTypeEvent.NUMERIC_PAGER,
      },
    });
  };

  const handlePhoneMixpanel = (address: string) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.phoneButtonPressed,
      params: {
        address: address,
      },
    });
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.chatSpecificPhoneButtonPressed,
      params: {
        address: address,
      },
    });
  };

  return (
    <ChatinformationSingleWrapper>
      <OptionsWrapper>
        <OptionHolder>
          <div>
            <MemberIcon />
          </div>
          <OptionActionCopy onClick={() => window.routerHistory.push(`${routePath}/${user.id}`)}>
            View {isSelf ? `Your` : `${getFullName(user)}'s`} Profile
          </OptionActionCopy>
        </OptionHolder>

        {isUserAddressesLoading && (
          <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <ChatInformationAddressLoader />
          </div>
        )}

        {data?.addresses &&
          data?.addresses.length !== 0 &&
          data.addresses.map((address, index) => (
            <OptionHolder key={`contact-${index}`}>
              <div>{AddressIcons[address.type!]}</div>
              <OptionActionCopy
                onClick={
                  PagerAddressType.includes(address.type!)
                    ? () => {
                        setSelectedPagerAddress(address.address);
                        setPagerSelected(address.type!);
                        setShowCallbackPageModal(true);
                        handlePagerMixpanel(address.type!, address.address);
                      }
                    : address.type === AddressType.PHONE_NUMBER
                    ? () => handlePhoneMixpanel(address.address)
                    : undefined
                }
              >
                <a
                  target={address.type === AddressType.EMAIL ? '_blank' : undefined}
                  title={`${convertAddressTypeToString(address.type)} this person`}
                  href={
                    address.type && PagerAddressType.includes(address.type)
                      ? undefined
                      : `${address.type === AddressType.EMAIL ? 'mailto' : 'callto'}:${address.address}`
                  }
                  style={{cursor: 'pointer'}}
                >
                  {address.type !== AddressType.PHONE_NUMBER ? address.address : formatNationalNumber(address.address)}
                </a>
              </OptionActionCopy>
            </OptionHolder>
          ))}
      </OptionsWrapper>

      <PagerAlertModals
        user={user}
        showCallbackPageModal={showCallbackPageModal}
        setShowCallbackPageModal={setShowCallbackPageModal}
        pagerSelected={pagerSelected}
        addressSelected={selectedPagerAddress!}
      />
    </ChatinformationSingleWrapper>
  );
};

export default React.memo(ChatinformationSingle);
