import gql from 'graphql-tag';
import {BasicProfileFieldFragment, ProfileFieldFragment} from '../fragment/ProfileFieldFragment';

export type UpdateSelfProfileCustomFieldMutationResult = {
  selfMutation: {
    profileField: {
      update: {
        __typename: string;
        id: string;
      };
    };
  };
};

export const UpdateSelfProfileCustomFieldMutation = gql`
  mutation UpdateProfileField($uniqueFieldId: ID!, $fieldInput: ProfileFieldInput!) {
    selfMutation {
      profileField(id: $uniqueFieldId) {
        update(fieldData: $fieldInput) {
          __typename
          ... on Node {
            id
          }
          ... on ProfilePhoneField {
            ...ProfileFieldFragment
            phoneNumber
            placeholder
          }
          ... on ProfileTextField {
            ...ProfileFieldFragment
            text
            placeholder
          }
          ... on ProfileDropdownField {
            options
            selectedOptions
          }
          ... on ProfileNumericField {
            value
          }
          ... on ProfileLinkField {
            url
          }
          ... on ProfileEmailField {
            email
          }
          ... on ProfileDateField {
            date
          }
        }
      }
    }
  }

  ${ProfileFieldFragment}
`;
