import gql from 'graphql-tag';

export type CreateSelfProfileCustomFieldsSectionMutationResult = {
  selfMutation: {
    addSection: {
      id: string;
      sectionId: string;
      label: string;
    };
  };
};

export const CreateSelfProfileCustomFieldsSectionMutation = gql`
  mutation CreateProfileSection($sectionDetails: CreateProfileSectionInput!) {
    selfMutation {
      addSection(details: $sectionDetails) {
        ... on ProfileSection {
          id
          sectionId
          label
        }
      }
    }
  }
`;
