import * as React from 'react';
import {adopt} from 'react-adopt';
import {Query} from 'react-apollo';
import GetChatsQuery from 'src/gql/query/GetChatsQuery';
import GetArchivedChatsQuery from 'src/gql/query/GetArchiveChatsQuery';
import {StatMessagePriority, UrgentMessagePriority} from 'src/constants/messageTypes';

const composedArchivedChat = {
  nonPriorityChatRP: (
    <Query query={GetArchivedChatsQuery} fetchPolicy="network-only">
      {() => null}
    </Query>
  ),
  statPriorityChatRP: (
    <Query query={GetArchivedChatsQuery} fetchPolicy="network-only" variables={{priority: StatMessagePriority}}>
      {() => null}
    </Query>
  ),
  urgentPriorityChatRP: (
    <Query query={GetArchivedChatsQuery} fetchPolicy="network-only" variables={{priority: UrgentMessagePriority}}>
      {() => null}
    </Query>
  ),
};

const composedChat = {
  nonPriorityChatRP: (
    <Query query={GetChatsQuery} notifyOnNetworkStatusChange={true}>
      {() => null}
    </Query>
  ),
  statPriorityChatRP: (
    <Query query={GetChatsQuery} notifyOnNetworkStatusChange={true} variables={{priority: StatMessagePriority}}>
      {() => null}
    </Query>
  ),
  urgentPriorityChatRP: (
    <Query query={GetChatsQuery} notifyOnNetworkStatusChange={true} variables={{priority: UrgentMessagePriority}}>
      {() => null}
    </Query>
  ),
};

export const ArchivedAllChatsQueryRP = adopt(composedArchivedChat);
export const AllChatsQueryRP = adopt(composedChat);
