export const openChatListSearchFieldKeys = 'g+shift+f';
export const openChatBodySearchFieldKeys = 'g+f';

export enum ActionEvents {
  START_CHAT = 'Start new chat',
  OPEN_NEW_CHAT = 'Open new chat',
  START_SUPPORT_CHAT = 'Start support chat',
  OPEN_CHANGE_STATUS_MODAL = 'Open status modal',
  OPEN_CHAT_LIST_SEARCH_FIELD = 'Open chat list search field',
  OPEN_CHAT_BODY_SEARCH_FIELD = 'Open chat body search field',
}

export enum NavigationEvents {
  GO_TO_SCHEDULING = 'Go to scheduling',
  GO_TO_MESSAGES = 'Go to messages',
  GO_TO_ONCALL = 'Go to on-call',
  GO_TO_CONTACTS = 'Go to contacts',
}
