import gql from 'graphql-tag';
import {PaginatedPendingInvitesFragment} from 'src/gql/v2/fragment/PaginatedFragment';
import {InvitedUser} from 'src/types';

export type FetchPaginatedInvitesQueryResponse = {
  organizationalUnitQuery: {
    organizationalUnit: {
      paginatedInvites: {
        continuationId: string;
        totalResultsCount: number;
        pageSize: number;
        invites: InvitedUser[];
      };
    };
  };
};

export const FETCH_PAGINATED_INVITES_QUERY = gql`
  query FetchPaginatedInvites(
    $organizationalUnit: OrganizationalUnitInput!
    $direction: PaginationDirection
    $continuationId: ID
    $limit: Int
  ) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on Error {
          __typename
          message
        }

        ... on Organization {
          paginatedInvites(continuationId: $continuationId, direction: $direction, limit: $limit) {
            ...PaginatedPendingInvitesFragment
          }
        }
      }
    }
  }
  ${PaginatedPendingInvitesFragment}
`;
