import gql from 'graphql-tag';

export const CREATE_USER_MUTATION = gql`
  mutation Signup(
    $challengeId: String!
    $username: String!
    $password: String!
    $userDetails: SignupUserDetails!
    $inviteCode: String
  ) {
    createUser(
      challengeId: $challengeId
      username: $username
      password: $password
      userDetails: $userDetails
      inviteCode: $inviteCode
    ) {
      ... on SelfUser {
        id
        username
        firstname: firstName
        lastname: lastName
        role
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;
