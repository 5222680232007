import * as React from 'react';

class ESCKeyListener extends React.PureComponent {
  public componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  public render() {
    return null;
  }

  private handleKeyDown = (evt) => {
    evt = evt || window.event;
    if (evt.key === 'Escape') {
      // ESC
      window.routerHistory.push(`/messenger/home`);
    }
  };
}

export default ESCKeyListener;
