export const SYSTEM = 'system';
export const TEXT = 'text';
export const IMAGE = 'image';
export const CONSULT = 'consult';
export const TEMPLATE = 'template';
export const ATTACHMENT = 'attachment';
export const StatMessagePriority = 'stat';
export const UrgentMessagePriority = 'urgent';
export const GeneralMessagePriority = 'regular';
export const SearchChatTypes = ['Users & Groups', 'Messages'];
export const SearchUserTypes = ['Users & Groups'];
export const DEBOUNCE_TIME = 250;
export const LOAD_MORE_LIMIT = 10;
export const LOAD_MORE_LIMIT_IN_CHAT = 30;
export const DIRECTION_BOTH = 'both';
export const DIRECTION_NEXT = 'next';
export const DIRECTION_PREVIOUS = 'previous';
export const INDIVIDUAL_MESSAGE = 'searchIndividualMessage';
export const SEARCH_MESSAGE = 'fetchSearchMessage';
export const NEXT_SEARCH = 'handleNextSearch';
export const SCROLL_UP = 'scrollUp';
export const SCROLL_DOWN = 'scrollDown';
export const DEFAULT_MESSAGE_LOAD_LIMIT = 25;
export const SPLIT_OLD_MESSAGE_LIMIT = 15;
