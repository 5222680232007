import gql from 'graphql-tag';

export default gql`
  mutation AcceptOrganizationInvite($inviteId: Int!) {
    self {
      acceptOrganizationInvite(inviteId: $inviteId) {
        id
      }
    }
  }
`;
