import * as React from 'react';

export default ({height = 40, width = 40}) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
      fill="#FFC705"
    />
    <path
      d="M17.3704 17.3685C17.3005 18.073 16.7079 18.6097 15.9999 18.6097C15.2919 18.6097 14.6993 18.073 14.6294 17.3684L13.972 10.739C13.8531 9.53988 14.795 8.5 16 8.5C17.205 8.5 18.1469 9.53992 18.0279 10.739L17.3704 17.3685Z"
      fill="#FF3E55"
    />
    <path
      d="M15.9997 23.5C17.0577 23.5 17.9154 22.6442 17.9154 21.5885C17.9154 20.5328 17.0577 19.677 15.9997 19.677C14.9416 19.677 14.0839 20.5328 14.0839 21.5885C14.0839 22.6442 14.9416 23.5 15.9997 23.5Z"
      fill="#FF3E55"
    />
  </svg>
);
