import gql from 'graphql-tag';

export type FetchOrganizationAllowInviteStatusResponse = {
  organizationalUnitQuery: {
    organizationalUnit: {
      allowInvites: boolean;
    };
  };
};

export const FETCH_ORG_ALLOW_INVITE_STATUS = gql`
  query GetOrgAllowInviteStatus($organizationalUnit: OrganizationalUnitInput!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on OrganizationalUnitNotFoundError {
          __typename
          message
        }
        ... on Organization {
          __typename
          id
          allowInvites
        }
      }
    }
  }
`;
