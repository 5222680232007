import gql from 'graphql-tag';
import {LocatingRoleFragment} from './RoleFragment';

export const scheduleFragmentString = `
  id
  name
  startDate
  endDate
  state
  roles {
    id
    name
  }
  createdAt
  updatedAt
`;

export default gql`
  fragment ScheduleFragment on Schedule {
    id
    name
    startDate
    endDate
    state
    roles {
      ...LocatingRoleFragment
    }
    createdAt
    updatedAt
  }
  ${LocatingRoleFragment}
`;
