import gql from 'graphql-tag';
import {ProfileSectionFragment, ProfileSectionFragmentType} from '../fragment/ProfileSectionFragment';

export type FetchOrgProfileFieldsSchemaQueryResult = {
  organizationalUnitQuery: {
    organizationalUnit: {
      profileTemplate: {
        template: ProfileSectionFragmentType[];
      };
    };
  };
};

export const FetchOrgProfileFieldsSchemaQuery = gql`
  query FetchProfileFieldsSchema($organizationalUnit: OrganizationalUnitInput!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        ... on Organization {
          profileTemplate {
            template {
              ...ProfileSectionFragment
            }
          }
        }
      }
    }
  }

  ${ProfileSectionFragment}
`;
