import {MessageAttachment} from './Message';

export enum TemplateElements {
  Textfield = 'textfield',
  Label = 'label',
  Dropdown = 'dropdown',
  Checkbox = 'checkbox',
  Radio = 'radio_button',
  MultilineTextfield = 'multiline_textfield',
  Toggle = 'toggle',
  Datepicker = 'date_picker',
  Attachments = 'attachments',
}

export enum TemplateMessageType {
  'request',
  'response',
}

export enum TemplateViews {
  RequestEditView = 'RequestEditView',
  RequestReview = 'RequestReview',
  DetailResponseEditView = 'DetailResponseEditView',
  DetailResponseReview = 'DetailResponseReview',
  ReviewResponse = 'ReviewResponse',
  RespondedResponse = 'RespondedResponse',
}

export enum MessageTemplateStatus {
  pending_response = 'pending_response',
  responded = 'responded',
  response_not_required = 'response_not_required',
}

export enum MessageTemplateResponseType {
  acknowledge = 'acknowledge',
  affirmation = 'affirmation',
  detail = 'detail',
}

interface MessageTemplateDisclaimer {
  title: string;
  body: string;
  __typename?: string;
}

export interface MessageTemplate {
  id: string;
  description: string;
  disclaimer: MessageTemplateDisclaimer | null;
  formTitle: string;
  request: MessageTemplateRequest;
  requestTitle: string;
  response: MessageTemplateResponse;
  metadata: MessageTemplateMetadata;
  status: MessageTemplateStatus;
  fields?: Array<MessageTemplateField>;
  __typename?: string;
}

interface MessageTemplateMetadata {
  isEnabledForGroupChat: boolean;
}

export interface MessageTemplateResponse {
  isAffirmative?: boolean;
  isAcknowledged?: boolean;
  children?: Array<MessageTemplateField>;
  type?: string;
  __typename?: string;
}

interface MessageTemplateRequest {
  children: Array<MessageTemplateField>;
  __typename?: string;
}

interface RegexValidation {
  value: String;
  error: String;
}

interface MessageTemplateFieldValidation {
  maxLength: Number;
  minLength: Number;
  regex: RegexValidation;
  maxDate: String;
  minDate: String;
  maxSelection: Number;
  minSelection: Number;
  maxAttachments: Number;
  supportedAttachments: [String];
}

export interface MessageTemplateField {
  id: string;
  type: string;
  isRequired: boolean;
  title: string;
  placeholder?: string;
  text?: string | null;
  options?: Array<string>;
  selectedOption?: number | null;
  state?: [boolean] | boolean;
  dateTime?: string | null;
  __typename?: string;
  attachments?: [MessageAttachment] | null;
  validation?: MessageTemplateFieldValidation | null;
}

export interface TemplateListResult {
  chat: {
    messageTemplates: {
      templates: Array<MessageTemplate>;
    };
  };
}
