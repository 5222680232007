import gql from 'graphql-tag';

type RoleUser = {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  profilePic: {
    url: string;
  };
  status: string;
};

type RoleShift = {
  id: string;
  startDate: string;
  endDate: string;
  user: RoleUser;
};

type DepartmentSchedule = {
  id: number;
  roles: {
    id: number;
    name: string;
    startTime: string;
    endTime: string;
    pagerNumber: string;
    currentShift: RoleShift;
    nextShift: RoleShift;
  };
};

export type FetchDepartmentScheduleResponse = {
  locating: {
    site: {
      department: {
        id: number;
        name: string;
        schedule: DepartmentSchedule;
      };
    };
  };
};

export type FetchDepartmentScheduleInput = {
  siteId: number;
  departmentId: number;
};

export const FETCH_SCHEDULE_FOR_DEPARTMENT = gql`
  query FetchScheduleForDepartment(
    $siteId: Int!
    $departmentId: Int!
    $startDate: String
    $endDate: String
    $month: ScheduleMonths
    $year: Int
  ) {
    locating {
      site(id: $siteId) {
        department(id: $departmentId) {
          id
          name
          schedule(startDate: $startDate, endDate: $endDate, month: $month, year: $year) {
            id
            roles {
              id
              name
              displayPosition
              startTime
              endTime
              pagerNumber
              currentShift {
                id
                user {
                  id
                  username
                  firstname
                  lastname
                  profilePic {
                    url
                  }
                  status
                }
                startDate
                endDate
              }
              nextShift {
                id
                user {
                  id
                  username
                  firstname
                  lastname
                  profilePic {
                    url
                  }
                  status
                  workStatus
                }
                startDate
                endDate
              }
            }
          }
        }
      }
    }
  }
`;
