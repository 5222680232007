import gql from 'graphql-tag';

export default gql`
  query fetchMoreMessages($chatId: ID!, $messagesContinuationId: Int, $limit: Int, $direction: PaginationDirection) {
    chat(chatId: $chatId) {
      ...OptimizedChatFragment
      messages(continuationId: $messagesContinuationId, limit: $limit, direction: $direction) {
        ...PaginatedMessagesFragment
        __typename
      }
      escalation {
        ...CustomChatsEscalationFragment
        __typename
      }
      __typename
    }
  }

  fragment OptimizedChatFragment on Chat {
    id
    type
    title
    muted
    unreadPriorityMessages
    dateCreated
    image {
      id
      url
      __typename
    }
    __typename
  }

  fragment GeneralUserFragment on GeneralUser {
    id
    username
    firstname
    lastname
    __typename
  }

  fragment ReadReceiptFragment on ReadReceipt {
    user {
      ...GeneralUserFragment
      __typename
    }
    messageId
    timestamp
    __typename
  }

  fragment FileAttachmentFragment on File {
    id
    url
    mimeType
    fileName
    __typename
  }

  fragment MessageTemplateFormFragment on MessageTemplateForm {
    ... on MessageTemplateForm {
      __typename
      id
      formTitle
      requestTitle
      description
      disclaimer {
        title
        body
        __typename
      }
      request {
        children {
          id
          type
          title
          isRequired
          validation {
            maxLength
            minLength
            maxSelection
            minSelection
            maxDate
            minDate
            maxAttachments
            regex {
              value
              error
              __typename
            }
            supportedAttachments
            __typename
          }
          ... on MessageTemplateTextField {
            text
            placeholder
            __typename
          }
          ... on MessageTemplateDatePicker {
            dateTime
            __typename
          }
          ... on MessageTemplateDropdown {
            options
            selectedOption
            __typename
          }
          ... on MessageTemplateAttachments {
            attachments {
              ...FileAttachmentFragment
              __typename
            }
            __typename
          }
          ... on MessageTemplateMultilineTextField {
            text
            __typename
          }
          ... on MessageTemplateCheckbox {
            options
            state
            __typename
          }
          ... on MessageTemplateRadioButton {
            options
            selectedOption
            __typename
          }
          ... on MessageTemplateToggle {
            toggleState: state
            __typename
          }
          __typename
        }
        __typename
      }
      response {
        type
        isAcknowledged
        isAffirmative
        children {
          id
          type
          title
          isRequired
          validation {
            maxLength
            minLength
            maxSelection
            minSelection
            maxDate
            minDate
            maxAttachments
            regex {
              value
              error
              __typename
            }
            supportedAttachments
            __typename
          }
          ... on MessageTemplateTextField {
            text
            placeholder
            __typename
          }
          ... on MessageTemplateDatePicker {
            dateTime
            __typename
          }
          ... on MessageTemplateDropdown {
            options
            selectedOption
            __typename
          }
          ... on MessageTemplateAttachments {
            attachments {
              ...FileAttachmentFragment
              __typename
            }
            __typename
          }
          ... on MessageTemplateMultilineTextField {
            text
            __typename
          }
          ... on MessageTemplateCheckbox {
            options
            state
            __typename
          }
          ... on MessageTemplateRadioButton {
            options
            selectedOption
            __typename
          }
          ... on MessageTemplateToggle {
            toggleState: state
            __typename
          }
          __typename
        }
        __typename
      }
      status
      metadata {
        isEnabledForGroupChat
        __typename
      }
    }
    __typename
  }

  fragment CustomChatsEscalationFragment on ActiveEscalation {
    id
    state
    type
    updatedAt
    endedAt
    endedBy {
      id
      firstname
      lastname
      __typename
    }
    escalationLadder {
      id
      name
      department {
        id
        name
        __typename
      }
      __typename
    }
    escalatedUsers {
      ...EscalatedUsersFragment
      __typename
    }
    activatedLevel {
      ...ActivatedLevelFragment
      __typename
    }
    message {
      id
      message
      type
      __typename
    }
    __typename
  }

  fragment EscalatedUsersFragment on EscalatedUser {
    id
    user {
      id
      firstname
      lastname
      __typename
    }
    escalatedAt
    acknowledged
    acknowledgedAt
    __typename
  }

  fragment ActivatedLevelFragment on ActivatedLevel {
    id
    name
    level
    currentAttempt
    nextAttemptAt
    escalationMembers {
      ...EscalationMembersFragment
      __typename
    }
    __typename
  }

  fragment EscalationMembersFragment on EscalationMember {
    id
    user {
      id
      firstname
      lastname
      role
      __typename
    }
    role {
      id
      name
      currentShift {
        id
        user {
          id
          firstname
          lastname
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }

  fragment PaginatedMessagesFragment on PaginatedMessages {
    continuationId
    messages {
      ...MessageFragment
      __typename
    }
    __typename
  }

  fragment MessageFragment on Message {
    id
    message
    image
    type
    dateCreated
    priority
    priorityType
    attachment {
      ...FileAttachmentFragment
      __typename
    }
    sender {
      ...GeneralUserFragment
      __typename
    }
    readBy {
      ...ReadReceiptFragment
      __typename
    }
    template {
      ...MessageTemplateFormFragment
      __typename
    }
    repliedTo {
      id
      message
      image
      type
      priority
      priorityType
      sender {
        id
        firstname
        lastname
        __typename
      }
      attachment {
        ...FileAttachmentFragment
        __typename
      }
      template {
        ...MessageTemplateFormFragment
        __typename
      }
      __typename
    }
    __typename
  }
`;
