import gql from 'graphql-tag';
import ShiftFragment from 'src/gql/fragment/ShiftFragment';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';

export const roleFragmentStringWithoutShifts = `
  id
  name
  auditId
  startTime
  endTime
  duration
  repeatRule
  pagerNumber
  required
  displayPosition
  createdAt
  updatedAt
  __typename
`;

export const LocatingRoleFragment = gql`
  fragment LocatingRoleFragment on Role {
    id
    name
    pagerNumber
    startTime
    displayPosition
    updatedAt
    endTime
    currentShift {
      id
      startDate
      endDate
      user {
        ...GeneralUserFragment
      }
    }
    nextShift {
      id
      endDate
      startDate
      user {
        ...GeneralUserFragment
      }
    }
    department {
      id
      name
    }
  }
  ${GeneralUserFragment}
`;

export default gql`
  fragment RoleFragment on Role {
    ${roleFragmentStringWithoutShifts}
    currentShift {
      ...ShiftFragment
    }
    nextShift {
      ...ShiftFragment
    }
    shifts(startDate: $startDate, endDate: $endDate) {
      ...ShiftFragment
    }
  }
  ${ShiftFragment}
`;
