import * as React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="53" height="54" {...props}>
    <g fill="none" fillRule="evenodd" opacity="0.7">
      <path
        stroke="#979797"
        strokeDasharray="8"
        d="M26.5 52.97c14.083 0 25.5-11.634 25.5-25.985C52 12.634 40.583 1 26.5 1S1 12.634 1 26.985C1 41.335 12.417 52.97 26.5 52.97z"
      />
      <path fill="#3F3F3F" d="M27.57 26.68H34v2.155h-6.43v6.649H25.4v-6.65H19v-2.153h6.4V20h2.17z" />
    </g>
  </svg>
);
