import * as React from 'react';
import {PageBody, PageContainer} from 'src/styles/styled-components/CommonPage';
import Helmet from 'react-helmet';
import {AuthContext} from 'src/auth/AuthProvider';
import Header from 'src/pages/MessengerPage/messenger/PageHeader';
import {AuthPayload, AuthRegion} from 'src/types';
import LocatingLayout from 'src/pages/LocatingPage/locating/LocatingLayout';
import store from 'src/redux';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import PageBanner from 'src/components/PageBanner';
interface Props {
  logout: () => void;
  authInfo: AuthPayload;
  authRegion: AuthRegion;
}

class LocatingPage extends React.PureComponent<Props> {
  public componentDidMount() {
    const currentOrganization = store.getState().organization;
    if (!currentOrganization.organizationId) window.routerHistory.push(`/messenger/`);

    AnalyticsManager.recordPageVisited(PAGE_VIEWS.locatingView);
  }

  public render() {
    const {authInfo, authRegion, logout} = this.props;
    const user = authInfo?.user;
    return (
      <PageContainer>
        <Helmet>
          <title>On-call - Hypercare</title>
        </Helmet>

        <Header user={user} logout={logout} authRegion={authRegion} />

        <PageBanner />

        <PageBody>
          <LocatingLayout />
        </PageBody>
      </PageContainer>
    );
  }
}

export default () => (
  <AuthContext.Consumer>
    {({logout, authInfo, authRegion}) => <LocatingPage authInfo={authInfo} logout={logout} authRegion={authRegion} />}
  </AuthContext.Consumer>
);
