import gql from 'graphql-tag';
import MessageTemplateFormFragment from 'src/gql/fragment/MessageTemplateFormFragment';

export default gql`
  mutation FavouriteTemplateMessage($chatId: ID!, $templateId: ID!) {
    chat(chatId: $chatId) {
      messageTemplates {
        template(id: $templateId) {
          favourite {
            ...MessageTemplateFormFragment
          }
        }
      }
    }
  }
  ${MessageTemplateFormFragment}
`;

export const UnfavouriteTemplateMessage = gql`
  mutation FavouriteTemplateMessage($chatId: ID!, $templateId: ID!) {
    chat(chatId: $chatId) {
      messageTemplates {
        template(id: $templateId) {
          unfavourite {
            ...MessageTemplateFormFragment
          }
        }
      }
    }
  }
  ${MessageTemplateFormFragment}
`;
