import gql from 'graphql-tag';
import {userFragmentString} from 'src/gql/fragment/GeneralUserFragment';
import MessageFragment from 'src/gql/fragment/MessageFragment';
import ChatFragment from 'src/gql/fragment/ChatFragment';

export default gql`
  mutation CreateChat($members: [ID!]!) {
    createChat(members: $members) {
      ...ChatFragment
      members {
        privilege
        ${userFragmentString}
      }
      messages {
        continuationId
        messages {
          ...MessageFragment
        }
      }
    }
  }
  ${ChatFragment}
  ${MessageFragment}
`;
