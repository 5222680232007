import * as R from 'ramda';
import {ChatWsPayload} from 'src/types';
import {ApolloClient} from 'apollo-client';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';

export default function (chatPayload: ChatWsPayload, client: ApolloClient<any>) {
  return R.pipe(
    R.merge({
      __typename: 'Chat',
      title: null,
      unreadPriorityMessages: [],
      lastMessage: null,
      readReceipts: [],
    }),
    R.evolve({
      members: R.map((user) =>
        client.readFragment({
          id: `User:${user.id}`,
          fragment: GeneralUserFragment,
        }),
      ),
    }),
  )(chatPayload);
}
