import * as React from 'react';

export default (props) => (
  <svg width="40" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6667 15.3333C26.6667 8.09305 18.7549 3.07979 12.2011 6.39013L12.1961 6.39268L12.1961 6.39267C7.37145 8.80394 5.27525 14.9519 7.72346 19.7989C7.88374 20.1162 7.91066 20.4844 7.79824 20.8216L6.10818 25.8918L11.1784 24.2018C11.5156 24.0893 11.8838 24.1163 12.2011 24.2765C18.6766 27.5473 26.6852 22.4546 26.6667 15.3368L26.6667 15.3333ZM29.3333 15.3318C29.3322 6.15171 19.3684 -0.2153 11.0014 4.0086C5.04572 6.98686 2.36806 14.3942 5.09864 20.4877L2.73508 27.5784C2.57538 28.0575 2.70008 28.5857 3.05719 28.9428C3.4143 29.2999 3.94252 29.4246 4.42163 29.2649L11.512 26.9015C19.695 30.5764 29.355 24.2128 29.3333 15.3318Z"
      fill="#9B9B9B"
    />
  </svg>
);
