import * as React from 'react';
import Helmet from 'react-helmet';
import DebugList from './DebugList';
import {AuthContext} from 'src/auth/AuthProvider';
import {AuthPayload, AuthRegion} from 'src/types';
import PageBanner from 'src/components/PageBanner';
import Header from 'src/pages/MessengerPage/messenger/PageHeader';
import {PageBody, PageContainer} from 'src/styles/styled-components/CommonPage';

interface Props {
  logout: () => void;
  isLoggedIn: boolean;
  authInfo: AuthPayload;
  authRegion: AuthRegion;
}

const DebugPage: React.FC<Props> = ({isLoggedIn, authInfo, authRegion, logout}) => {
  const user = authInfo?.user;

  return (
    <PageContainer>
      <Helmet>
        <title>Debug - Hypercare</title>
      </Helmet>
      {isLoggedIn && (
        <>
          <Header user={user} logout={logout} authRegion={authRegion} />
          <PageBanner />
        </>
      )}
      <PageBody>
        <DebugList isLoggedIn={isLoggedIn} />
      </PageBody>
    </PageContainer>
  );
};

export default () => (
  <AuthContext.Consumer>
    {({logout, isLoggedIn, authRegion, authInfo}) => (
      <DebugPage isLoggedIn={isLoggedIn} authInfo={authInfo} authRegion={authRegion} logout={logout} />
    )}
  </AuthContext.Consumer>
);
