import gql from 'graphql-tag';

export default gql`
  mutation ArchiveChat($chatId: ID!) {
    chat(chatId: $chatId) {
      archive {
        id
      }
    }
  }
`;
