import {removeStopwords} from 'stopword';
import {STOP_WORDS} from 'src/utils/messengerHelper/stopWords';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';

export const formatSearchPayload = (searchText) => {
  const noSpecialCharacters = searchText.replace(/[^\w ]/g, '');
  const uniqueInputWords = [...new Set(noSpecialCharacters.trim().split(' '))]; // remove duplicate string from the searched array.
  const stopWords = removeStopwords(uniqueInputWords, STOP_WORDS);
  const searchChatText = removeStopwords(stopWords).join(' ');

  return searchChatText;
};

export const handleSearchMixpanel = (searchChatText: string) => {
  AnalyticsManager.applyAnalytics({
    eventName: EVENTS.searchQuerySubmitted,
    params: {
      word_count: searchChatText.split(' ').length,
      character_count: searchChatText.length,
    },
  });
};
