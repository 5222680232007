import client from 'src/apollo';
import {Chat, Message} from 'src/types';
import * as R from 'ramda';
import GetChatQuery from 'src/gql/query/GetChatQuery';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';
import {MESSAGES_READ} from 'src/gql/v2/mutation/MessagesReadMutation';
import ChatViewModel from 'src/pages/MessengerPage/messenger/chat-list-layout/repository/ChatViewModel';

export default async function (chatId: string, messageIds: string[], userId: string) {
  const {handleMessageDelivered} = ChatViewModel();
  try {
    await handleMessageDelivered(messageIds);
    await client.mutate({
      mutation: MESSAGES_READ,
      errorPolicy: 'ignore', // replication lag
      variables: {
        chatId,
        messageIds,
      },
      update: (store) => {
        // update message other than self to store since it only self message has subscriber when mount
        const chatQuery = store.readQuery({
          query: GetChatQuery,
          variables: {chatId},
        }) as {chat: Chat};

        const user = client.readFragment({
          id: `User:${userId}`,
          fragment: GeneralUserFragment,
        });

        const newMessages: Message[] = chatQuery.chat.messages.messages.map((message) => {
          if (messageIds.includes(message.id) && !message.readBy.find((receipts) => receipts.user.id === userId)) {
            message.readBy.push({
              user,
              messageId: message.id,
              timestamp: new Date().toISOString(),
              __typename: 'ReadReceipt',
            });
          }
          return message;
        });

        const unreadPriorityMessages = chatQuery.chat.unreadPriorityMessages.filter(
          (id) => !messageIds.includes(id.toString()),
        );
        const newQuery = R.evolve({
          chat: {
            lastUnreadMessage: () => null, // currently only set from from socket event
            unreadPriorityMessages: () => unreadPriorityMessages,
            messages: {
              messages: () => newMessages,
            },
          },
        })(chatQuery);

        store.writeQuery({
          query: GetChatQuery,
          variables: {chatId},
          data: newQuery,
        });
      },
    });
    return Promise.resolve('done');
  } catch (e) {
    console.error(e);
    return Promise.resolve('error ignored');
  }
}
