export const SESSION_TIMEOUT_LOGINPAGE_MODAL = 'sessionTimeoutLoginPageModal';
export const ORGANIZATION_TIMEOUT_VALUES = 'organization_timeout_values';

export const US_ORGANIZATION_TIMEOUT_VALUES = 'organization_timeout_values_us';
export const EU_ORGANIZATION_TIMEOUT_VALUES = 'organization_timeout_values_eu';

export const CURRENT_ORGANIZATION_TIMEOUT_VALUES = 'current_organization_timeout_values';
export const IDLE_TIMEOUT = 'idle_timeout';
export const FORCED_TIMEOUT = 'forced_timeout';

export const USER_IS_IDLE = 'user_is_idle';
export const USER_IS_IDLE_NOTIFICATION_TEXT =
  'Session expired. You have been signed out for security purposes because you were idle';

export const MODAL_POPUP = 'modal_popup';
export const MODAL_NOTIFICATION_POPUP_TEXT =
  "Session expiring. You will be logged out in 5 minutes due to inactivity. To continue your session, click 'Continue session' below.";

export const defaultTime = {
  FORCED_TIMEOUT: 28800000,
  IDLE_TIMEOUT: 3600000,
};

export const LAST_ACTIVE_TIME = 'lastActiveTime';
