import gql from 'graphql-tag';
import ActiveEscalationsFragment from 'src/gql/fragment/ActiveEscalationFragment';

export const CheckActiveEscalationExists = gql`
  query CheckUserActiveEscalations {
    me {
      id
      activeEscalations {
        id
        state
      }
    }
  }
`;

export default gql`
  query FetchUserActiveEscalations {
    me {
      id
      activeEscalations {
        ...ActiveEscalationsFragment
      }
    }
  }
  ${ActiveEscalationsFragment}
`;
