import {Operation, NextLink, fromPromise} from 'apollo-link';
import AuthHelper from 'src/auth/authHelper';
import store from 'src/redux';
import {resetReduxStore} from 'src/redux/rootActions';
import {print} from 'graphql/language/printer';
import {
  INVALID_ORG_SCOPE,
  NO_RECORD,
  INVALID_REFRESH_TOKEN,
  UNAUTHORIZED_REQUEST,
  EXPIRED_ACCESS_TOKEN_ERROR_MESSAGE,
} from 'src/constants/networkError';
import {USER_AUTH_EXPIRATION_NAMES} from 'src/constants/user';
import {onError} from 'apollo-link-error';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {IS_EXPIRED} from 'src/constants/storageKeys';

// TODO: request abortion with uuid
// https://github.com/apollographql/apollo-feature-requests/issues/40

let isRefreshing = false;
let pendingRequests: Function[] = [];

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

const addPendingRequest = (pendingRequest: Function) => {
  pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
  console.log(`resolving pending requests, ${pendingRequests.length} in total...`);
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

// TODO: align with authProvider logout, currently reload to make sure reset
const handleUserExpired = () => {
  AuthHelper.logout();
  // redux
  // @ts-ignore
  store.dispatch(resetReduxStore());
  // if (window.location.href.includes('changepassword')) return
  sessionStorage.setItem(IS_EXPIRED, 'expired by network error');
  window.location.reload();
};

// TODO: create error model that match backend
// https://github.com/Hypercare/hypercare-api/blob/fd469dbc4be2ec36c5fbbe3e8a456eb27fbbf43f/App/src/utils/errors.js
const handleNetworkError = (networkError, operation: Operation, forward: NextLink) => {
  const castedNetworkError = networkError as any;
  const context = operation.getContext();
  const {response, headers} = context;

  if (response) {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.networkRequest,
      params: {
        status_code: response.status ? response.status : '',
        query: print(operation.query),
        duration: new Date().getTime() - operation.getContext().start,
        error_code:
          castedNetworkError.result && castedNetworkError.result.errors[0] && castedNetworkError.result.errors[0].code
            ? castedNetworkError.result.errors[0].code
            : '',
        url_path: response.url,
        trace_id: headers['X-Request-ID'],
      },
    });
  }

  //if token is expired, log the user out
  if (castedNetworkError?.result?.code === UNAUTHORIZED_REQUEST) {
    console.error(EXPIRED_ACCESS_TOKEN_ERROR_MESSAGE, networkError);
    handleUserExpired();
  }

  // this should only happen when user manually editing the localStorage scope
  if (
    castedNetworkError.result &&
    castedNetworkError.result.errors &&
    castedNetworkError.result.errors[0] &&
    castedNetworkError.result.errors[0].name === INVALID_ORG_SCOPE
  ) {
    console.error('Organization scope was invalid', networkError);
    handleUserExpired();
  }

  // e.g. when force logout from admin
  if (
    castedNetworkError.result &&
    castedNetworkError.result.inner &&
    castedNetworkError.result.inner.name === NO_RECORD
  ) {
    console.error('User record not found', networkError);
    handleUserExpired();
  }
};

// retry docs:
// https://stackoverflow.com/questions/50965347/how-to-execute-an-async-fetch-request-and-then-retry-last-failed-request/51321068#51321068
// https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8

// original discussion:
// https://github.com/apollographql/apollo-link/issues/646

const errorLink = onError(({graphQLErrors, networkError, operation, forward}) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      console.error(`ran into graphql errors: ${err.name}`);
      console.error(err);
      if (USER_AUTH_EXPIRATION_NAMES.includes(err.name)) {
        if (!isRefreshing) {
          setIsRefreshing(true);

          return fromPromise(
            AuthHelper.refreshAccessToken().catch((e) => {
              if (e.response.data.code === INVALID_REFRESH_TOKEN) window.location.reload();
              resolvePendingRequests();
              setIsRefreshing(false);

              // if refresh accessToken failed, logout without retry for now
              handleUserExpired();

              return forward(operation);
            }),
          ).flatMap(() => {
            resolvePendingRequests();
            setIsRefreshing(false);

            return forward(operation);
          });
        } else {
          return fromPromise(
            new Promise<void>((resolve) => {
              addPendingRequest(() => resolve());
            }),
          ).flatMap(() => {
            return forward(operation);
          });
        }
      }
    }
  }
  if (networkError) {
    handleNetworkError(networkError, operation, forward);
  }
});

export default errorLink;
