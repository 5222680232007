import * as R from 'ramda';
import React from 'react';
import {Query, QueryResult} from 'react-apollo';
import GetColleaguesQuery from 'src/gql/query/GetColleaguesQuery';
import AddAlternateContactSelect from 'src/pages/MessengerPage/messenger/change-status/ChangeStatusModalUserDropdown';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import {UserSelectOptionType} from 'src/types';
import {SmallLoader} from 'src/components/LoadingDiv';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';

const ChangeStatusModalUserDropdownContainer = ({onClickColleague, workStatusProxyUser}) => {
  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.selectContactsList);
  }, []);

  return (
    <Query query={GetColleaguesQuery}>
      {({loading, error, data}: QueryResult) => {
        if (error || loading) return <SmallLoader />;

        const colleagues = R.pathOr([], ['colleagues'], data);
        const parsedAuthInfo = getParsedAuthInfo();
        const selfID = parsedAuthInfo && parsedAuthInfo.user.id;

        const suggestions = colleagues
          .filter((colleague) => colleague.id !== selfID)
          .map((colleague) => {
            return {
              value: colleague.id,
              label: colleague.firstname + ' ' + colleague.lastname,
              username: colleague.username,
              role: colleague.role ? colleague.role : '',
            } as UserSelectOptionType;
          });

        // initially proxyUser is User type fetched from userFragment, conver to UserSelectOptionType for dropdown
        let proxyUserData;
        if (workStatusProxyUser && workStatusProxyUser.id) {
          const proxyUserFragment = colleagues.find((colleague) => colleague.id === workStatusProxyUser.id);
          // BUG: proxy was from other organization will still showing
          if (proxyUserFragment) {
            proxyUserData = {
              value: proxyUserFragment.id,
              label: proxyUserFragment.firstname + ' ' + proxyUserFragment.lastname,
              username: proxyUserFragment.username,
              role: proxyUserFragment.role ? proxyUserFragment.role : '',
            } as UserSelectOptionType;
          }
        }

        return (
          <AddAlternateContactSelect
            suggestions={suggestions}
            proxyUserProps={proxyUserData}
            onClickColleague={onClickColleague}
          />
        );
      }}
    </Query>
  );
};

export default React.memo(ChangeStatusModalUserDropdownContainer);
