import gql from 'graphql-tag';

export default gql`
  mutation UnarchiveChat($chatId: ID!) {
    chat(chatId: $chatId) {
      unarchive {
        id
      }
    }
  }
`;
