import store from 'src/redux';
import {actions as schedulingActions} from 'src/redux/actions/scheduling';

export default function tryDispatchUserIdForColorAssignement(userIdSet: Set<string>) {
  const currentAssignment = store.getState().scheduling.userToHexColorCode;
  const containsUnAssignedUserId = Boolean(
    Array.from(userIdSet).find((userId) => !currentAssignment.hasOwnProperty(userId)),
  );
  if (containsUnAssignedUserId) store.dispatch(schedulingActions.setUserColor(userIdSet));
  return;
}
