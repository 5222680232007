import * as React from 'react';

export default (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="10" cy="10" r="10" fill="#F2F2F2" />
    <g clip-path="url(#clip0_88_4791)">
      <path
        d="M14.2438 7.06875L11.1813 4.00625C11.0938 3.91875 11.0062 3.875 10.875 3.875H6.5C6.01875 3.875 5.625 4.26875 5.625 4.75V15.25C5.625 15.7312 6.01875 16.125 6.5 16.125H13.5C13.9812 16.125 14.375 15.7312 14.375 15.25V7.375C14.375 7.24375 14.3313 7.15625 14.2438 7.06875ZM10.875 4.925L13.325 7.375H10.875V4.925ZM13.5 15.25H6.5V4.75H10V7.375C10 7.85625 10.3938 8.25 10.875 8.25H13.5V15.25Z"
        fill="#4A4A4A"
      />
      <path d="M12.625 12.625H7.375V13.5H12.625V12.625Z" fill="#4A4A4A" />
      <path d="M12.625 10H7.375V10.875H12.625V10Z" fill="#4A4A4A" />
    </g>
    <defs>
      <clipPath id="clip0_88_4791">
        <rect width="14" height="14" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);
