import gql from 'graphql-tag';
import ChatFragment from 'src/gql/fragment/ChatFragment';

export default gql`
  mutation EditChatTitle($chatId: ID!, $title: String!) {
    chat(chatId: $chatId) {
      editTitle(title: $title) {
        ...ChatFragment
      }
    }
  }
  ${ChatFragment}
`;
