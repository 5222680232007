import {useLazyQuery} from 'react-apollo';
import GetSelfOrganizations from 'src/gql/query/GetUserOrganizations';

export const useFetchUsersOrganizations = () => {
  const [fetchUsersOrganizations, {data, loading}] = useLazyQuery(GetSelfOrganizations, {
    fetchPolicy: 'cache-first',
    variables: {
      filter: false,
    },
    onError: (err) => {
      console.error(err);
    },
  });
  return {fetchUsersOrganizations, organizationListData: data, loading};
};
