import gql from 'graphql-tag';

const AttachmentFragment = gql`
  fragment AttachmentFragment on File {
    id
    url
    fileName
    mimeType
  }
`;

const messageTemplateFieldFragmentString = `
  id
  type
  title
  isRequired
  validation {
    maxLength
    minLength
    maxSelection
    minSelection
    maxDate
    minDate
    maxAttachments
    regex {
        value
        error
    }
    supportedAttachments
  }
  ... on MessageTemplateTextField {
    text
    placeholder
  }
  ... on MessageTemplateDatePicker {
    dateTime
  }
  ... on MessageTemplateDropdown {
    options
    selectedOption
  }
  ... on MessageTemplateAttachments {
    attachments {
      ...AttachmentFragment
    }
  }
  ... on MessageTemplateMultilineTextField {
    text
  }
  ... on MessageTemplateCheckbox {
    options
    state
  }
  ... on MessageTemplateRadioButton {
    options
    selectedOption
  }
  ... on MessageTemplateToggle {
    toggleState: state
  }
`;

export default gql`
  fragment MessageTemplateFormFragment on MessageTemplateForm {
    ... on MessageTemplateForm {
      __typename
      id
      formTitle
      requestTitle
      description
      disclaimer {
        title
        body
      }
      request {
        children {
          ${messageTemplateFieldFragmentString}
        }
      }
      response {
        type
        isAcknowledged
        isAffirmative
        children {
          ${messageTemplateFieldFragmentString}
        }
      }
      status
      metadata {
        isEnabledForGroupChat
      }
    }
  }
  ${AttachmentFragment}
`;
