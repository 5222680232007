import React from 'react';
import * as R from 'ramda';
import client from 'src/apollo';
import {toast} from 'react-toastify';
import AcceptOrganizationInviteMutation from 'src/gql/mutation/AcceptOrganizationInvite';
import DeclineOrganizationInviteMutation from 'src/gql/mutation/DeclineOrganizationInvite';
import {InvitesOrganization, User, UserOrganizationSwitcherPayload} from 'src/types';
import trySwitchOrganization from 'src/utils/organizationHelper/trySwitchOrganization';
import GetOrganizationInvitesForUser from 'src/gql/query/GetOrganizationInvitesForUser';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';

interface Props {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const useHandleInvitationAction = ({setLoading}: Props) => {
  const handleUpdateLocalStore = (store: any, inviteId: number) => {
    const selfQuery = store.readQuery({
      query: GetOrganizationInvitesForUser,
    });
    const newSelfQuery = R.evolve(
      {
        me: {
          invites: R.filter((invite) => invite.id !== inviteId),
        },
      },
      selfQuery,
    );

    if (newSelfQuery.me.invites.length > 0) setLoading(false);

    store.writeQuery({
      query: GetOrganizationInvitesForUser,
      data: newSelfQuery,
    });
  };

  async function handleAccept(organization: InvitesOrganization, inviteId: number, invitedBy: User) {
    setLoading(true);
    try {
      await client.mutate({
        mutation: AcceptOrganizationInviteMutation,
        variables: {
          inviteId,
        },
        update: (store) => {
          handleUpdateLocalStore(store, inviteId);
        },
      });

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.inviteAccepted,
        params: {
          invite_id: inviteId,
          invited_by_user_id: invitedBy.id,
          organization_id: organization.id,
        },
      });

      const newOrganizationOption = {
        name: organization.name,
        image: organization.image,
        type: 'Organization',
        organizationId: organization.id,
      } as UserOrganizationSwitcherPayload;

      trySwitchOrganization(newOrganizationOption);
    } catch {
      toast.error('Failed to accept invitation, please check your internet connection and try again');
      setLoading(false);
    }
  }

  async function handleDecline(inviteId: number) {
    if (window.confirm('Are you sure you want to decline this invitation?')) {
      setLoading(true);
      try {
        await client.mutate({
          mutation: DeclineOrganizationInviteMutation,
          variables: {
            inviteId,
          },
          update: (store) => {
            handleUpdateLocalStore(store, inviteId);
          },
        });
      } catch {
        toast.error('Failed to decline invitation, please check your internet connection and try again');
        setLoading(false);
      }
    }
  }

  return {
    handleAccept,
    handleDecline,
  };
};

export default useHandleInvitationAction;
