import * as React from 'react';

export default (props: {style: React.CSSProperties}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.9137 4H3.06249C1.95792 4 1.0625 4.89542 1.0625 5.99999V18.4999C1.0625 19.6045 1.95792 20.4999 3.06249 20.4999H20.9137C22.0183 20.4999 22.9137 19.6045 22.9137 18.4999V5.99999C22.9137 4.89542 22.0183 4 20.9137 4Z"
      stroke={props.style.color || '#FF3E55'}
      stroke-width="1.5"
    />
    <path
      d="M18.162 7.06363H5.70066C5.09796 7.06363 4.60938 7.43634 4.60938 7.8961V11.1125C4.60938 11.5722 5.09796 11.9449 5.70066 11.9449H18.162C18.7647 11.9449 19.2533 11.5722 19.2533 11.1125V7.8961C19.2533 7.43634 18.7647 7.06363 18.162 7.06363Z"
      stroke={props.style.color || '#FF3E55'}
      stroke-width="1.5"
    />
    <path
      d="M11.4032 16.8262H5.13747C4.84581 16.8262 4.60938 17.0994 4.60938 17.4364C4.60938 17.7734 4.84581 18.0466 5.13747 18.0466H11.4032C11.6949 18.0466 11.9313 17.7734 11.9313 17.4364C11.9313 17.0994 11.6949 16.8262 11.4032 16.8262Z"
      stroke={props.style.color || '#FF3E55'}
    />
    <path
      d="M15.5547 18.5679C16.107 18.5679 16.5547 18.1202 16.5547 17.5679C16.5547 17.0156 16.107 16.5679 15.5547 16.5679C15.0024 16.5679 14.5547 17.0156 14.5547 17.5679C14.5547 18.1202 15.0024 18.5679 15.5547 18.5679Z"
      stroke={props.style.color || '#FF3E55'}
    />
    <path
      d="M18.7969 18.5679C19.3491 18.5679 19.7969 18.1202 19.7969 17.5679C19.7969 17.0156 19.3491 16.5679 18.7969 16.5679C18.2446 16.5679 17.7969 17.0156 17.7969 17.5679C17.7969 18.1202 18.2446 18.5679 18.7969 18.5679Z"
      stroke={props.style.color || '#FF3E55'}
    />
  </svg>
);
