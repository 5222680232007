class JoinOrgViewModel {
  nextButtonPressed;
  constructor(nextButtonPressed) {
    this.nextButtonPressed = nextButtonPressed;
  }

  handleNextButtonPressed = (values) => {
    this.nextButtonPressed(values);
  };
}

export default JoinOrgViewModel;
