import * as React from 'react';

export default ({styles = {}}: {styles?: React.CSSProperties}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_879_1215)">
      <g clip-path="url(#clip1_879_1215)">
        <path
          d="M8.70711 10.2929C8.31658 10.6834 7.68342 10.6834 7.29289 10.2929L3.35 6.34999C3.1567 6.15669 3.1567 5.84329 3.35 5.64999C3.5433 5.45669 3.8567 5.45669 4.05 5.64999L8 9.59999L11.95 5.64999C12.1433 5.45669 12.4567 5.45669 12.65 5.64999C12.8433 5.84329 12.8433 6.15669 12.65 6.34999L8.70711 10.2929Z"
          fill="#4A4A4A"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_879_1215">
        <rect width="16" height="16" fill="white" />
      </clipPath>
      <clipPath id="clip1_879_1215">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
