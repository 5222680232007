import {toast} from 'react-toastify';
import client from 'src/apollo';
import MuteChatMutation from 'src/gql/mutation/MuteChatMutation';
import UnMuteChatMutation from 'src/gql/mutation/UnMuteChatMutation';
import getChatFromId from 'src/utils/messengerHelper/getChatFromId';
import GetChatQuery from 'src/gql/query/GetChatQuery';

const muteChat = async (chatId: string) => {
  try {
    await client.mutate({
      mutation: MuteChatMutation,
      variables: {
        chatId,
        muteTypes: ['messages', 'system'],
      },
    });

    const chatQuery = await getChatFromId(chatId, client);
    if (chatQuery) {
      const newChatQuery = {
        chat: {
          ...chatQuery.chat,
          muted: ['messages', 'system'],
        },
      };
      client.writeQuery({
        query: GetChatQuery,
        variables: {chatId},
        data: newChatQuery,
      });
    }

    toast.success('Chat has been muted!');
  } catch (error) {
    toast.error('Failed to mute chat, please refresh the page and try again');
    console.error(error);
  }
};

const unMuteChat = async (chatId: string) => {
  try {
    await client.mutate({
      mutation: UnMuteChatMutation,
      variables: {
        chatId,
        muteTypes: ['messages', 'system'],
      },
    });
    const chatQuery = await getChatFromId(chatId, client);
    if (chatQuery) {
      const newChatQuery = {
        chat: {
          ...chatQuery.chat,
          muted: [],
        },
      };
      client.writeQuery({
        query: GetChatQuery,
        variables: {chatId},
        data: newChatQuery,
      });
    }
    toast.success('Chat has been unmuted!');
  } catch (error) {
    toast.error('Failed to unmute chat, please refresh the page and try again');
    console.error(error);
  }
};

export {muteChat, unMuteChat};
