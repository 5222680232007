import gql from 'graphql-tag';

const PublicUserFragment = gql`
  fragment PublicUserFragment on PublicUser {
    username
    firstName
    lastName
  }
`;

export const OrganizationUserResultFragment = gql`
  fragment OrganizationUserResultFragment on OrganizationUserResult {
    ... on UserNotFoundError {
      __typename
      message
    }
    ... on PublicUser {
      ...PublicUserFragment
    }
    ... on Node {
      id
    }
    ... on OrganizationMember {
      firstName
      lastName
      username
      role
      id
    }
  }
  ${PublicUserFragment}
`;
