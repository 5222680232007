import {useEffect} from 'react';
import {FirebaseNotificationBuilder} from './FirebaseNotificationBuilder';
import FirebaseNotificationController from './FirebaseNotificationManager';

// BroadcastChannel API allows communication between serviceworker file and client app
// https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API

export const CreateBroadcastChannel = () => {
  useEffect(() => {
    const channel = new BroadcastChannel('sw-messages');
    channel.addEventListener('message', (event) => {
      const {
        data: {data},
      } = event;

      const encryptedPayload = data?.encryptedPayload;

      const notificationPayload = FirebaseNotificationController.decryptNotificationPayload(
        encryptedPayload,
        FirebaseNotificationController.secret,
      );

      FirebaseNotificationBuilder(notificationPayload, false);
    });
  }, []);

  return null;
};
