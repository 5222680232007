import {useState} from 'react';
import {InvitedUser} from 'src/types';
export const usePaginatedPendingDataState = () => {
  const [isLoadingPendingAdditionalData, setIsLoadingPendingAdditionalData] = useState(false);
  const [isDonePendingRows, setIsDonePendingRows] = useState(false);
  const [additionalPendingRows, setAdditionalPendingRows] = useState<InvitedUser[]>([]);
  const [seenPendingContinuationIds, setSeenPendingContinuationIds] = useState<string[]>([]);

  return {
    isLoadingPendingAdditionalData,
    setIsLoadingPendingAdditionalData,
    isDonePendingRows,
    setIsDonePendingRows,
    additionalPendingRows,
    setAdditionalPendingRows,
    seenPendingContinuationIds,
    setSeenPendingContinuationIds,
  };
};
