import React from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import {IStepProps, ITellUsAboutYourSelfStepData} from 'src/types';
import {firstNameSchema, lastNameSchema, roleSchema} from 'src/utils/validations';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';

import {StepContainerCopy} from '../components/step-container-copy';
import NewStepHeader from '../components/step-header-copy';
import NewNextStepButton from '../components/NextStepButton';
import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import NewStepFooter from '../components/step-footer-copy';

const validationSchema = yup.object().shape<ITellUsAboutYourSelfStepData>({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  jobTitle: roleSchema,
});

export const HypercareSignupPersonalDetailsView = ({viewModel, inputData, onBackButtonClick}) => {
  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.signupProfile);
  }, []);

  const transformName = (value: string) => {
    if (!value || typeof value !== 'string') return value;
    return value.replace(/[^a-zA-Z ]/, '');
  };

  const onNextClick = (values: {firstName: string; lastName: string; jobTitle: string}) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signupPersonalInfoNextPressed,
      params: {
        first_name: values.firstName,
        last_name: values.lastName,
        job_title: values.jobTitle,
      },
    });
  };

  return (
    <StepContainerCopy currentStep={4}>
      <NewStepHeader
        isDisabled={false}
        title="Step 4 of 5: Tell us about yourself"
        description="Let us know how to properly address you!"
        onBackButtonClick={onBackButtonClick}
      />
      <Formik
        initialValues={{
          firstName: inputData.firstName,
          lastName: inputData.lastName,
          jobTitle: inputData.jobTitle,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          viewModel.handleNextButtonPressed(values);
        }}
      >
        {({handleSubmit, values, isValid, handleChange, handleBlur, setFieldValue, touched, errors}) => (
          <form onSubmit={handleSubmit}>
            <StyledTextField
              fullWidth={true}
              margin="normal"
              variant="outlined"
              label="First Name"
              value={values.firstName}
              name="firstName"
              placeholder="First Name"
              onChange={(event) => setFieldValue('firstName', transformName(event.target.value))}
              onBlur={handleBlur}
              helperText={touched.firstName && errors.firstName}
              error={touched.firstName && Boolean(errors.firstName)}
            />
            <StyledTextField
              fullWidth={true}
              margin="normal"
              variant="outlined"
              value={values.lastName}
              label="Last Name"
              name="lastName"
              placeholder="Last Name"
              onChange={(event) => setFieldValue('lastName', transformName(event.target.value))}
              onBlur={handleBlur}
              helperText={touched.lastName && errors.lastName}
              error={touched.lastName && Boolean(errors.lastName)}
            />
            <StyledTextField
              margin="normal"
              fullWidth={true}
              variant="outlined"
              value={values.jobTitle}
              label="Job Title"
              name="jobTitle"
              placeholder="Job Title (Suggested Format-> Physician/Cardiology)"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.jobTitle && errors.jobTitle}
              error={touched.jobTitle && Boolean(errors.jobTitle)}
            />
            <NewNextStepButton label="Next" onClick={() => onNextClick(values)} disabled={!isValid}>
              Next
            </NewNextStepButton>
          </form>
        )}
      </Formik>
      <NewStepFooter />
    </StepContainerCopy>
  );
};
