import React from 'react';
import AlertModal from '../../../MessengerPage/messenger/messages-layout/message-template/AlertModal';

export const AccountLogoutModal = ({title, subTitle, isOpen, setIsOpen, modalButtons}) => {
  return (
    <AlertModal
      width="sm"
      title={title}
      titleFontSize="21px"
      subtitle={subTitle}
      closeAlertModal={() => setIsOpen(false)}
      isAlertModalVisible={isOpen}
      alertModalButtons={modalButtons}
    />
  );
};
