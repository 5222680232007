export const ACCESS = {
  PUBLIC: 'public',
  PRIVATE: 'private',
} as const;

export const VISIBILITY = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
} as const;

export const NOTES = 'NOTES';
export const ADD_NOTE = 'Add note';
export const EDIT_NOTE = 'Edit note';
export const DELETE_NOTE = 'Delete note';

export const DESCRIPTION_LIMIT = 1000;
export const DISCARD = 'Discard';
export const DISCARDED = 'Discarded';
export const NOTE_ADDED = 'Note added to your profile!';
export const NOTE_UPDATED = 'Note updated!';
export const NOTE_DELETED = 'Note deleted!';
export const EDIT_NOTE_FORM_LABEL = `Enter up to ${DESCRIPTION_LIMIT} characters`;
export const UPDATE = 'Update';
export const NO_ROLE_NOTES_FOUND =
  "This role currently has no notes. Click 'Add note' to get started or 'Done' to skip.";
export const HN_NETWORK_ERROR = 'A Network Error Has Occured, Please Check Your Internet Connection And Try Again.';
export const NO_NOTES_FOUND = 'This user has no notes yet.';
export const DELETE_NOTE_MODAL_TEXT = `You're about to delete this note:`;
export const DELETE_NOTE_MODAL_ACTION_TEXT = 'This action cannot be undone.';
export const NOTE_VISIBILITY_PRIVATE_TEXT = 'Note is visible to only you and other Admins at';
export const NOTE_VISIBILITY_PUBLIC_TEXT = 'Note is visible to Everyone at';
