import {OrganizationFragments} from 'src/gql/fragment/OrganizationScopeFragment';
import client from 'src/apollo';

export default function getCachedOrgById(organizationId) {
  const organizationFragment = client.readFragment({
    id: `Organization:${organizationId}`,
    fragment: OrganizationFragments,
    fragmentName: 'OrganizationFragments',
  });

  if (organizationFragment) {
    const {id, image, name, __typename} = organizationFragment;
    return {
      organizationId: id,
      image,
      name,
      type: __typename,
    };
  } else {
    return null;
  }
}
