import React from 'react';
import {withFormik} from 'formik';
import MultiStepForm from 'src/pages/JoinOrganizationPage/join-organization/JoinOrganizationStepWrapper';

// TODO: upload and preview organizationImage file
// Yup file validation https://github.com/jaredpalmer/formik/issues/926

interface FormValues {
  email: string;
  organizationName: string;
  inviteCode: string;
}

const ResetPasswordForm = ({onComplete}) => {
  const FormikHOC = withFormik({
    mapPropsToValues: (): FormValues => ({
      email: '',
      organizationName: '',
      inviteCode: '',
    }),
    handleSubmit: async (values: FormValues, {setSubmitting}) => {
      setSubmitting(false);
      // onComplete()
    },
    displayName: 'JoinOrganizationForm',
  })(MultiStepForm);

  return <FormikHOC />;
};

export default ResetPasswordForm;
