import gql from 'graphql-tag';

export type BasicProfileFieldFragmentType = {
  fieldId: string;
  label: string;
  labelOptions: string[];
  visibility: 'public' | 'private';
  isSynced: boolean;
  isEditable: boolean;
  isRemovable: boolean;
  isRequired: boolean;
  validationRules: {
    regex: string;
  };
};

export const BasicProfileFieldFragment = gql`
  fragment BasicProfileFieldFragment on ProfileField {
    fieldId
    label
    labelOptions
    visibility
    isSynced
    isEditable
    isRemovable
    isRequired
    validationRules {
      regex
    }
  }
`;

type ProfilePhoneField = {
  __typename: 'ProfilePhoneField';
  phoneNumber: string | null;
  placeholder: string | null;
};

type ProfileTextField = {
  __typename: 'ProfileTextField';
  text: string | null;
  placeholder: string;
};

type ProfileDropdownField = {
  __typename: 'ProfileDropdownField';
  options: string[];
  selectedOptions: string[];
};

type ProfileNumericField = {
  __typename: 'ProfileNumericField';
  value: string | null;
};

type ProfileLinkField = {
  __typename: 'ProfileLinkField';
  url: string | null;
};

type ProfileEmailField = {
  __typename: 'ProfileEmailField';
  email: string | null;
};

type ProfileDateField = {
  __typename: 'ProfileDateField';
  date: string | null;
};

export type ProfileFieldFragmentType = BasicProfileFieldFragmentType &
  (
    | ProfilePhoneField
    | ProfileTextField
    | ProfileDropdownField
    | ProfileNumericField
    | ProfileLinkField
    | ProfileEmailField
    | ProfileDateField
  );

export const ProfileFieldFragment = gql`
  fragment ProfileFieldFragment on ProfileField {
    ...BasicProfileFieldFragment
    __typename
    ... on ProfilePhoneField {
      phoneNumber
      placeholder
    }
    ... on ProfileTextField {
      text
      placeholder
    }
    ... on ProfileDropdownField {
      options
      selectedOptions
    }
    ... on ProfileNumericField {
      value
    }
    ... on ProfileLinkField {
      url
    }
    ... on ProfileEmailField {
      email
    }
    ... on ProfileDateField {
      date
    }
  }

  ${BasicProfileFieldFragment}
`;
