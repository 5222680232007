import gql from 'graphql-tag';

export default gql`
  mutation RegisterDevice($deviceId: ID!, $type: DeviceType!) {
    selfMutation {
      registerDevice(id: $deviceId, type: $type) {
        id
        type
        userAgent
      }
    }
  }
`;
