import gql from 'graphql-tag';

export const CREATE_ORG_MUTATION = gql`
  mutation CreateOrganization($organizationDetails: CreateOrganizationDetails!, $domain: String) {
    createOrganization(organizationDetails: $organizationDetails, domain: $domain) {
      ...CreateOrganizationResultFragment
    }
  }
  fragment CreateOrganizationResultFragment on CreateOrganizationResult {
    __typename
    ... on Error {
      __typename
      message
    }
    ...FullOrganizationFragment
  }
  fragment FullOrganizationFragment on FullOrganization {
    __typename
    name
    imageUrl
    url
    inviteCodes {
      code
      createdAt
    }
  }
`;
