import React, {useEffect} from 'react';
import {AuthContext} from 'src/auth';
import FirebaseNotificationController from 'src/notifications/FirebaseNotificationManager';
import {isFirebaseRemoteConfigSupported} from 'src/firebase/firebaseSetup';
import getCurrentOrganizationId from 'src/utils/organizationHelper/getCurrentOrganizationId';
import client from 'src/apollo';
import GetSelfOrganizations from 'src/gql/query/GetUserOrganizations';
import store from 'src/redux';
import {actions} from 'src/redux/actions/organization';
import getCachedOrganizationById from 'src/utils/organizationHelper/getCachedOrganizationById';
import {updateScopeToken} from 'src/utils/localStorageHandler';
import {CreateBroadcastChannel} from './CreateBroadcastChannel';

const FCMHandler = ({isLoggedIn}) => {
  CreateBroadcastChannel();
  useEffect(() => {
    const handleNotificationRegistration = async () => {
      const isFirebaseSupported = await isFirebaseRemoteConfigSupported();

      if (isLoggedIn && isFirebaseSupported) {
        FirebaseNotificationController.requestNotificationPermission();
        FirebaseNotificationController.recieveForegroundMessages();
      }
    };

    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    handleNotificationRegistration();
    return () => navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
  }, [isLoggedIn]);

  async function handleServiceWorkerMessage(event: MessageEvent) {
    if (event.data.payload) {
      const {chatId, messageSenderOrganizationId} = event.data.payload;

      const currentOrganizationID = getCurrentOrganizationId();

      if (currentOrganizationID !== messageSenderOrganizationId) {
        await client.query({
          query: GetSelfOrganizations,
        });
        const notificationOrganization = getCachedOrganizationById(messageSenderOrganizationId);
        store.dispatch(actions.setCurrentOrganization(notificationOrganization));
        updateScopeToken({organizationId: messageSenderOrganizationId});

        window.routerHistory.push(`/messenger/${chatId}`);
        window.location.reload();
      } else {
        window.routerHistory.push(`/messenger/${chatId}`);
      }
    }
  }

  return (
    <React.Fragment>
      <audio id="priority-notification-player" autoPlay muted>
        <source type="audio/wav" id="priority-notification-src" src="/sounds/priority-notification.wav" />
      </audio>
      <audio id="regular-notification-player" autoPlay muted>
        <source type="audio/wav" id="regular-notification-src" src="/sounds/regular-notification.mp3" />
      </audio>
      <audio id="urgent-notification-player" autoPlay muted>
        <source type="audio/wav" id="urgent-notification-src" src="/sounds/priority-urgent.mp3" />
      </audio>
    </React.Fragment>
  );
};

export default () => (
  <AuthContext.Consumer>{({isLoggedIn}) => <FCMHandler isLoggedIn={isLoggedIn} />}</AuthContext.Consumer>
);
