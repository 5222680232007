import React, {useState} from 'react';
import * as ReactModal from 'react-modal';
import {Chat} from 'src/types';
import {StyledModal} from 'src/styles/styled-components/ModalStyleComponents';
import ChatInformationAddUserModalV2 from './ChatInformationAddUserModalV2';

interface Props {
  closeModal: () => void;
  reactModalProps: ReactModal.Props;
  chat: Chat;
}

const ChatInformationAddUserContainerV2: React.FC<Props> = (props) => {
  const [shouldClose, setShouldClose] = useState(true);

  const {reactModalProps, closeModal} = props;
  const {isOpen} = reactModalProps;

  const shouldCloseModal = () => {
    setShouldClose(true);
  };
  const shouldNotCloseModal = () => {
    setShouldClose(false);
  };

  return (
    <StyledModal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnEsc={shouldClose}
      shouldCloseOnOverlayClick={shouldClose}
      onRequestClose={closeModal}
    >
      <ChatInformationAddUserModalV2
        {...props}
        shouldCloseModal={shouldCloseModal}
        shouldNotCloseModal={shouldNotCloseModal}
      />
    </StyledModal>
  );
};

export default ChatInformationAddUserContainerV2;
