import gql from 'graphql-tag';

export const MESSAGES_DELIVERED = gql`
  mutation DeliveredMessages($chatId: ID!, $messageIds: [Int!]!) {
    chat(id: $chatId) {
      __typename
      ... on ChatMutation {
        messagesDelivered(ids: $messageIds) {
          __typename
          ... on AsyncResponse {
            requestId
          }
          ... on Error {
            message
          }
        }
      }
    }
  }
`;
