import React, {useContext} from 'react';

import LocatingPageRepository from 'src/pages/LocatingPage/LocatingPageRepository';
import {User, UserAddress} from 'src/types';
import {AuthContext} from 'src/auth';
import ProfilePageFormContext from 'src/contexts/HypercareProfileFieldsContext';
import {
  CANNOT_MODIFY_DIR_SYNC_FIELDS_ERROR,
  CANNOT_MODIFY_DIR_SYNC_FIELDS_ERROR_MESSAGE,
  DELETE_PROFILE_ADDRESS_ERROR,
  DUPLICATE_ADDRESSES,
  GET_OPC_NETWORK_ERROR,
  INVALID_ADDRESS_COUNT,
  INVALID_ADDRESS_UPDATE_REQUEST,
  OPC_DEFAULT_ERROR,
  UNVERIFIED_ADDRESS,
  UPDATE_ADDRESS_LABEL_NETWORK_ERROR,
  UPDATE_ADDRESS_VISIBILITY_NETWORK_ERROR,
  UPDATE_PROFILE_ERROR,
  USER_NOT_FOUND_ERROR,
  USER_NOT_FOUND_ERROR_MESSAGE,
} from 'src/constants/networkError';
import store from 'src/redux';

export default function HypercareSelfProfileViewModel() {
  const {
    updateProfilePreference,
    updateProfileFormFields,
    updateAddressVisibility,
    deleteAddress,
    fetchSelfOrganizations,
    updateAddressLabel,
    useFetchOrganizationLabelOptions,
    useFetchSelfProfile,
    useFetchOrganizationAllowInviteStatus,
  } = LocatingPageRepository();

  const {updateAuthUserInfo} = useContext(AuthContext);

  const {
    changedVisibilityAddresses,
    setChangedVisibilityAddresses,
    deletedAddresses,
    setDeletedAddresses,
    editedLabelAddresses,
    setEditedLabelAddresses,
  } = useContext(ProfilePageFormContext);

  const currentOrganization = store.getState().organization;

  const useGetProfileInformation = () => {
    const result = useFetchSelfProfile();
    return {
      data: result.data?.organizationalUnitQuery.organizationalUnit.me,
      error: result.error,
      loading: result.loading,
      refetch: result.refetch,
    };
  };

  const useGetOrganizationAllowInviteStatus = () => {
    const result = useFetchOrganizationAllowInviteStatus();
    return {
      data: result.data?.organizationalUnitQuery.organizationalUnit.allowInvites || false,
      error: result.error,
      loading: result.loading,
      refetch: result.refetch,
    };
  };

  const resetContextState = () => {
    setChangedVisibilityAddresses([]);
    setDeletedAddresses([]);
  };

  const handleUpdateProfile = async (
    values,
    addresses,
  ): Promise<{success: boolean | undefined; error: boolean | string}> => {
    const [updateProfileFieldsResult, addressVisibilityResult, updateAddressLabelResult] = await Promise.all([
      updateProfileFields(values),
      handleUpdateAddressVisibility(),
      handleUpdateAddressLabel(),
    ]);

    const deleteAddressResult = await handleDeleteAddress();

    const updateAddressPreferenceResult = await updateAddressPreference(addresses);

    if (
      updateProfileFieldsResult?.success &&
      updateAddressPreferenceResult?.success &&
      addressVisibilityResult.success &&
      deleteAddressResult.success &&
      updateAddressLabelResult.success
    ) {
      return {success: true, error: false};
    }

    let error: string | boolean = UPDATE_PROFILE_ERROR;

    if (updateProfileFieldsResult?.error) {
      error = updateProfileFieldsResult?.error;
    }

    if (updateAddressPreferenceResult?.error) {
      error = updateAddressPreferenceResult.error;
    }

    if (addressVisibilityResult.error) {
      error = UPDATE_ADDRESS_VISIBILITY_NETWORK_ERROR;
    }

    if (deleteAddressResult.error) {
      error = DELETE_PROFILE_ADDRESS_ERROR;
    }

    if (updateAddressLabelResult.error) {
      error = UPDATE_ADDRESS_LABEL_NETWORK_ERROR;
    }

    return {success: false, error};
  };

  const updateProfileFields = async (values) => {
    const result = await updateProfileFormFields(values);

    switch (result?.result?.data?.selfMutation?.updateProfile.__typename) {
      case 'FullOrganizationMember':
        updateAuthUserInfo(result.result.data?.selfMutation.updateProfile);
        return Promise.resolve({error: null, success: true});
      case CANNOT_MODIFY_DIR_SYNC_FIELDS_ERROR:
        return Promise.resolve({error: CANNOT_MODIFY_DIR_SYNC_FIELDS_ERROR_MESSAGE, success: false});
      case USER_NOT_FOUND_ERROR:
        return Promise.resolve({error: USER_NOT_FOUND_ERROR_MESSAGE, success: true});
    }

    if (result.error) {
      return Promise.resolve({error: result.error, success: false});
    }
  };

  const updateAddressPreference = async (addresses: UserAddress[]) => {
    let addressArray = addresses.map((address) => address.address);
    const result = await updateProfilePreference(addressArray);

    const responseObject = result.result?.data?.selfMutation?.updateAddressPreference;

    if (responseObject?.addresses) {
      return {
        success: true,
        error: false,
      };
    }

    let error = OPC_DEFAULT_ERROR;

    if (responseObject?.message || result.error) {
      switch (responseObject?.__typename) {
        case INVALID_ADDRESS_UPDATE_REQUEST:
          error = GET_OPC_NETWORK_ERROR(INVALID_ADDRESS_UPDATE_REQUEST);
          break;
        case DUPLICATE_ADDRESSES:
          error = GET_OPC_NETWORK_ERROR(DUPLICATE_ADDRESSES);
          break;

        case UNVERIFIED_ADDRESS:
          error = GET_OPC_NETWORK_ERROR(UNVERIFIED_ADDRESS);
          break;

        case INVALID_ADDRESS_COUNT:
          error = GET_OPC_NETWORK_ERROR(INVALID_ADDRESS_COUNT);
          break;

        default:
          return {success: false, error};
      }

      return {success: false, error};
    }
  };

  const handleUpdateAddressLabel = async () => {
    if (!editedLabelAddresses?.length) return Promise.resolve({error: null, success: true});

    const handleUpdateAddressLabelPromise = editedLabelAddresses.map((address) =>
      updateAddressLabel({address: address.address, type: address.type!, updatedLabel: address.label ?? ''}),
    );

    const results = await Promise.all(handleUpdateAddressLabelPromise);

    const hasError = results.find((result) => result.error);

    if (hasError) {
      return {error: true, success: false};
    } else {
      return {error: null, success: true};
    }
  };

  const handleUpdateAddressVisibility = async () => {
    if (!changedVisibilityAddresses?.length) return Promise.resolve({error: null, success: true});

    const handleUpdateAddressPromise = changedVisibilityAddresses.map(({address, type, access}) =>
      updateAddressVisibility(address, type!, access),
    );

    const results = await Promise.all(handleUpdateAddressPromise);

    const hasError = results.find((result) => result.error);

    if (hasError) {
      return {error: true, success: false};
    } else {
      return {error: null, success: true};
    }
  };

  const handleLabelChange = (currentAddress: UserAddress, label: string) => {
    const newUpdatedLabelAddress = {...currentAddress, label};
    const addressIndex = editedLabelAddresses.findIndex((address) => address.id === newUpdatedLabelAddress.id);

    if (addressIndex !== -1) {
      const updatedAddresses = [...editedLabelAddresses];
      updatedAddresses[addressIndex] = newUpdatedLabelAddress;
      setEditedLabelAddresses(updatedAddresses);
    } else {
      setEditedLabelAddresses([...editedLabelAddresses, newUpdatedLabelAddress]);
    }
  };

  const handleAddressVisibilityChange = (currentAddress, accessValue: string) => {
    const newUpdatedAddress = {...currentAddress, access: accessValue};
    const addressIndex = changedVisibilityAddresses.findIndex((address) => address.id === newUpdatedAddress.id);

    if (addressIndex !== -1) {
      const updatedAddresses = [...changedVisibilityAddresses];
      updatedAddresses[addressIndex] = newUpdatedAddress;
      setChangedVisibilityAddresses(updatedAddresses);
    } else {
      setChangedVisibilityAddresses([...changedVisibilityAddresses, newUpdatedAddress]);
    }
  };

  const handleDeleteAddress = async () => {
    if (!deletedAddresses?.length) {
      return Promise.resolve({error: null, success: true});
    }

    const handleDeleteAddressPromise = deletedAddresses.map((deletedAddress) => deleteAddress(deletedAddress));

    const results = await Promise.all(handleDeleteAddressPromise);

    const hasError = results.find((result) => result.error);
    if (hasError) {
      return {error: true, success: false};
    } else {
      return {error: null, success: true};
    }
  };

  const handleUpdateDeleteAddress = (currentAddress: UserAddress) => {
    const findAddressMatch = deletedAddresses.find((address) => address.id === currentAddress.id);

    if (!findAddressMatch) {
      setDeletedAddresses([...deletedAddresses, currentAddress]);
    }
  };

  const getSelfOrganizations = async () => {
    const {result, error} = await fetchSelfOrganizations();

    if (error) {
      return {error};
    }

    if (result) {
      const usersOrganizations = result.me.organizations;

      const organizationFullScope = usersOrganizations?.find(
        (organization) => organization.id == currentOrganization.organizationId,
      );

      const siteFullScope = organizationFullScope?.sites;

      siteFullScope?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      const defaultSelectedSite = siteFullScope?.[0];

      return {
        defaultSelectedSite,
        siteFullScope,
      };
    }

    return {result};
  };

  const useGetOrganizationLabelOptions = () => {
    const result = useFetchOrganizationLabelOptions();
    return {
      data: result.data?.organizationalUnitQuery.organizationalUnit.addressLabels,
      error: result.error,
      loading: result.loading,
      //refetch: result.refetch,
    };
  };

  return {
    updateAddressPreference,
    updateProfileFields,
    handleUpdateProfile,
    handleAddressVisibilityChange,
    handleUpdateDeleteAddress,
    handleDeleteAddress,
    resetContextState,
    getSelfOrganizations,
    useGetOrganizationLabelOptions,
    useGetProfileInformation,
    useGetOrganizationAllowInviteStatus,
    handleLabelChange,
  };
}
