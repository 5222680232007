import gql from 'graphql-tag';

export const FETCH_DEPARTMENT_ROLES_NOTES = gql`
  query roleNotes($organizationalUnit: OrganizationalUnitInput!, $scheduleId: Int!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on OrganizationalUnitNotFoundError {
          message
        }
        ... on Department {
          id
          scheduling {
            schedule(id: $scheduleId) {
              roles {
                ...RoleResultFields
              }
            }
          }
        }
      }
    }
  }

  fragment RoleResultFields on Role {
    id
    name
    notes {
      ...RoleNoteFields
    }
  }

  fragment RoleNoteFields on RoleNote {
    id
    note
    access
    updatedAt
  }
`;
