import ApiHelper from '../../../../api';
import {AuthPayloadDTO, AuthSSOVendors, STALogin, RequestVerificationPayload} from '../../../../types';
import {AccountStatus, OrgLoginMethods} from '../../../../types/sta';
import {checkIfUserHasLoginScope} from '../../../../utils/userHelper/userUtils';
import {SSO_VENDOR} from '../../../../constants/storageKeys';
import {TOO_MANY_CHALLENGES} from '../../../../constants/networkError';

interface IOrganizationViewModelProps {
  STALogin: STALogin;
}

export const AccountSelectionViewModel = () => {
  const checkIfAccessTokenIsExpired = (accessToken: string) => {};

  const getBasicUser = async (email: string, orgUrl: string) => {
    const res = await ApiHelper.PublicEndpoints.fetchBasicUser(email, orgUrl);

    if (res?.data?.user.__typename === 'UserNotFound') {
      return 'UserNotFound';
    }

    if (res.data) {
      return res.data.user;
    }
  };

  const handleLoginToClickedAccount = async (savedOrg: AuthPayloadDTO) => {
    const basicUser = await getBasicUser(savedOrg.user.email || '', savedOrg.organization.url);

    if (!basicUser || basicUser === 'UserNotFound') {
      return {error: 'UserNotFound'};
    }

    const firstSSOProfile = basicUser.ssoProfiles?.[0];

    const getEnteredEmailDomain = (savedOrg.user.email || '').split('@')[1];

    const checkAccountForSSOScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.SSO);
    const checkAccountForOTPScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.OTP);
    const checkAccountForPasswordScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.PASSWORD);

    switch (basicUser.accountStatus) {
      case AccountStatus.INACTIVE:
        return {error: 'Inactive account'};
      case AccountStatus.SHELL:
        return {screen: 'ActivateShellAccount'};
      case AccountStatus.ACTIVE:
        if (firstSSOProfile?.domain === getEnteredEmailDomain && checkAccountForSSOScope) {
          if (firstSSOProfile?.auth0Id && firstSSOProfile?.ssoVendor) {
            localStorage.setItem(SSO_VENDOR, firstSSOProfile?.ssoVendor);
            if (firstSSOProfile?.ssoVendor === AuthSSOVendors.WORKOS) {
              await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(
                firstSSOProfile?.auth0Id,
                firstSSOProfile?.ssoVendor,
              );
            } else {
              return {auth0Id: firstSSOProfile?.auth0Id, address: ''};
            }
          }
        } else if (checkAccountForOTPScope) {
          const startRequestOTPFlow = await requestAddressOTP({address: savedOrg.user.email || '', type: 'email'});

          if (typeof startRequestOTPFlow === 'string') {
            return {screen: OrgLoginMethods.OTP, challengeId: startRequestOTPFlow};
          } else {
            return startRequestOTPFlow;
          }
        } else if (checkAccountForPasswordScope) {
          return {screen: OrgLoginMethods.PASSWORD};
        } else {
          return {error: 'No supported login methods'};
        }
    }
  };

  const requestAddressOTP = async ({address, type}: RequestVerificationPayload) => {
    const res = await ApiHelper.PrivateEndpoints.addressVerificationRequest({address, type});

    if (res && res?.data?.response) {
      const challengeId: string = res.data.response.challengeId;
      return challengeId;
    } else {
      if (res && res?.data?.errors[0].name === TOO_MANY_CHALLENGES) {
        return {
          error: 'Too many challenges, please wait before starting another challenge',
        };
      } else {
        return {
          error: 'Failed to request validation code, please check your internet connection and try again',
        };
      }
    }
  };

  return {handleLoginToClickedAccount};
};
