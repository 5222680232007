import React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
    <path
      fill={props.fill || '#FF3E55'}
      d="M3.5 19.7V8.775q-.4-.2-.7-.613-.3-.412-.3-.962V4.8q0-.75.525-1.275Q3.55 3 4.3 3h15.4q.75 0 1.275.525.525.525.525 1.275v2.4q0 .55-.3.962-.3.413-.7.613V19.7q0 .75-.525 1.275-.525.525-1.275.525H5.3q-.75 0-1.275-.525Q3.5 20.45 3.5 19.7ZM5 9v10.7q0 .125.088.213.087.087.212.087h13.4q.125 0 .213-.087.087-.088.087-.213V9Zm14.7-1.5q.125 0 .213-.088Q20 7.325 20 7.2V4.8q0-.125-.087-.213-.088-.087-.213-.087H4.3q-.125 0-.212.087Q4 4.675 4 4.8v2.4q0 .125.088.212.087.088.212.088ZM9.2 13.7h5.6v-1.5H9.2ZM5 20V9 20Z"
    />
  </svg>
);
