const mimeToExtension = {
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': 'dotx',
  'application/vnd.ms-word.document.macroEnabled.12': 'docx',
  'application/vnd.ms-word.template.macroEnabled.12': 'dotx',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': 'xltx',
  'application/vnd.ms-excel.sheet.macroEnabled.12': 'xlsx',
  'application/vnd.ms-excel.template.macroEnabled.12': 'xltx',
  'application/vnd.ms-excel.addin.macroEnabled.12': 'xlsx',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12': 'xlsx',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
  'application/vnd.openxmlformats-officedocument.presentationml.template': 'potx',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'ppsx',
  'application/vnd.ms-powerpoint.addin.macroEnabled.12': 'ppt',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12': 'ppt',
  'application/vnd.ms-powerpoint.template.macroEnabled.12': 'pptx',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': 'pptx',
  'application/pdf': 'pdf',
  'application/json': 'json',
  'video/mp4': 'mp4',
  'video/quicktime': 'mov',
  'audio/mpeg': 'mp3',
  'audio/mp3': 'mp3',
  'audio/m4a': 'm4a',
  'audio/x-m4a': 'm4a',
  'audio/mp4': 'mp4',
  'text/plain': 'txt',
  'image/png': 'png',
  'image/jpeg': 'jpg',
  'image/gif': 'gif',
  'image/tiff': 'tiff',
  'image/bmp': 'bmp',
};

export default mimeToExtension;
