import gql from 'graphql-tag';
import {userFragmentString, userFragmentStringWithAddresses} from 'src/gql/fragment/GeneralUserFragment';

export const GetUserWithPhoneAndEmail = gql`
  query GetUserByIds($ids: [ID!]!) {
    users (ids: $ids) {
      ${userFragmentStringWithAddresses}
    }
  }
`;

export default gql`
  query GetUserByIds($ids: [ID!]!) {
    users (ids: $ids) {
      ${userFragmentString}
    }
  }
`;
