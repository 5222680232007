import gql from 'graphql-tag';

export default gql`
  query FetchOrganizationForInviteCode($code: String!) {
    organizationForInviteCode(code: $code) {
      image
      name
      id
      termsOfServiceUrl
      privacyPolicyUrl
    }
  }
`;
