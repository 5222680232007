import gql from 'graphql-tag';

export default gql`
  mutation RemoveUserAddress($address: String!, $type: AddressType!) {
    user {
      updateAddress(address: $address, type: $type) {
        remove
      }
    }
  }
`;
