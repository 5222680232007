import {useState} from 'react';
import {PaginatedUsersType} from 'src/types/PaginatedTypes';
export const usePaginatedDataState = () => {
  const [isLoadingAdditionalData, setIsLoadingAdditionalData] = useState(false);
  const [isDoneRows, setIsDoneRows] = useState(false);
  const [additionalRows, setAdditionalRows] = useState<PaginatedUsersType[]>([]);
  const [seenContinuationIds, setSeenContinuationIds] = useState<string[]>([]);

  return {
    isLoadingAdditionalData,
    setIsLoadingAdditionalData,
    isDoneRows,
    setIsDoneRows,
    additionalRows,
    setAdditionalRows,
    seenContinuationIds,
    setSeenContinuationIds,
  };
};
