import * as React from 'react';

export default (props) => (
  <svg width="20" height="20" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 1H9V0.5C9 0.223858 8.77614 0 8.5 0C8.22386 0 8 0.223858 8 0.5V1H4V0.5C4 0.223858 3.77614 0 3.5 0C3.22386 0 3 0.223858 3 0.5V1H1C0.45 1 0 1.45 0 2V12C0 12.55 0.45 13 1 13H11C11.55 13 12 12.55 12 12V2C12 1.45 11.55 1 11 1ZM11 12H1V5H11V12ZM11 4H1V2H3V2.5C3 2.77614 3.22386 3 3.5 3C3.77614 3 4 2.77614 4 2.5V2H8V2.5C8 2.77614 8.22386 3 8.5 3C8.77614 3 9 2.77614 9 2.5V2H11V4Z"
      fill="#FF3E55"
    />
  </svg>
);
