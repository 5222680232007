import gql from 'graphql-tag';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';
import ReadReceiptFragment from 'src/gql/fragment/ReadReceiptFragment';
import DeliveryReceiptFragment from 'src/gql/fragment/DeliveryReceiptFragment';
import FileAttachmentFragment from 'src/gql/fragment/FileAttachmentFragment';
import MessageTemplateFormFragment from 'src/gql/fragment/MessageTemplateFormFragment';

export default gql`
  query GetMessageById($chatId: ID!, $messageId: Int!) {
    chat(chatId: $chatId) {
      id
      message(id: $messageId) {
        ...MessageFragment
      }
    }
  }
  fragment MessageFragment on Message {
    id
    message
    image
    type
    dateCreated
    priority
    priorityType
    attachment {
      ...FileAttachmentFragment
    }
    sender {
      ...GeneralUserFragment
    }
    deliveredTo {
      ...DeliveryReceiptFragment
    }
    readBy {
      ...ReadReceiptFragment
    }
    template {
      ...MessageTemplateFormFragment
    }
    # currently we do not want to deal with recursive replies, therefore repliedTo message type only contains partial message fragment information
    repliedTo {
      id
      message
      image
      type
      priority
      priorityType
      sender {
        id
        firstname
        lastname
      }
      attachment {
        ...FileAttachmentFragment
      }
      template {
        ...MessageTemplateFormFragment
      }
    }
  }
  ${GeneralUserFragment}
  ${ReadReceiptFragment}
  ${DeliveryReceiptFragment}
  ${FileAttachmentFragment}
  ${MessageTemplateFormFragment}
`;
