import moment from 'moment';
import {Role, RoleResultInfo, ScheduleStateType, MarketplacePosts, MonthlyCalendar} from 'src/types';
import {ARBITRARY_DATE, PUBLISHED} from 'src/constants/scheduler';
import splitShifts, {splitMarketplaceShift} from 'src/utils/scheduling/splitShifts';
import sortRoles from 'src/utils/sortRoles';

const monthlyRoles = [] as Role[];

// formating base on utc time
function initMonthlyCalendarShift(startDate?: string, endDate?: string) {
  const monthlyDays = {};

  const startOfMonth = startDate ? moment(startDate).add(2, 'days').startOf('month') : moment().utc().startOf('month');
  const endOfMonth = endDate
    ? moment(endDate).subtract(2, 'days').endOf('month').millisecond(0o00)
    : moment().utc().endOf('month').millisecond(0o00);

  let day = startOfMonth;

  while (day <= endOfMonth) {
    // eslint-disable-next-line
    monthlyDays[day.format('YYYY-MM-DD')] = monthlyRoles.map((role, index) => {
      return {
        shiftId: 'test',
        role: {...role},
        assignee: [],
      };
    });
    day = day.clone().add(1, 'd');
  }

  return monthlyDays;
}

interface Payload {
  roles: RoleResultInfo[];
  startDate: string;
  endDate: string;
  scheduleInfo: {
    state: ScheduleStateType;
    id: number;
  };
}

interface MarketplacePayload {
  roles: RoleResultInfo[];
  startDate: string;
  endDate: string;
  marketplacePosts: MarketplacePosts[];
  preventSplitCrossDay: boolean;
}

// note: the initial backend implementation does not expose shifts directly:
// which all shifts are under roles[], and shifts fragment added Role they belongs to in later implementation...
// this VM implement can be improved as backend had updated its endpoints with many iterations
// the implementation below as well as on Admin site, is just for rushing for mvp purpose with given time

function formatShiftPerRole(
  roles: RoleResultInfo[],
  baseCalendar: {},
  MarketplacePayload: MarketplacePosts[] | null,
  preventSplitCrossDay: boolean,
) {
  const roleContainer: Role[] = [];
  roles?.sort(sortRoles).forEach((role, index) => {
    // arbitrary date as role only need range and startTime
    const utcStartTime = moment.utc(`${ARBITRARY_DATE} ${role.startTime}`, 'YYYY/MM/DD hh:mm');
    const utcEndTime = moment.utc(`${ARBITRARY_DATE} ${role.endTime}`, 'YYYY/MM/DD hh:mm');

    const formattedRole = {
      roleId: parseInt(role.id),
      roleName: role.name,
      startTime: utcStartTime.local(),
      endTime: utcEndTime.local(),
      duration: role.duration,
      repeatRule: role.repeatRule,
      required: role.required ? role.required : false,
      displayPosition: role.displayPosition,
      updatedAt: role.updatedAt,
      index,
    } as Role;

    roleContainer.push(formattedRole);

    Object.keys(baseCalendar).forEach((key) => {
      baseCalendar[key].push({
        role: formattedRole,
        assignee: [],
      });
    });

    if (MarketplacePayload) {
      splitMarketplaceShift(MarketplacePayload as MarketplacePosts[], baseCalendar, role.id, index);
    } else {
      const {shifts} = role;
      splitShifts(shifts, baseCalendar, index, preventSplitCrossDay);
    }
  });

  return roleContainer;
}

export const transformMarketplaceShiftsToViewModel = (payload: MarketplacePayload) => {
  const {roles, startDate, endDate, marketplacePosts} = payload;
  const baseCalendar: MonthlyCalendar = initMonthlyCalendarShift(startDate, endDate);
  const roleContainer = formatShiftPerRole(roles, baseCalendar, marketplacePosts, false);

  const baseResultPayload = {
    startDateISOstring: startDate,
    endDateISOstring: endDate,
    scheduleId: null,
    scheduleState: PUBLISHED as ScheduleStateType,
    roleContainer,
  };

  return {
    ...baseResultPayload,
    monthlyCalendar: baseCalendar,
  };
};

export default (payload: Payload, preventSplitCrossDay: boolean) => {
  const {roles, startDate, endDate, scheduleInfo} = payload;
  const baseCalendar: MonthlyCalendar = initMonthlyCalendarShift(startDate, endDate);
  const roleContainer: Role[] = formatShiftPerRole(roles, baseCalendar, null, preventSplitCrossDay);

  const baseResultPayload = {
    startDateISOstring: startDate,
    endDateISOstring: endDate,
    scheduleId: scheduleInfo ? scheduleInfo.id : null,
    scheduleState: scheduleInfo ? scheduleInfo.state : 'draft',
    roleContainer,
  };

  return {
    ...baseResultPayload,
    monthlyCalendar: baseCalendar,
  };
};
