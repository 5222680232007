import React from 'react';

export const BackArrowButton = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6667 9.16634H6.52504L11.1834 4.50801L10 3.33301L3.33337 9.99967L10 16.6663L11.175 15.4913L6.52504 10.833H16.6667V9.16634Z"
      fill="#4A4A4A"
    />
  </svg>
);
