import gql from 'graphql-tag';
import {ProfileNoteFields} from '../fragment/ProfileNoteFieldsFragment';
import {OrganizationUserResultFragment} from '../fragment/OrganizationUserResultFragment';

const UserProfileResultFragment = gql`
  fragment UserProfileResultFragment on UserProfileResult {
    ... on Error {
      __typename
      message
    }
    ... on GeneralUser {
      firstname: firstName
      lastName
      username
      __typename
    }

    ... on Node {
      id
    }

    ... on OrganizationMember {
      notes {
        ...ProfileNoteFields
        __typename
      }
      __typename
    }

    ... on FullOrganizationMember {
      notes {
        ...ProfileNoteFields
        __typename
      }
    }
  }
  ${ProfileNoteFields}
`;

export default gql`
  query FetchUserProfile($organizationalUnit: OrganizationalUnitInput!, $userId: ID!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on Error {
          __typename
          message
        }
        ... on Organization {
          member(id: $userId) {
            ...UserProfileResultFragment
            __typename
          }
        }
      }
    }
  }
  ${UserProfileResultFragment}
`;
