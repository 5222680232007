import client from 'src/apollo';
import {toast} from 'react-toastify';
import GetMessageAttachment from 'src/gql/query/GetMessageAttachment';

export default async function ({chatId, messageId}) {
  // download tag only works with same origin, creating blob on frontend instead
  try {
    toast.info('Downloading...', {
      autoClose: false,
    });
    const {data} = await client.query({
      query: GetMessageAttachment,
      variables: {
        chatId,
        messageId,
      },
      fetchPolicy: 'no-cache',
    });

    const {fileName, mimeType, blob} = data.chat.message.attachment;

    const byteChars = atob(blob);
    const byteNumbers = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i++) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blobObj = new Blob([byteArray], {type: mimeType});

    const fileUrl = window.URL.createObjectURL(blobObj);
    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = fileUrl;
    anchor.click();

    // destroy
    anchor.remove();
    window.URL.revokeObjectURL(fileUrl);
    toast.dismiss();
  } catch (e) {
    console.error(e);
    toast.dismiss();
    toast.error('Failed to download the file. Please check your internet connection and try again.', {
      className: 'Toast-Container',
    });
  }
}
