import * as React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '197'}
    height={props.height || '94'}
    viewBox={`0 0 ${props.width || '197'} ${props.height || '94'}`}
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#C0C1C2" d="M53.242 93.529h87.859V6.996H53.242z" />
      <path fill="#0065DF" d="M90.378 16.928h13.681v8.51h9.692v11.897h-9.692v8.51H90.378v-8.51H80.59V25.438h9.787z" />
      <path fill="#FFF" d="M74.737 93.529H96.06V61.404H74.737zM98.281 93.529h21.324V61.404H98.281z" />
      <path
        fill="#0065DF"
        d="M142.354 6.996H51.99c-1.27 0-2.3-.894-2.3-1.998v-2.66c0-1.104 1.03-1.997 2.3-1.997h90.365c1.27 0 2.298.893 2.298 1.997v2.66c0 1.104-1.028 1.998-2.298 1.998"
      />
      <g>
        <path
          fill="#EBEBEB"
          d="M64.738 31.156v59.346c0 1.67-1.544 3.024-3.447 3.024H7.238c-1.904 0-3.447-1.353-3.447-3.024V31.156h60.947z"
        />
        <path
          fill="#0065DF"
          d="M37.184 81.98h15.787V68.129H37.184zM15.558 81.98h15.785V68.129H15.558zM37.184 62.012h15.787V48.16H37.184zM15.558 62.012h15.785V48.16H15.558zM65.758 31.956H2.77C1.24 31.956 0 30.867 0 29.524v-1.817c0-1.343 1.241-2.432 2.77-2.432h62.988c1.53 0 2.771 1.089 2.771 2.432v1.817c0 1.343-1.24 2.432-2.77 2.432"
        />
      </g>
      <g>
        <path
          fill="#EBEBEB"
          d="M192.925 31.156v59.346c0 1.67-1.544 3.024-3.447 3.024h-54.052c-1.905 0-3.447-1.353-3.447-3.024V31.156h60.946z"
        />
        <path
          fill="#0065DF"
          d="M165.371 81.98h15.787V68.129h-15.787zM143.744 81.98h15.787V68.129h-15.787zM165.371 62.012h15.787V48.16h-15.787zM143.744 62.012h15.787V48.16h-15.787zM193.945 31.956h-62.988c-1.53 0-2.77-1.089-2.77-2.432v-1.817c0-1.343 1.24-2.432 2.77-2.432h62.988c1.53 0 2.771 1.089 2.771 2.432v1.817c0 1.343-1.24 2.432-2.77 2.432"
        />
      </g>
    </g>
  </svg>
);
