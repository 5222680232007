import gql from 'graphql-tag';
import MessageFragment from 'src/gql/fragment/MessageFragment';

export default gql`
  mutation SendMessage(
    $chatId: ID!
    $uniqueId: ID
    $message: String!
    $priorityType: MessagePriority
    $imageId: Int
    $fileId: Int
    $type: MessageType
    $data: String
    $repliedTo: Int
  ) {
    chat(chatId: $chatId) {
      sendMessage(
        uniqueId: $uniqueId
        message: $message
        imageId: $imageId
        fileId: $fileId
        priorityType: $priorityType
        type: $type
        data: $data
        repliedTo: $repliedTo
      ) {
        ...MessageFragment
      }
    }
  }
  ${MessageFragment}
`;

export const SendTemplateMessageMutation = gql`
  mutation SendTemplateMessage(
    $chatId: ID!
    $uniqueId: ID
    $templateData: MessageTemplatePayload!
    $priorityType: MessagePriority
    $repliedTo: Int
  ) {
    chat(chatId: $chatId) {
      sendTemplateMessage(
        uniqueId: $uniqueId
        templateData: $templateData
        priorityType: $priorityType
        repliedTo: $repliedTo
      ) {
        ...MessageFragment
      }
    }
  }
  ${MessageFragment}
`;

export const RespondTemplateMessageMutation = gql`
  mutation RespondToTemplateMessage(
    $chatId: ID!
    $messageId: Int!
    $uniqueId: ID
    $responseData: MessageTemplateFormResponseValue!
  ) {
    chat(chatId: $chatId) {
      message(id: $messageId) {
        template {
          respond(uniqueId: $uniqueId, responseData: $responseData) {
            ...MessageFragment
          }
        }
      }
    }
  }
  ${MessageFragment}
`;
