import gql from 'graphql-tag';

export type AddAddressMutationResponse = {
  data: {
    selfMutation: {
      addAddress: {
        address: string;
        label: string;
        access: string;
      };
    };
  };
};

export const ADD_ADDRESS_MUTATION = gql`
  mutation AddAddress($challengeId: String!, $addressDetails: AddressDetails!) {
    selfMutation {
      addAddress(challengeId: $challengeId, details: $addressDetails) {
        __typename
        ... on Address {
          address
          label
          access
          type
          id
        }
        ... on InvalidAddressLabel {
          message
        }
        ... on ChallengeNotFound {
          message
        }
        ... on ChallengeNotCompleted {
          message
        }
        ... on ChallengeFailed {
          message
        }
        ... on AddressInUse {
          message
        }
        ... on DuplicateAddresses {
          message
        }
      }
    }
  }
`;
