import gql from 'graphql-tag';

export default gql`
  mutation ChangeStatus(
    $newStatus: WorkStatus
    $statusDescription: String
    $proxyId: ID
    $startDate: String
    $endDate: String
    $resetFields: [UserStatusField!]
  ) {
    user {
      status(
        newStatus: $newStatus
        statusDescription: $statusDescription
        proxyId: $proxyId
        startDate: $startDate
        endDate: $endDate
        resetFields: $resetFields
      ) {
        id
      }
    }
  }
`;
