import gql from 'graphql-tag';
import UserInviteFragment from 'src/gql/fragment/InviteFragment';

export const CancelUserInvite = gql`
  mutation cancelInvite($inviteId: Int!) {
    self {
      cancelInvite(inviteId: $inviteId) {
        ...UserInviteFragment
      }
    }
  }
  ${UserInviteFragment}
`;
