import gql from 'graphql-tag';
import MessageFragment from 'src/gql/fragment/MessageFragment';

export default gql`
  fragment PaginatedMessagesFragment on PaginatedMessages {
    continuationId
    messages {
      ...MessageFragment
    }
  }
  ${MessageFragment}
`;
