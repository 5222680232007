import * as React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '31'}
    height={props.height || '19'}
    viewBox="0 0 31 19"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke={props.color || '#FF0043'}>
      <rect width="30" height="18" x=".5" y=".5" rx="2.5" />
      <g transform="translate(5 3)">
        <rect width="20.467" height="7.667" x=".5" y=".5" rx="2" />
        <rect width="11.267" height="1.889" x=".5" y="10.611" rx=".944" />
        <g transform="translate(13.8 10.111)">
          <rect width="2.067" height="1.889" x=".5" y=".5" rx=".944" />
          <rect width="2.067" height="1.889" x="5.1" y=".5" rx=".944" />
        </g>
      </g>
    </g>
  </svg>
);
