import gql from 'graphql-tag';

export const DepartmentFragments = gql`
  fragment DepartmentFragments on Department {
    image
    name
    id
  }
`;

export const SiteFragments = gql`
  fragment SiteFragments on Site {
    image
    name
    id
    departments(filter: false) {
      ...DepartmentFragments
    }
  }
  ${DepartmentFragments}
`;

export const OrganizationFragments = gql`
  fragment OrganizationFragments on Organization {
    image
    name
    id
    termsOfServiceUrl
    privacyPolicyUrl
    isAdmin
    sites {
      ...SiteFragments
    }
  }
  ${SiteFragments}
`;
