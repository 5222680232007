import * as React from 'react';

export default (props) => (
  <svg width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.14645 0.646447C6.34171 0.451184 6.65829 0.451184 6.85355 0.646447L9.35355 3.14645C9.54882 3.34171 9.54882 3.65829 9.35355 3.85355L6.85355 6.35355C6.65829 6.54882 6.34171 6.54882 6.14645 6.35355C5.95118 6.15829 5.95118 5.84171 6.14645 5.64645L8.29289 3.5L6.14645 1.35355C5.95118 1.15829 5.95118 0.841709 6.14645 0.646447Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4C2.60218 4 2.22064 4.15804 1.93934 4.43934C1.65804 4.72064 1.5 5.10218 1.5 5.5V9C1.5 9.27614 1.27614 9.5 1 9.5C0.723858 9.5 0.5 9.27614 0.5 9V5.5C0.5 4.83696 0.763392 4.20107 1.23223 3.73223C1.70107 3.26339 2.33696 3 3 3H9C9.27614 3 9.5 3.22386 9.5 3.5C9.5 3.77614 9.27614 4 9 4H3Z"
      fill="#222222"
    />
  </svg>
);
