import {useEffect} from 'react';

export const useEscapeKeyEventListener = ({watchKey}: {watchKey: string}) => {
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key !== watchKey) return;

    let currentFocusedElement = document.activeElement;
    const isAnyModalOpen = document.querySelector('.ReactModal__Content');

    // dont want to trigger any events shortcuts if any modal open
    if (isAnyModalOpen) return;

    switch (event.key) {
      case watchKey:
        if (currentFocusedElement instanceof HTMLTextAreaElement || currentFocusedElement instanceof HTMLInputElement) {
          event.preventDefault();
          currentFocusedElement.blur();
        }
        break;
      default:
        break;
    }
  };
};
