import gql from 'graphql-tag';

export type ModifyAddressAccessResult = {
  data: {
    self: {
      updateAddress: {
        updateAccess: boolean;
      };
    };
  };
};

export default gql`
  mutation ModifyAddressAccess($address: String!, $addressType: AddressType!, $accessType: AccessType!) {
    self {
      updateAddress(address: $address, type: $addressType) {
        updateAccess(access: $accessType)
      }
    }
  }
`;
