import gql from 'graphql-tag';
import {OrganizationUserResultFragment} from '../fragment/OrganizationUserResultFragment';

export default gql`
  query FetchSelfProfileNotes {
    selfQuery {
      notes {
        id
        note
        createdAt
        updatedAt
        access
        createdBy {
          ...OrganizationUserResultFragment
        }
        editedBy {
          ...OrganizationUserResultFragment
        }
      }
    }
  }
  ${OrganizationUserResultFragment}
`;
