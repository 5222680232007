import gql from 'graphql-tag';

export type MinimalSiteData = {
  id: number;
  name: string;
};

export type FetchSitesForOrganizationRequestPayload = {
  organizationId: number;
  filterStatus: boolean;
};

export type FetchSitesForOrganizationResponse = {
  locating: {
    organization: {
      sites: MinimalSiteData[];
    };
  };
};

export const FETCH_SITES_FOR_ORGANIZATION = gql`
  query FetchSitesForOrganization($organizationId: Int!, $filterStatus: Boolean) {
    locating {
      organization(id: $organizationId) {
        sites(filter: $filterStatus) {
          id
          name
        }
      }
    }
  }
`;
