import React from 'react';
import {
  CANCEL,
  LOGIN_PAGE_REMOVE_ALL_DEVICE_MODAL_DESCRIPTION,
  LOGIN_PAGE_REMOVE_ALL_DEVICE_MODAL_TITLE,
  REMOVE,
} from '../../../../constants/strings';
import AlertModal from '../../../MessengerPage/messenger/messages-layout/message-template/AlertModal';

interface ILogoutAllAccountsModalProps {
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  handleOnClick: () => void;
}

export const LogoutAllAccountsModal = ({isOpen, setIsOpen, handleOnClick}: ILogoutAllAccountsModalProps) => {
  return (
    <AlertModal
      width="sm"
      title={LOGIN_PAGE_REMOVE_ALL_DEVICE_MODAL_TITLE}
      titleFontSize="21px"
      subtitle={LOGIN_PAGE_REMOVE_ALL_DEVICE_MODAL_DESCRIPTION}
      closeAlertModal={() => setIsOpen(false)}
      isAlertModalVisible={isOpen}
      alertModalButtons={[
        {
          type: 'secondary',
          buttonLabel: CANCEL,
          onClickHandler: () => setIsOpen(false),
          id: 'cancel-btn',
        },
        {
          type: 'primary',
          buttonLabel: REMOVE,
          onClickHandler: () => handleOnClick(),
          id: 'remove-btn',
        },
      ]}
    />
  );
};
