import React from 'react';
import ContentLoader from 'react-content-loader';

export const SiteListLoader = (props) => {
  return (
    <ContentLoader backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props} speed={1}>
      <rect x="17" y="30" rx="4" ry="4" width="522" height="62" />
      <rect x="17" y="120" rx="4" ry="4" width="522" height="62" />

      <rect x="17" y="262" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="312" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="362" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="412" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="462" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="512" rx="3" ry="3" width="522" height="42" />

      <rect x="17" y="630" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="680" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="730" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="780" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="830" rx="3" ry="3" width="522" height="42" />
      <rect x="17" y="880" rx="3" ry="3" width="522" height="42" />
    </ContentLoader>
  );
};
