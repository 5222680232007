import gql from 'graphql-tag';
import ShiftRequestFragment from 'src/gql/fragment/ShiftRequestFragment';
import MarketplacePostsFragment from 'src/gql/fragment/MarketplacePostsFragment';

// note: shiftRequests is for type 'swap', marketplacePosts is for type 'give_away'
// backend have difficulty for merging into one shiftRequest

export default gql`
  query FetchArchivedShiftRequests {
    me {
      shiftRequests {
        ...ShiftRequestFragment
      }
      marketplacePosts {
        ...MarketplacePostsFragment
      }
    }
  }
  ${MarketplacePostsFragment}
  ${ShiftRequestFragment}
`;
