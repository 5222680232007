import gql from 'graphql-tag';

export const GET_TEMPLATE_AUDIT_REPORT = gql`
  query FetchMessageTemplateAuditReport(
    $templateIds: [ID!]!
    $startDate: String!
    $endDate: String!
    $senderUserIds: [ID!]!
    $recipientUserIds: [ID!]!
  ) {
    me {
      reports {
        templateMessageAudit(
          templateIds: $templateIds
          startDate: $startDate
          endDate: $endDate
          senderUserIds: $senderUserIds
          recipientUserIds: $recipientUserIds
        ) {
          requestId
        }
      }
    }
  }
`;

export const GET_MESSAGE_TEMPLATE_REPORT = gql`
  query FetchOrganizationMessageTemplates($organizationId: Int!) {
    locating {
      organization(id: $organizationId) {
        messageTemplates {
          templates {
            ...MessageTemplateFormFragment
          }
        }
      }
    }
  }

  fragment MessageTemplateFormFragment on MessageTemplateForm {
    id
    formTitle
  }
`;
