import gql from 'graphql-tag';

export default gql`
  query search($text: String!, $chatId: ID!, $continuationId: Int, $limit: Int, $direction: PaginationDirection) {
    searchQuery(text: $text) {
      searchMessagesInChat(chatId: $chatId, continuationId: $continuationId, limit: $limit, direction: $direction) {
        continuationId
        totalResultsCount
        messages {
          message {
            id
            message
            type
            dateCreated
          }
          matchedSequences
        }
      }
    }
  }
`;
