import gql from 'graphql-tag';
import {roleFragmentStringWithoutShifts} from 'src/gql/fragment/RoleFragment';
import MarketplacePostsFragment from 'src/gql/fragment/MarketplacePostsFragment';

export default gql`
    query FetchMarketplacePosts(
        $departmentId: Int!,
        $marketplaceFilter: MarketplaceFilter,
        $month: ScheduleMonths, 
        $year: Int,
    ) {
        locating {
            department(id: $departmentId) {
                schedules(month: $month, year: $year) {
                    roles {
                        ${roleFragmentStringWithoutShifts}
                    }
                    __typename
                }
                marketplacePosts(filter: $marketplaceFilter) {
                    ...MarketplacePostsFragment
                }
            }
        }
    }
    ${MarketplacePostsFragment}
`;
