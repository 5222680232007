import * as React from 'react';

export default ({height = 40, width = 40}) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
      fill="#CB3E00"
    />
    <path
      d="M14.6204 17.3685C14.5505 18.073 13.9579 18.6097 13.2499 18.6097C12.5419 18.6097 11.9493 18.073 11.8794 17.3684L11.222 10.739C11.1031 9.53988 12.045 8.5 13.25 8.5C14.455 8.5 15.3969 9.53992 15.2779 10.739L14.6204 17.3685Z"
      fill="white"
    />
    <path
      d="M13.2497 23.5C14.3078 23.5 15.1655 22.6442 15.1655 21.5885C15.1655 20.5328 14.3078 19.677 13.2497 19.677C12.1917 19.677 11.334 20.5328 11.334 21.5885C11.334 22.6442 12.1917 23.5 13.2497 23.5Z"
      fill="white"
    />
    <path
      d="M20.6204 17.3685C20.5505 18.073 19.9579 18.6097 19.2499 18.6097C18.5419 18.6097 17.9493 18.073 17.8794 17.3684L17.222 10.739C17.1031 9.53988 18.045 8.5 19.25 8.5C20.455 8.5 21.3969 9.53992 21.2779 10.739L20.6204 17.3685Z"
      fill="white"
    />
    <path
      d="M19.2497 23.5C20.3078 23.5 21.1655 22.6442 21.1655 21.5885C21.1655 20.5328 20.3078 19.677 19.2497 19.677C18.1917 19.677 17.334 20.5328 17.334 21.5885C17.334 22.6442 18.1917 23.5 19.2497 23.5Z"
      fill="white"
    />
  </svg>
);
