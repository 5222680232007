import gql from 'graphql-tag';
import {userFragmentString} from 'src/gql/fragment/GeneralUserFragment';

export const shiftFragmentString = `
  id
  status
  user {
    ${userFragmentString}
  }
  startDate
  endDate
`;

export const shiftFragmentStringWithRole = `
  ${shiftFragmentString}
  role {
    id
    name
    startTime
    endTime
  }
`;

export const ShiftFragmentWithRoleAndDepartment = gql`
  fragment ShiftFragmentWithRoleAndDepartment on Shift {
    ${shiftFragmentString}
    role {
      id
      name
      startTime
      endTime
      department {
        id
        name
      }
    }
  }
`;

export default gql`
  fragment ShiftFragment on Shift {
    ${shiftFragmentString}
  }
`;
