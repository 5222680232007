import React, {useState} from 'react';
import client from 'src/apollo';
import {
  Dialog,
  Button,
  DialogActions,
  Slide,
  IconButton,
  Typography,
  DialogContentText,
  DialogContent,
  FormControlLabel,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  CircularProgress,
} from '@mui/material';
import {lensPath, view} from 'ramda';
import {toast} from 'react-toastify';
import styled from '@emotion/styled';
import MuiDialogTitle from '@mui/material/DialogTitle';
import {TransitionProps} from '@mui/material/transitions';

import Check from 'src/svgs/Check';
import Closemark from 'src/svgs/Closemark';
import {getFullName} from 'src/utils/user';
import {User, WorkStatus} from 'src/types';
import handleGotoAlternativeContact from 'src/utils/messengerHelper/SwitchChatToAlternateContact';
import {BUSY, ON_SHIFT, UNAVAILABLE, BusyUnavailableDialogText} from 'src/constants/workStatus';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';
import fetchCurrentUserFragment from 'src/utils/fetchCurrentUserFragment';
import ChangeUserStatusMutation from 'src/gql/mutation/ChangeUserStatusMutation';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {muiTheme} from 'src/styles/theme';

const CloseButton = styled(IconButton)`
  .MuiIconButton-root {
    position: absolute;
    right: ${muiTheme.spacing(1)};
    top: ${muiTheme.spacing(1)};
    color: ${muiTheme.colors.secondary};
  }
`;

const ConfirmDialogSubtext = styled(DialogContentText)`
  .MuiDialogContentText-root {
    color: ${muiTheme.colors.black};
    font-weight: bold;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 16px 24px 24px 0px;
`;

const SendMessageDialogActions = styled(DialogActions)`
  padding: 16px 24px 24px 24px;
`;

const MyButton = styled(Button)`
  span {
    font-size: 1em;
    text-transform: none;
  }
`;

const KeepEditingBtn = styled(MyButton)`
  color: ${(props) => props.theme.colors.chatTeal} !important;
  border: 1px solid ${(props) => props.theme.colors.chatTeal} !important;
`;

const DontSendBtn = styled(KeepEditingBtn)``;

const ContinueBusyBtn = styled(KeepEditingBtn)``;

const SendAnywayBtn = styled(MyButton)`
  color: ${(props) => props.theme.colors.chatTeal} !important;
`;

const ContactAlternateContactBtn = styled(MyButton)`
  margin-left: 5px !important;
`;

const DialogTitleTypography = styled(Typography)`
  color: ${(props) => props.theme.colors.secondary} !important;
`;

const StatusDot = styled.div<{status: string}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  ${(props) => (props.status === ON_SHIFT ? `background: ${props.theme.colors.appleGreen}` : null)}
  ${(props) => (props.status === BUSY ? `background: ${props.theme.colors.watermelon}` : null)}
  ${(props) => (props.status === UNAVAILABLE ? `background: ${props.theme.colors.greyishBrown}` : null)}
`;

const UpdateStatusListItem = styled(ListItem)`
  border: 1px solid ${(props) => props.theme.colors.labelGrey} !important;
  border-radius: 5px !important;
  margin-bottom: 5px !important;
`;

const StyledButton = styled(MyButton)`
  background-color: ${(props) => props.theme.colors.watermelon} !important;
  &:disabled {
    color: white !important;
    background-color: ${(props) => props.theme.colors.disabled} !important;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: darkgrey;
  margin-right: 1em;
`;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children: React.ReactElement<any, any>},
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface CancelDialogProps {
  cancelStatusDialog: boolean;
  keepEditingHandler: () => void;
  closeStatusSettingsModal: () => void;
}

const CancelStatusSettingsDialog = ({
  cancelStatusDialog,
  keepEditingHandler,
  closeStatusSettingsModal,
}: CancelDialogProps) => {
  const handleDiscardBtn = () => {
    keepEditingHandler();
    closeStatusSettingsModal();
  };

  return (
    <Dialog fullWidth={true} maxWidth={'xs'} TransitionComponent={Transition} open={cancelStatusDialog}>
      <MuiDialogTitle display="flex" justifyContent="space-between">
        <DialogTitleTypography variant="h6">Discard unsaved changes?</DialogTitleTypography>
        <CloseButton aria-label="close" onClick={() => keepEditingHandler()}>
          <Closemark />
        </CloseButton>
      </MuiDialogTitle>
      <StyledDialogActions>
        <KeepEditingBtn onClick={() => keepEditingHandler()} variant={'outlined'}>
          Keep editing
        </KeepEditingBtn>
        <MyButton onClick={handleDiscardBtn} variant="contained" color="secondary">
          Discard anyway
        </MyButton>
      </StyledDialogActions>
    </Dialog>
  );
};

interface ConfirmStatusDialogProps {
  confirmStatusDialog: boolean;
  keepEditingHandler: () => void;
  handleConfirmBtn: (event, resetFields) => void;
}

const ConfirmStatusSettingsDialog = ({
  confirmStatusDialog,
  keepEditingHandler,
  handleConfirmBtn,
}: ConfirmStatusDialogProps) => {
  const currentUserFragment = fetchCurrentUserFragment();
  const {statusResetFields} = currentUserFragment;
  const ResetCheckbox = {
    StatusMessage: 'status_message',
    AlternateContact: 'alternate_contact',
  };
  const [resetFields, setResetFields] = useState({
    status_message: statusResetFields?.includes(ResetCheckbox.StatusMessage),
    alternate_contact: statusResetFields?.includes(ResetCheckbox.AlternateContact),
  });

  const clearResetState = () => {
    setResetFields({
      status_message: statusResetFields?.includes(ResetCheckbox.StatusMessage),
      alternate_contact: statusResetFields?.includes(ResetCheckbox.AlternateContact),
    });
  };

  const handleResetFieldsCheckboxHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResetFields({...resetFields, [event.target.name]: event.target.checked});
  };

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} TransitionComponent={Transition} open={confirmStatusDialog}>
      <MuiDialogTitle>
        <DialogTitleTypography variant="h6">Confirm status settings</DialogTitleTypography>
      </MuiDialogTitle>
      <DialogContent>
        <ConfirmDialogSubtext>When status turns available:</ConfirmDialogSubtext>
        <Grid container direction={'column'}>
          <FormControlLabel
            control={
              <Checkbox
                checked={resetFields.status_message}
                onChange={(e) => handleResetFieldsCheckboxHandler(e)}
                name={ResetCheckbox.StatusMessage}
              />
            }
            label="Remove my status message"
          />
          <FormControlLabel
            control={
              <Checkbox
                name={ResetCheckbox.AlternateContact}
                checked={resetFields.alternate_contact}
                onChange={(e) => handleResetFieldsCheckboxHandler(e)}
              />
            }
            label="Remove my alternate contact"
          />
        </Grid>
      </DialogContent>
      <StyledDialogActions>
        <KeepEditingBtn
          onClick={() => {
            clearResetState();
            keepEditingHandler();
          }}
          variant={'outlined'}
        >
          Keep editing
        </KeepEditingBtn>
        <MyButton
          variant="contained"
          color="secondary"
          onClick={(e) => {
            let _resetFields: any = [];
            if (resetFields.status_message) _resetFields.push(ResetCheckbox.StatusMessage);
            if (resetFields.alternate_contact) _resetFields.push(ResetCheckbox.AlternateContact);
            handleConfirmBtn(e, _resetFields);
          }}
        >
          Confirm
        </MyButton>
      </StyledDialogActions>
    </Dialog>
  );
};

interface SendMessageWarningProps {
  sendMessageWarningDialog: boolean;
  targetUser: User;
  closeWarningDialog: () => void;
  sendMessage: () => void;
}

const SendMessageWarningDialog = ({
  sendMessageWarningDialog,
  targetUser,
  closeWarningDialog,
  sendMessage,
}: SendMessageWarningProps) => {
  const {workStatus, workStatusProxy} = targetUser;

  const handleMixpanelEvent = (event) => {
    let eventParams = {
      user_status: workStatus,
    };
    if (event === EVENTS.alternativeContantPressed) {
      eventParams['user_id'] = workStatusProxy?.id;
    }
    AnalyticsManager.applyAnalytics({
      eventName: event,
      params: eventParams,
    });
  };

  const dialogTitle = workStatus ? BusyUnavailableDialogText[workStatus].title(targetUser) : undefined;

  const dialogSubtitle = workStatus ? BusyUnavailableDialogText[workStatus].subtitle(targetUser) : undefined;

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} TransitionComponent={Transition} open={sendMessageWarningDialog}>
      <MuiDialogTitle display={'flex'}>
        <DialogTitleTypography variant="h6">{dialogTitle}</DialogTitleTypography>
        <CloseButton aria-label="close" onClick={() => closeWarningDialog()}>
          <Closemark />
        </CloseButton>
      </MuiDialogTitle>
      <DialogContent>
        <DialogContentText>{dialogSubtitle}</DialogContentText>
      </DialogContent>
      <SendMessageDialogActions>
        <Grid container justifyContent={Boolean(workStatusProxy) ? 'space-between' : 'flex-end'}>
          <Grid item>
            <SendAnywayBtn
              onClick={() => {
                handleMixpanelEvent(EVENTS.sendAnywayPressed);
                sendMessage();
                closeWarningDialog();
              }}
            >
              Send anyway
            </SendAnywayBtn>
          </Grid>
          <Grid item>
            <DontSendBtn
              variant="outlined"
              onClick={() => {
                handleMixpanelEvent(EVENTS.dontSendPressed);
                closeWarningDialog();
              }}
            >
              Don't send
            </DontSendBtn>
            {workStatusProxy && (
              <ContactAlternateContactBtn
                variant="contained"
                color={'secondary'}
                onClick={() => {
                  handleMixpanelEvent(EVENTS.alternativeContantPressed);
                  handleGotoAlternativeContact(workStatusProxy);
                }}
              >
                Contact {getFullName(workStatusProxy)}
              </ContactAlternateContactBtn>
            )}
          </Grid>
        </Grid>
      </SendMessageDialogActions>
    </Dialog>
  );
};

interface UpdateStatusOpenAppEventProps {
  openUpdateStatusDialog: boolean;
  closeUpdateStatusDialog: () => void;
}

const UpdateStatusOpenAppEventDialog = ({
  openUpdateStatusDialog,
  closeUpdateStatusDialog,
}: UpdateStatusOpenAppEventProps) => {
  const [statusSelection, setStatusSelection] = React.useState(ON_SHIFT as WorkStatus);
  const [isLoading, setLoading] = React.useState(false);
  const [resetFieldDialog, setResetFieldDialog] = React.useState(false);

  const ResetCheckbox = {
    StatusMessage: 'status_message',
    AlternateContact: 'alternate_contact',
  };

  const closeResetFieldDialog = () => {
    setResetFieldDialog(false);
  };

  const handleListItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, status: WorkStatus) => {
    setStatusSelection(status);
  };

  const handleConfirmBtn = async (e, resetFields) => {
    closeResetFieldDialog();
    await handleSubmit(resetFields);
  };

  const handleSubmit = async (resetFields: any = null) => {
    setLoading(true);
    try {
      const currentUserFragment: User = fetchCurrentUserFragment();
      const {
        workStatus: cachedWorkStatus,
        statusDescription: cachedstatusDescription,
        workStatusProxy: cachedWorkStatusProxy,
        statusExpiryDate: cachedStatusExpiryDate,
        statusResetFields: cachedStatusResetFields,
      } = currentUserFragment;

      let workStatus: WorkStatus = statusSelection;
      let userProxyId: string | undefined = cachedWorkStatusProxy?.id;

      await client.mutate({
        mutation: ChangeUserStatusMutation,
        variables: {
          newStatus: workStatus,
          statusDescription: resetFields?.includes(ResetCheckbox.StatusMessage) ? '' : cachedstatusDescription,
          proxyId: resetFields?.includes(ResetCheckbox.AlternateContact) ? null : userProxyId,
          resetFields: resetFields || cachedStatusResetFields,
        },
        update: (store, {data}) => {
          const idLensPath = lensPath([`user`, 'status', 'id']);
          const userId = view(idLensPath, data);

          store.writeFragment({
            id: `User:${userId}`,
            fragment: GeneralUserFragment,
            fragmentName: 'GeneralUserFragment',
            data: {
              ...currentUserFragment,
              workStatus,
              statusDescription: cachedstatusDescription,
              workStatusProxy: userProxyId,
            },
          });
        },
      });

      closeUpdateStatusDialog();
      window.location.reload();
    } catch {
      toast.error('Failed to change status, please try again');
      setLoading(false);
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} TransitionComponent={Transition} open={openUpdateStatusDialog}>
      <MuiDialogTitle>
        <DialogTitleTypography variant="h6">Update your status?</DialogTitleTypography>
      </MuiDialogTitle>
      <DialogContent>
        <DialogContentText>
          Your status is currently set to Busy. You can choose to continue as Busy or update your status.
        </DialogContentText>
        <List>
          <UpdateStatusListItem
            dense
            selected={statusSelection === ON_SHIFT}
            onClick={(event) => handleListItemClick(event, ON_SHIFT)}
          >
            <ListItemAvatar>
              <StatusDot status={ON_SHIFT} />
            </ListItemAvatar>
            <ListItemText primary="Available" secondary="You will receive all message notifications" />
            {statusSelection === ON_SHIFT && (
              <ListItemSecondaryAction>
                <Check />
              </ListItemSecondaryAction>
            )}
          </UpdateStatusListItem>
          <UpdateStatusListItem
            dense
            selected={statusSelection === UNAVAILABLE}
            onClick={(event) => handleListItemClick(event, UNAVAILABLE)}
          >
            <ListItemAvatar>
              <StatusDot status={UNAVAILABLE} />
            </ListItemAvatar>
            <ListItemText
              primary="Unavailable"
              secondary="You will only receive notifications for Urgent and STAT messages"
            />
            {statusSelection === UNAVAILABLE && (
              <ListItemSecondaryAction>
                <Check />
              </ListItemSecondaryAction>
            )}
          </UpdateStatusListItem>
        </List>
      </DialogContent>
      <StyledDialogActions>
        <ContinueBusyBtn variant="outlined" onClick={() => closeUpdateStatusDialog()}>
          Continue as Busy
        </ContinueBusyBtn>
        <StyledButton
          disabled={isLoading}
          variant="contained"
          color="secondary"
          onClick={async () => {
            if (statusSelection === ON_SHIFT) setResetFieldDialog(true);
            else await handleSubmit();
          }}
        >
          {isLoading && <StyledCircularProgress color="inherit" size={20} />}
          {isLoading ? 'Sending Request...' : statusSelection === ON_SHIFT ? 'Set to Available' : 'Set to Unavailable'}
        </StyledButton>
      </StyledDialogActions>
      <ConfirmStatusSettingsDialog
        confirmStatusDialog={resetFieldDialog}
        keepEditingHandler={closeResetFieldDialog}
        handleConfirmBtn={handleConfirmBtn}
      />
    </Dialog>
  );
};

export {
  CancelStatusSettingsDialog,
  ConfirmStatusSettingsDialog,
  SendMessageWarningDialog,
  UpdateStatusOpenAppEventDialog,
};
