import gql from 'graphql-tag';

export const chatFragmentString = `
  id
  type
  title
  muted
  unreadPriorityMessages
  dateCreated
  image {
    id
    url
  }
  status
`;

export default gql`
  fragment ChatFragment on Chat {
    ${chatFragmentString}
    lastUnreadMessage {
      id
    }
    lastMessage {
      id
      message
      type
      dateCreated
      priorityType
      sender {
        id
        username
        firstname
        lastname
        profilePic {
          url
          __typename
        }
        role
        status
        workStatus
        __typename
      }
      readBy {
        user {
          id
          __typename
        }
        messageId
        timestamp
        __typename
      }
      __typename
    }
  }
`;
