import gql from 'graphql-tag';

export default gql`
  mutation ActivateCode($codeId: Int!, $message: String!) {
    locating {
      activateCode(codeId: $codeId, message: $message) {
        code {
          id
          name
        }
        activatedBy {
          id
          firstname
          lastname
          username
        }
        chat {
          id
          title
        }
        activatedAt
        createdAt
        updatedAt
      }
    }
  }
`;
