import gql from 'graphql-tag';
import {PaginatedUsersType} from 'src/types/PaginatedTypes';
import {OrganizationMemberFragment} from 'src/gql/v2/fragment/PaginatedFragment';

export type PaginatedUsers = {
  continuationId?: string | undefined;
  totalResultsCount?: number | undefined;
  users: PaginatedUsersType[] | undefined;
};

export type FetchPaginatedUsersQueryResponse = {
  organizationalUnitQuery: {
    organizationalUnit: {
      paginatedMembersInScopes: PaginatedUsers;
    };
  };
};

export type FetchTotalUserCountResponse = {
  organizationalUnitQuery: {
    organizationalUnit: {
      paginatedMembers: PaginatedUsers;
    };
  };
};

export const FETCH_PAGINATED_USERS_QUERY = gql`
  query FetchPaginatedUsersInScopes(
    $organizationalUnit: OrganizationalUnitInput!
    $scopes: [OrganizationalUnitInput!]
    $options: SearchUsersOptions
    $direction: PaginationDirection
    $continuationId: ID
  ) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on Error {
          __typename
          message
        }

        ... on Organization {
          paginatedMembersInScopes(
            scopes: $scopes
            options: $options
            continuationId: $continuationId
            direction: $direction
          ) {
            ...PaginatedUsersInScopeResultFragment
            __typename
          }
        }
      }
    }
  }

  fragment PaginatedUsersInScopeResultFragment on PaginatedUsersInScopeResult {
    ... on InvalidOrganizationScope {
      __typename
      message
    }
    ...PaginatedUsersInScopesFragment
  }

  fragment PaginatedUsersInScopesFragment on PaginatedUsersInScopes {
    continuationId
    totalResultsCount
    pageSize
    users {
      ...OrganizationMemberFragment
    }
  }
  ${OrganizationMemberFragment}
`;

export const FETCH_ORGANIZATION_USERS_COUNT = gql`
  query FetchOrganizationTotalResultCount($organizationalUnit: OrganizationalUnitInput!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        ... on Organization {
          paginatedMembers {
            totalResultsCount
          }
        }
      }
    }
  }
`;
