import {pathOr, path} from 'ramda';
import {Chat} from 'src/types';
import GetChatsQuery from 'src/gql/query/GetChatsQuery';
import CreateChatMutation from 'src/gql/mutation/CreateChatMutation';
import client from 'src/apollo';
import {User} from 'src/types';
import tryReadChatsQuery from 'src/utils/messengerHelper/tryReadCachedQuery';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {MESSENGER, LOCATING} from 'src/constants/routerPathName';

/**
 * - chatInfomation(settings)
 * - locating profile
 * - select contact list
 */
function getCurrentRouteToPageViewKeys(): string {
  if (window.location.pathname.includes(MESSENGER)) {
    if (window.location.pathname.includes(`${MESSENGER}/new`)) {
      return 'selectContactsList';
    }
    // window.location.pathname.split("/")[2] && window.location.pathname.split("/")[2].length >= 36
    return 'chatSettings';
  } else if (window.location.pathname.includes(LOCATING)) {
    return 'profileView';
  } else {
    return '';
  }
}

const createNewChat = async (selectedColleagues: User[]) => {
  try {
    const members: string[] = [];
    let newChat: Chat;

    selectedColleagues.forEach((colleague) => members.push(colleague.id));

    const result = await client.mutate({
      mutation: CreateChatMutation,
      variables: {members},
      update: async (store, {data}) => {
        const localChatsQuery = await tryReadChatsQuery();
        const chatsQuery = localChatsQuery ? localChatsQuery : (await client.query({query: GetChatsQuery})).data;

        const chats = pathOr([], ['chats', 'chats'], chatsQuery);
        newChat = path(['createChat'], data) as Chat;

        if (!chats.find((storedChat) => storedChat.id === newChat.id)) {
          chats.unshift(newChat);
          store.writeQuery({
            query: GetChatsQuery,
            data: chatsQuery,
          });
        }
      },
    });

    let routeKeyForAnalyticsPageView = getCurrentRouteToPageViewKeys();

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.chatCreated,
      params: {
        source: AnalyticsManager.pageViews[routeKeyForAnalyticsPageView],
        chat_id: result.data.createChat.id,
        chat_type: result.data.createChat.type,
      },
    });

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.openChat,
      params: {
        source: AnalyticsManager.pageViews[routeKeyForAnalyticsPageView],
        chat_id: result.data.createChat.id,
        chat_type: result.data.createChat.type,
      },
    });

    return result.data.createChat.id;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default createNewChat;
