import gql from 'graphql-tag';

export default gql`
  mutation UserJoinOrganization($organizationId: Int!) {
    user {
      joinOrganization(organizationId: $organizationId) {
        id
        name
      }
    }
  }
`;
