import * as React from 'react';
import {AppDispatch, typedUseSelector} from 'src/redux';
import {useDispatch} from 'react-redux';
import {ShiftRequestType} from 'src/types';
import RequestTypeCheckboxPicker from 'src/pages/SchedulingPage/schedule-layout/RequestTypeCheckboxPicker';

const FilterRequestType = () => {
  const offerTypes = typedUseSelector((state) => state.tradeShift.filterOfferTypes);
  const dispatch: AppDispatch = useDispatch();
  const handleSetOfferTypes = (type: ShiftRequestType) =>
    dispatch({type: 'tradeShift/SET_OFFER_TYPE', payload: {type}});

  return <RequestTypeCheckboxPicker offerTypes={offerTypes} handleSetOfferTypes={handleSetOfferTypes} />;
};

export default FilterRequestType;
