export const SSO_LOGIN_BUTTON_TEXT = 'Login with your organization account';
export const VERIFYING_AVAILABILITY = 'Verifying availability...';
export const NEXT = 'Next';

export const LOGIN_AUTHREGION_CA_ACCOUNT = "Your account doesn't exist in Canada";
export const LOGIN_AUTHREGION_USA_ACCOUNT = "Your account doesn't exist in the USA";
export const LOGIN_AUTHREGION_SWITCH_CA = 'Click here to switch to Canada region and try again';
export const LOGIN_AUTHREGION_SWITCH_USA = 'Click here to switch to the USA region and try again';

export const OR = 'or';

// errors

export const LOGIN_ERROR_CODE_INVALID_PASSWORD = 'InvalidPassword';
export const LOGIN_ERROR_MESSAGE_INVALID_PASSWORD = 'Invalid password. Please try again.';

export const LOGIN_ERROR_CODE_INVALID_USER = 'InvalidUsername';
export const LOGIN_ERROR_MESSAGE_INVALID_USER = 'User not found. Please try again.';

export const LOGIN_ERROR_CODE_ACCOUNT_LOCKED = 'AccountLocked';
export const LOGIN_ERROR_MESSAGE_ACCOUNT_LOCKED =
  'Account has been locked due to too many failed login attempts. Please contact Hypercare Support at +1 (877) 379-3533.';

export const LOGIN_ERROR_CODE_INVALID_CHALLENGE = 'InvalidChallenge';
export const LOGIN_ERROR_MESSAGE_INVALID_CHALLENGE = 'Token expired, please try again.';

export const LOGIN_PAGE_SSO_ERROR_MODAL_TITLE = 'Single Sign-On Error';
