import gql from 'graphql-tag';

export default gql`
  mutation JoinOrganizationByInviteCode($code: String!) {
    user {
      joinOrganizationWithInviteCode(code: $code) {
        id
        name
      }
    }
  }
`;
