const matchingTable = {
  'application/vnd.ms-excel': 'file_excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file_excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': 'file_excel',
  'application/vnd.ms-excel.sheet.macroEnabled.12': 'file_excel',
  'application/vnd.ms-excel.template.macroEnabled.12': 'file_excel',
  'application/vnd.ms-excel.addin.macroEnabled.12': 'file_excel',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12': 'file_excel',
  'application/msword': 'file_doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'file_doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': 'file_doc',
  'application/vnd.ms-word.document.macroEnabled.12': 'file_doc',
  'application/vnd.ms-word.template.macroEnabled.12': 'file_doc',
  'application/vnd.ms-powerpoint': 'file_presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'file_presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template': 'file_presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'file_presentation',
  'application/vnd.ms-powerpoint.addin.macroEnabled.12': 'file_presentation',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12': 'file_presentation',
  'application/vnd.ms-powerpoint.template.macroEnabled.12': 'file_presentation',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': 'file_presentation',
  'application/pdf': 'file_pdf',
  'application/json': 'file_generic',
  'video/mp4': 'file_video',
  'audio/mp3': 'file_audio',
  'video/quicktime': 'file_video',
  'audio/mpeg': 'file_audio',
  'text/plain': 'file_generic',
  'image/png': 'file_video',
  'image/jpeg': 'file_video',
  'image/gif': 'file_video',
  'image/tiff': 'file_video',
  'image/bmp': 'file_video',
};

export default matchingTable;
