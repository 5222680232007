import gql from 'graphql-tag';
import {shiftFragmentStringWithRole} from 'src/gql/fragment/ShiftFragment';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';
import ShiftRequestNoteFragment from 'src/gql/fragment/ShiftRequestNoteFragment';

export default gql`
  fragment ShiftRequestFragment on ShiftRequest {
    id
    desiredShift {
      ${shiftFragmentStringWithRole}
    }
    surrenderingShift {
      ${shiftFragmentStringWithRole}
    }
    postedBy {
      ...GeneralUserFragment
    }
    notes {
      ...ShiftRequestNoteFragment
    }
    type
    status
    createdAt
    updatedAt
  }
  ${GeneralUserFragment}
  ${ShiftRequestNoteFragment}
`;
