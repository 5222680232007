import {useEffect, useState} from 'react';
import ApiHelper from 'src/api';
import {HYPERCARE_REGION} from '../constants/storageKeys';
import {getParsedAuthRegion} from '../utils/localStorageHandler';
import {
  CANADA_ABBREVIATION,
  EUROPE_ABBREVIATION,
  UNITED_STATES_ABBREVIATION,
  UNITED_STATES_ABBREVIATION_2,
} from '../constants/strings';

export const useSetUserGeographicalLocation = () => {
  const [region, setRegion] = useState('');

  useEffect(() => {
    const getData = async () => {
      const current = getParsedAuthRegion();
      const result = await ApiHelper.PublicEndpoints.fetchUserGeographicalLocation();

      if (current) {
        setRegion(current);
      }

      if (!window.localStorage.getItem(HYPERCARE_REGION) && result?.data?.country_code) {
        const {country_code} = result.data;
        let newRegion;

        switch (country_code) {
          case CANADA_ABBREVIATION:
            newRegion = CANADA_ABBREVIATION;
            break;
          case UNITED_STATES_ABBREVIATION_2:
            newRegion = UNITED_STATES_ABBREVIATION;
            break;
          case EUROPE_ABBREVIATION:
            newRegion = EUROPE_ABBREVIATION;
            break;
          default:
            newRegion = CANADA_ABBREVIATION;
        }

        setRegion(newRegion);
        window.localStorage.setItem(HYPERCARE_REGION, newRegion);
      }

      if (!result || result.error) {
        setRegion(CANADA_ABBREVIATION);
      }
    };

    getData();
  }, []);

  return {region};
};
