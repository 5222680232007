import {toast} from 'react-toastify';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import client from 'src/apollo';
import {StatMessagePriority, UrgentMessagePriority} from 'src/constants/messageTypes';
import ArchiveChatMutation from 'src/gql/mutation/ArchiveChatMutation';
import UnarchiveChataMutation from 'src/gql/mutation/UnarchiveChataMutation';
import GetArchiveChatsQuery from 'src/gql/query/GetArchiveChatsQuery';
import GetChatsQuery from 'src/gql/query/GetChatsQuery';
import {Chat} from 'src/types';
import * as R from 'ramda';
import tryReadChatsQuery from 'src/utils/messengerHelper/tryReadCachedQuery';
import {ARCHIVED, MESSENGER} from 'src/constants/routerPathName';

export const archiveChat = async (e, chatId: string, selectedChat: Chat) => {
  e.stopPropagation();
  try {
    await client.mutate({
      mutation: ArchiveChatMutation,
      variables: {
        chatId,
      },
      update: async (store) => {
        updateLocalCache(true, chatId, store);
      },
    });

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.archivedChat,
      params: {
        chat_id: chatId,
        chat_type: selectedChat.type,
      },
    });
  } catch (e) {
    console.error(e);
    toast.error('Failed to archive the chat, please check your internet connection and try again');
  }
};

export const unArchiveChat = async (e, chatId: string, selectedChat: Chat) => {
  e.stopPropagation();
  try {
    await client.mutate({
      mutation: UnarchiveChataMutation,
      variables: {
        chatId,
      },
      update: async (store) => {
        updateLocalCache(false, chatId, store, selectedChat);
      },
    });

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.unarchivedChat,
      params: {
        chat_id: chatId,
        chat_type: selectedChat.type,
      },
    });
  } catch (e) {
    console.error(e);
    toast.error('Failed to unarchive the chat, please check your internet connection and try again');
  }
};

const updateLocalCache = (isArchive: boolean, chatId: string, store, selectedChat?: Chat) => {
  const ActualChatQuery = isArchive ? GetChatsQuery : GetArchiveChatsQuery;

  const chatsQuery = store.readQuery({
    query: ActualChatQuery,
  });
  const statPriorityChatsQuery = store.readQuery({
    query: ActualChatQuery,
    variables: {priority: StatMessagePriority},
  });
  const urgentPriorityChatsQuery = store.readQuery({
    query: ActualChatQuery,
    variables: {priority: UrgentMessagePriority},
  });

  if (chatsQuery && statPriorityChatsQuery && urgentPriorityChatsQuery) {
    const evolveScope = isArchive
      ? {
          chats: {
            chats: R.filter(R.complement(R.propEq('id', chatId))),
          },
        }
      : {
          archived_chats: {
            chats: R.filter(R.complement(R.propEq('id', chatId))),
          },
        };
    // np
    const newChatsQuery = R.evolve(evolveScope)(chatsQuery);
    client.writeQuery({
      query: ActualChatQuery,
      data: newChatsQuery,
    });
    // p
    const newStatPriorityChatsQuery = R.evolve(evolveScope)(statPriorityChatsQuery);
    client.writeQuery({
      query: ActualChatQuery,
      variables: {priority: StatMessagePriority},
      data: newStatPriorityChatsQuery,
    });

    const newUrgentPriorityChatsQuery = R.evolve(evolveScope)(urgentPriorityChatsQuery);
    client.writeQuery({
      query: ActualChatQuery,
      variables: {priority: UrgentMessagePriority},
      data: newUrgentPriorityChatsQuery,
    });

    window.routerHistory.push(`/${isArchive ? MESSENGER : ARCHIVED}/home`);

    if (!isArchive && selectedChat) {
      updateActiveChatList(store, selectedChat);

      if (
        newChatsQuery.archived_chats.chats.length === 0 &&
        newStatPriorityChatsQuery.archived_chats.chats.length === 0 &&
        newUrgentPriorityChatsQuery.archived_chats.chats.length === 0
      ) {
        window.routerHistory.push(`/${MESSENGER}/home`);
      }
    }
  }
};

const updateActiveChatList = async (store, selectedChat) => {
  const localChatsQuery = await tryReadChatsQuery();
  const activeChatsQuery = localChatsQuery ? localChatsQuery : (await client.query({query: GetChatsQuery})).data;
  const chats = R.pathOr([], ['chats', 'chats'], activeChatsQuery) as Chat[];
  chats.unshift(selectedChat);
  store.writeQuery({
    query: GetChatsQuery,
    data: activeChatsQuery,
  });
};
