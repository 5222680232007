import gql from 'graphql-tag';
import {ProfileFieldFragment, ProfileFieldFragmentType} from '../fragment/ProfileFieldFragment';

export type FetchSelfProfileCustomFieldsQueryResult = {
  selfQuery: {
    customFields: Array<{
      id: string;
      sectionId: string;
      label: string;
      labelOptions: string[];
      maxFields: number;
      sections: Array<{id: string; label: string}>;
      fields: Array<ProfileFieldFragmentType & {id: string}>;
      __typename: string;
    }>;
  };
};

export const FetchSelfProfileCustomFieldsQuery = gql`
  query FetchSelfProfileFields {
    selfQuery {
      customFields {
        id
        sectionId
        label
        labelOptions
        maxFields
        sections {
          id
          label
        }
        fields {
          ... on Node {
            id
          }
          ...ProfileFieldFragment
        }
        __typename
      }
    }
  }

  ${ProfileFieldFragment}
`;
