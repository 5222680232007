import {remoteConfigObject} from 'src/firebase/firebaseSetup';
import {fetchAndActivate, getValue} from 'firebase/remote-config';
import {
  EU_ORGANIZATION_TIMEOUT_VALUES,
  ORGANIZATION_TIMEOUT_VALUES,
  US_ORGANIZATION_TIMEOUT_VALUES,
} from 'src/constants/sessionTimeout';
import {getParsedAuthRegion} from 'src/utils/localStorageHandler';

const FirebaseRemoteConfigModule = () => {
  const getFirebaseParameter = () => {
    const currentAuthRegion = getParsedAuthRegion();

    switch (currentAuthRegion) {
      case 'CA':
        return ORGANIZATION_TIMEOUT_VALUES;
        break;
      case 'EU':
        return EU_ORGANIZATION_TIMEOUT_VALUES;
        break;
      case 'USA':
        return US_ORGANIZATION_TIMEOUT_VALUES;
        break;
      default:
        return ORGANIZATION_TIMEOUT_VALUES;
    }
  };

  const getDataFromRemoteConfig = async () => {
    const currentAuthRegionFirebaseParameter = getFirebaseParameter();

    try {
      const remoteConfig = remoteConfigObject();
      await fetchAndActivate(remoteConfig);
      const getOrganizationTimeoutObject = getValue(remoteConfig, currentAuthRegionFirebaseParameter);
      const parseOrganizationTimeoutObject = JSON.parse(getOrganizationTimeoutObject.asString());
      return parseOrganizationTimeoutObject;
    } catch (err) {
      console.error(err);
    }
  };

  const getOrganizationConfig = async (organizationId: number | string) => {
    const data = await getDataFromRemoteConfig();
    return data?.[organizationId];
  };

  return {getOrganizationConfig};
};

export default FirebaseRemoteConfigModule;
