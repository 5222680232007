import {HYPERCARE_REGION} from 'src/constants/storageKeys';

const generateSSOLink = () => {
  const localStoredRegion = localStorage.getItem(HYPERCARE_REGION);

  // NOTE: Backend is unable to run us-staging environments, auth0_us_configs.json only exist in prod
  let env = process.env.REACT_APP_ENV === 'production' ? 'prod' : 'staging';

  if (localStoredRegion && localStoredRegion === 'US')
    return `https://hypercare.github.io/public-uploads-prod/organization_configs/auth0_us_configs.json`;

  return `https://hypercare.github.io/public-uploads-${env}/organization_configs/auth0_config.json`;
};

export default generateSSOLink;
