import gql from 'graphql-tag';
import ChatFragment from 'src/gql/fragment/ChatFragment';
import PaginatedMessagesFragment from 'src/gql/fragment/PaginatedMessagesFragment';
import {CustomChatsEscalationFragment} from 'src/gql/fragment/ActiveEscalationFragment';
import {userFragmentString} from 'src/gql/fragment/GeneralUserFragment';

export const GetChatWithoutMessages = gql`
  query ChatQuery($chatId: ID!) {
    chat(chatId: $chatId) {
      id
      type
      title
      members {
        privilege
        ${userFragmentString}
      }
    }
  }
`;

export const GetChatMessages = gql`
  query ChatQuery($chatId: ID!, $messagesContinuationId: Int) {
    chat(chatId: $chatId) {
      messages(continuationId: $messagesContinuationId) {
        ...PaginatedMessagesFragment
      }
    }
  }
  ${PaginatedMessagesFragment}
`;

export const GetChatEscalations = gql`
  query ChatQuery($chatId: ID!) {
    chat(chatId: $chatId) {
      id
      escalation {
        ...CustomChatsEscalationFragment
      }
    }
  }
  ${CustomChatsEscalationFragment}
`;

export default gql`
  query ChatQuery($chatId: ID!, $messagesContinuationId: Int, $limit: Int) {
    chat(chatId: $chatId) {
      ...ChatFragment
      members {
        privilege
        ${userFragmentString}
      }
      messages(continuationId: $messagesContinuationId, limit: $limit) {
        ...PaginatedMessagesFragment
      }
      escalation {
        ...CustomChatsEscalationFragment
      }
    }
  }
  ${ChatFragment}
  ${CustomChatsEscalationFragment}
  ${PaginatedMessagesFragment}
`;
