import React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      fill="#757575"
      fillRule="nonzero"
      d="M18.127 9.09a8.177 8.177 0 0 0-7.218-7.217V0H9.091v1.873A8.177 8.177 0 0 0 1.873 9.09H0v1.818h1.873a8.177 8.177 0 0 0 7.218 7.218V20h1.818v-1.873a8.177 8.177 0 0 0 7.218-7.218H20V9.091h-1.873zM10 16.365A6.359 6.359 0 0 1 3.636 10 6.359 6.359 0 0 1 10 3.636 6.359 6.359 0 0 1 16.364 10 6.359 6.359 0 0 1 10 16.364z"
    />
  </svg>
);
