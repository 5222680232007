import gql from 'graphql-tag';

export default gql`
  fragment EscalatedUsersFragment on EscalatedUser {
    id
    user {
      id
      firstname
      lastname
    }
    escalatedAt
    acknowledged
    acknowledgedAt
  }
`;
