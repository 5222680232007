import {useMemo, useState} from 'react';

export type TimeRangeTypes = '1 hour' | '8 hours' | '12 hours' | '24 hours' | 'custom';

interface TimeFilter {
  type: TimeRangeTypes;
  customRange?: {
    startDate: string;
    endDate: string;
  };
}

export const defaultTimeFilter: TimeFilter = {type: '1 hour'};

export const useAuditLogFilters = (onApplyFilters: () => void) => {
  const [selectedEventIds, setSelectedEventIds] = useState<string[]>([]);
  const [selectedStatusIds, setSelectedStatusIds] = useState<string[]>([]);
  const [selectedSenderIds, setSelectedSenderIds] = useState<string[]>([]);
  const [selectedReceiverIds, setSelectedReceiverIds] = useState<string[]>([]);
  const [timeFilter, setTimeFilter] = useState<TimeFilter>(defaultTimeFilter);

  const clearAllFilters = () => {
    setSelectedEventIds([]);
    setSelectedStatusIds([]);
    setSelectedSenderIds([]);
    setSelectedReceiverIds([]);
    setTimeFilter(defaultTimeFilter);
  };

  const clearAllFiltersAndApply = () => {
    clearAllFilters();
    onApplyFilters();
  };

  const numFiltersSelected =
    selectedEventIds.length +
    selectedStatusIds.length +
    selectedSenderIds.length +
    selectedReceiverIds.length +
    (timeFilter.type === '1 hour' ? 0 : 1);

  const applyFilters = () => onApplyFilters();

  const hasAnyFilterSelected = useMemo(() => {
    return (
      selectedEventIds.length > 0 ||
      selectedStatusIds.length > 0 ||
      selectedSenderIds.length > 0 ||
      selectedReceiverIds.length > 0 ||
      timeFilter.type !== defaultTimeFilter.type
    );
  }, [selectedEventIds, selectedStatusIds, selectedSenderIds, selectedReceiverIds, timeFilter]);

  return {
    applyFilters,
    clearAllFilters,
    clearAllFiltersAndApply,
    selectedEventIds,
    setSelectedEventIds,
    selectedStatusIds,
    setSelectedStatusIds,
    selectedSenderIds,
    setSelectedSenderIds,
    selectedReceiverIds,
    setSelectedReceiverIds,
    timeFilter,
    setTimeFilter,
    hasAnyFilterSelected,
    numFiltersSelected,
  };
};
