import {HypercareLoginRepositoryProps} from 'src/pages/LoginPage/HypercareLoginRepository';
import ApiHelper from 'src/api';
import {AuthSSOVendors, SSOProfileType} from 'src/types';
import {SSO_VENDOR} from '../../../constants/storageKeys';

export type LoginAddressValues = {
  address: string;
  auth0Id?: string;
  workOS?: boolean;
};
export interface HypercareLoginAddressViewModelProps {
  repository: HypercareLoginRepositoryProps;
  nextButtonPressed: any;
  handleNextButtonPressed: ({address}: LoginAddressValues) => void;
  checkIfAddressIsSSO: (email: string) => Promise<{auth0Id?: string; error?: string}>;
  fetchSSOConfigurations: () => Promise<SSOProfileType[]>;
  completeAddressStep: (values: LoginAddressValues) => Promise<{auth0Id?: string; error?: string; email?: string}>;
}

class HypercareLoginAddressViewModel {
  nextButtonPressed;
  repository: HypercareLoginRepositoryProps;

  constructor(nextButtonPressed, repository) {
    this.nextButtonPressed = nextButtonPressed;
    this.repository = repository;
  }

  handleNextButtonPressed = (values: LoginAddressValues) => {
    this.nextButtonPressed(values);
  };

  completeAddressStep = async ({address}: LoginAddressValues) => {
    const isEmailSSO = await this.checkIfAddressIsSSO(address);
    sessionStorage.setItem('email', address);

    if (isEmailSSO?.error) {
      return {error: isEmailSSO.error};
    }

    if (isEmailSSO?.auth0Id && isEmailSSO?.ssoVendor) {
      localStorage.setItem(SSO_VENDOR, isEmailSSO?.ssoVendor);

      if (isEmailSSO.ssoVendor === AuthSSOVendors.WORKOS) {
        await this.getSSOAuthorizationUrl(isEmailSSO.auth0Id, isEmailSSO.ssoVendor);
        this.handleNextButtonPressed({address: '', workOS: true});
      } else {
        this.handleNextButtonPressed({auth0Id: isEmailSSO.auth0Id, address: ''});
      }
    } else {
      this.handleNextButtonPressed({address});
    }
  };

  checkIfAddressIsSSO = async (email: string) => {
    const ssoProfileResult = await this.repository.checkIfAddressIsSSO(email);

    if (ssoProfileResult?.success && ssoProfileResult.data?.ssoProfileForUser) {
      const {auth0Id, ssoId, ssoVendor} = ssoProfileResult.data.ssoProfileForUser;
      return {auth0Id, ssoId, ssoVendor};
    }

    if (ssoProfileResult?.error) {
      let error = ssoProfileResult.error?.response?.data?.errors[0]?.message || ssoProfileResult.error[0]?.message;
      return {error};
    }
  };

  fetchSSOConfigurations = async () => {
    const result = await this.repository.fetchSSOConfiguration();

    const arrUniq = [...new Map(result.map((SSOProfile) => [SSOProfile.auth0Id, SSOProfile])).values()];

    return arrUniq;
  };

  getSSOAuthorizationUrl = async (connectionId: string, provider: AuthSSOVendors) => {
    const result = await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(connectionId, provider);
    return result;
  };
}

export default HypercareLoginAddressViewModel;
