import * as React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14">
    <defs>
      <filter id="a9m6o328xa">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 0.244095 0 0 0 0 0.331081 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" filter="url(#a9m6o328xa)" transform="translate(-1094 -14)">
      <path
        fill="#212B36"
        d="M1102.425 21l4.405-4.405c.273-.274.273-.716 0-.99-.274-.274-.716-.274-.99 0l-4.405 4.405-4.405-4.405c-.274-.274-.716-.274-.99 0s-.274.716 0 .99l4.405 4.405-4.405 4.405c-.274.274-.274.716 0 .99.136.136.316.205.495.205.18 0 .358-.069.495-.205l4.405-4.405 4.405 4.405c.136.136.316.205.495.205.18 0 .358-.069.495-.205.273-.274.273-.716 0-.99L1102.425 21z"
      />
    </g>
  </svg>
);

export const Closemark = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.475 1.575C11.7649 1.28505 11.7649 0.814949 11.475 0.525C11.1851 0.23505 10.715 0.235051 10.425 0.525L6 4.95L1.575 0.525C1.28505 0.235051 0.814949 0.23505 0.525 0.525C0.23505 0.814949 0.235051 1.28505 0.525 1.575L4.95 6L0.525 10.425C0.235051 10.7149 0.23505 11.1851 0.525 11.475C0.814949 11.7649 1.28505 11.7649 1.575 11.475L6 7.05L10.425 11.475C10.7149 11.7649 11.1851 11.7649 11.475 11.475C11.7649 11.1851 11.7649 10.715 11.475 10.425L7.05 6L11.475 1.575Z"
        fill="#222222"
      />
    </svg>
  );
};
