import React from 'react';
import ContentLoader from 'react-content-loader';

const ChatListLoader = (props) => {
  return (
    <ContentLoader backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props} speed={1}>
      <rect x="8" y="8" rx="3" ry="3" width="380" height="45" />
      <circle cx="39" cy="125" r="32" />
      <rect x="81" y="97" rx="0" ry="0" width="194" height="16" />
      <rect x="83" y="139" rx="0" ry="0" width="143" height="16" />
      <rect x="292" y="97" rx="0" ry="0" width="93" height="16" />
      <circle cx="41" cy="211" r="32" />
      <rect x="83" y="183" rx="0" ry="0" width="194" height="16" />
      <rect x="85" y="225" rx="0" ry="0" width="143" height="16" />
      <rect x="294" y="183" rx="0" ry="0" width="93" height="16" />
      <circle cx="42" cy="295" r="32" />
      <rect x="84" y="267" rx="0" ry="0" width="194" height="16" />
      <rect x="86" y="309" rx="0" ry="0" width="143" height="16" />
      <rect x="295" y="267" rx="0" ry="0" width="93" height="16" />
      <circle cx="44" cy="381" r="32" />
      <rect x="86" y="353" rx="0" ry="0" width="194" height="16" />
      <rect x="88" y="395" rx="0" ry="0" width="143" height="16" />
      <rect x="297" y="353" rx="0" ry="0" width="93" height="16" />
      <circle cx="43" cy="467" r="32" />
      <rect x="85" y="439" rx="0" ry="0" width="194" height="16" />
      <rect x="87" y="481" rx="0" ry="0" width="143" height="16" />
      <rect x="296" y="439" rx="0" ry="0" width="93" height="16" />
      <circle cx="45" cy="553" r="32" />
      <rect x="87" y="525" rx="0" ry="0" width="194" height="16" />
      <rect x="89" y="567" rx="0" ry="0" width="143" height="16" />
      <rect x="298" y="525" rx="0" ry="0" width="93" height="16" />
      <circle cx="46" cy="637" r="32" />
      <rect x="88" y="609" rx="0" ry="0" width="194" height="16" />
      <rect x="90" y="651" rx="0" ry="0" width="143" height="16" />
      <rect x="299" y="609" rx="0" ry="0" width="93" height="16" />
      <circle cx="48" cy="723" r="32" />
      <rect x="90" y="695" rx="0" ry="0" width="194" height="16" />
      <rect x="92" y="737" rx="0" ry="0" width="143" height="16" />
      <rect x="301" y="695" rx="0" ry="0" width="93" height="16" />
    </ContentLoader>
  );
};

export default ChatListLoader;
