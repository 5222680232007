import React, {Component} from 'react';
import {FILETYPES, FILETYPEARRAY} from 'src/constants/fileUpload';
import {toast} from 'react-toastify';

interface Props {
  onSelectFiles: (files: File[]) => void;
  allowedFileTypes?: string;
  allowedFileTypesArray?: Array<string>;
  className?: string;
}
class FileSelector extends Component<Props> {
  allowedFileTypes = this.props.allowedFileTypes || FILETYPES;

  public onChange = (e) => {
    const selectorFiles: FileList = e.target.files,
      allowedFileTypesArray = this.props.allowedFileTypesArray || FILETYPEARRAY;

    let fileListArray: File[] = Array.from(selectorFiles);
    let unSupportedFormatFlag = false;
    let errorFileNameList: String[] = [];

    fileListArray.forEach((file: File) => {
      if (!allowedFileTypesArray.includes(file.type)) {
        unSupportedFormatFlag = true;
        errorFileNameList.push(file.name);
      }
    });

    const fileteredFileArray = fileListArray.filter((file) => -1 !== allowedFileTypesArray.indexOf(file.type));

    if (unSupportedFormatFlag) {
      toast.error(`These files are not supported: ${errorFileNameList.join(', ')}`, {
        autoClose: false,
      });
    }

    this.props.onSelectFiles(fileteredFileArray);
    e.target.value = null;
  };

  public render() {
    return (
      <input
        type="file"
        multiple
        accept={this.allowedFileTypes}
        onChange={this.onChange}
        className={this.props.className}
      />
    );
  }
}

export default FileSelector;
