import gql from 'graphql-tag';
import {OrganizationLoginMethods} from '../../../types';

export type FetchOrganizationByNameResponse = {
  data: {
    findOrganizationByName: {
      __typename: string;
      limit: number;
      offset: number;
      pageSize: number;
      total: number;
      organizations: {
        name: string;
        imageUrl: string;
        url: string;
        loginMethods: OrganizationLoginMethods[];
        __typename: string;
      }[];
    };
  };
};

export const FETCH_ORGANIZATION_BY_NAME = gql`
  query GetOrganizationByName($name: String!, $offset: Int, $limit: Int) {
    findOrganizationByName(name: $name, offset: $offset, limit: $limit) {
      ... on PaginatedOrganizations {
        ...FindOrganizationResultFragment
      }
    }
  }
  fragment FindOrganizationResultFragment on PaginatedOrganizations {
    __typename
    limit
    offset
    pageSize
    total
    organizations {
      ...OrganizationFragment
    }
  }

  fragment OrganizationFragment on Organization {
    __typename
    name
    imageUrl
    url
    loginMethods
  }
`;
