import React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" transform="scale(-1 1)">
    <path
      fill={props.fill || '#FF3E55'}
      d="M2.3 17.75V6.25h1.5v11.5Zm13.75-.1L15 16.6l3.85-3.85H6.3v-1.5h12.525l-3.8-3.85 1.025-1.05L21.7 12Z"
    />
  </svg>
);
