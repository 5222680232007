import {useQuery} from 'react-apollo';
import {FetchScopesQuery} from 'src/gql/query/FetchScopesQuery';

export const GetOrganizationScope = () => {
  const {data} = useQuery(FetchScopesQuery, {
    fetchPolicy: 'no-cache',
  });

  return data;
};
