import getParsedAuthInfo from 'src/utils/localStorageHandler';
import client from 'src/apollo';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';
import SelfUserFragment from 'src/gql/fragment/SelfUserFragment';

export default (userId?: string) => {
  let selfID;
  let fragment;
  if (userId) {
    selfID = userId;
    fragment = GeneralUserFragment;
  } else {
    const parsedAuthInfo = getParsedAuthInfo();
    selfID = parsedAuthInfo && parsedAuthInfo.user.id;
    fragment = SelfUserFragment;
  }
  const currentUserFragment = client.readFragment({
    id: `User:${selfID}`,
    fragment,
  });
  return currentUserFragment;
};
