import gql from 'graphql-tag';

export default gql`
  query FetchUnreadCount {
    me {
      id
      unreadChatCount {
        count
        organizationId
      }
    }
  }
`;
