import axiosConfig from './AxiosConfig';
import PrivateEndpoints from './PrivateEndpoints';
import PublicEndpoints from './PublicEndpoints';

axiosConfig();

export default {
  PrivateEndpoints,
  PublicEndpoints,
};
