import * as R from 'ramda';
import {ApolloClient} from 'apollo-client';
import GeneralUserFragment from 'src/gql/fragment/GeneralUserFragment';
import {ReadReceipt, ReadReceiptFromWs} from 'src/types';
import {User} from 'src/types';

function tryReadUser(userId: string, client: ApolloClient<any>) {
  try {
    return client.readFragment({
      id: `User:${userId}`,
      fragment: GeneralUserFragment,
    });
  } catch {
    return null;
  }
}

export default function (readReceiptPayload: ReadReceiptFromWs, client: ApolloClient<any>): ReadReceipt {
  const {userId, messageId} = readReceiptPayload;

  const user: User = tryReadUser(userId, client);
  const now = new Date();

  const newReadReceipt = {
    messageId: Number(messageId),
  };

  return R.pipe(
    R.assoc('user', user),
    R.assoc('timestamp', now.toISOString()),
    R.assoc('__typename', 'ReadReceipt'),
  )(newReadReceipt);
}
