import {useMutation, useQuery} from 'react-apollo';
import {
  CreateSelfProfileFieldsMutation,
  CreateSelfProfileFieldsMutationResult,
} from 'src/gql/v2/mutation/CreateSelfProfileCustomFieldsMutation';
import {
  CreateSelfProfileCustomFieldsSectionMutation,
  CreateSelfProfileCustomFieldsSectionMutationResult,
} from 'src/gql/v2/mutation/CreateSelfProfileCustomFieldsSection';
import {
  DeleteSelfProfileCustomFieldMutation,
  DeleteSelfProfileCustomFieldMutationResult,
} from 'src/gql/v2/mutation/DeleteSelfProfileCustomFieldMutation';
import {
  UpdateSelfProfileCustomFieldMutation,
  UpdateSelfProfileCustomFieldMutationResult,
} from 'src/gql/v2/mutation/UpdateSelfProfileCustomFieldMutation';
import {
  FetchOrgProfileFieldsSchemaQuery,
  FetchOrgProfileFieldsSchemaQueryResult,
} from 'src/gql/v2/query/FetchOrgProfileFieldsSchemaQuery';
import {
  FetchSelfProfileCustomFieldsQuery,
  FetchSelfProfileCustomFieldsQueryResult,
} from 'src/gql/v2/query/FetchSelfProfileCustomFieldsQuery';
import {
  CreateProfileFieldInput,
  CreateProfileSectionInput,
  OrganizationalUnitInputType,
  UpdateProfileFieldInput,
  UserIdInputType,
} from 'src/gql/v2/types/input';
import {getOrganizationalUnitObject} from 'src/utils/organizationHelper/getOrganizationalUnitObject';
import {
  FetchUserProfileCustomFieldsQueryResult,
  FetchUserProfileCustomFieldsQuery,
} from 'src/gql/v2/query/FetchUserProfileCustomFieldsQuery';
type FetchSelfProfileFieldsError = null | 'networkError' | 'anotherError(complete me)';
type FetchUserProfileFieldsError = null | 'networkError' | 'anotherError(complete me)';
type FetchProfileFieldsSchemaError = null | 'networkError' | 'anotherError(complete me)';
type CreateProfileFieldsSectionError = null | 'networkError' | 'anotherError(complete me)';
type CreateProfileFieldsError = null | 'networkError' | 'anotherError(complete me)';
type UpdateProfileFieldError = null | 'networkError' | 'anotherError(complete me)';
type DeleteProfileFieldError = null | 'networkError' | 'anotherError(complete me)';

export const CustomFieldsRepository = () => {
  const useFetchSelfProfileFields = () => {
    const result = useQuery<FetchSelfProfileCustomFieldsQueryResult>(FetchSelfProfileCustomFieldsQuery, {
      fetchPolicy: 'no-cache',
    });

    let error: FetchSelfProfileFieldsError = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const useFetchUserProfileFields = (userId: string) => {
    const result = useQuery<FetchUserProfileCustomFieldsQueryResult, OrganizationalUnitInputType & UserIdInputType>(
      FetchUserProfileCustomFieldsQuery,
      {
        fetchPolicy: 'no-cache',
        variables: {
          organizationalUnit: getOrganizationalUnitObject(),
          userId,
        },
      },
    );

    let error: FetchUserProfileFieldsError = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const useFetchProfileFieldsSchema = () => {
    const result = useQuery<FetchOrgProfileFieldsSchemaQueryResult, OrganizationalUnitInputType>(
      FetchOrgProfileFieldsSchemaQuery,
      {
        fetchPolicy: 'no-cache',
        variables: {
          organizationalUnit: getOrganizationalUnitObject(),
        },
      },
    );

    let error: FetchProfileFieldsSchemaError = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const useCreateProfileFieldsSection = () => {
    const result = useMutation<CreateSelfProfileCustomFieldsSectionMutationResult, CreateProfileSectionInput>(
      CreateSelfProfileCustomFieldsSectionMutation,
      {
        fetchPolicy: 'no-cache',
      },
    );

    let error: CreateProfileFieldsSectionError = null;

    if (result[1]?.error?.networkError) {
      error = 'networkError';
    }

    return {
      createProfileFieldsSection: result[0],
      ...result[1],
      error,
    };
  };

  const useCreateProfileFields = () => {
    const result = useMutation<
      CreateSelfProfileFieldsMutationResult,
      {sectionUniqueId: string; fields: CreateProfileFieldInput[]}
    >(CreateSelfProfileFieldsMutation, {
      fetchPolicy: 'no-cache',
    });

    let error: CreateProfileFieldsError = null;

    if (result[1]?.error?.networkError) {
      error = 'networkError';
    }

    return {
      createProfileFields: result[0],
      ...result[1],
      error,
    };
  };

  const useUpdateProfileField = () => {
    const result = useMutation<
      UpdateSelfProfileCustomFieldMutationResult,
      {uniqueFieldId: string; fieldInput: UpdateProfileFieldInput}
    >(UpdateSelfProfileCustomFieldMutation, {
      fetchPolicy: 'no-cache',
    });

    let error: UpdateProfileFieldError = null;

    if (result[1]?.error?.networkError) {
      error = 'networkError';
    }

    return {
      updateProfileField: result[0],
      ...result[1],
      error,
    };
  };

  const useDeleteProfileField = () => {
    const result = useMutation<DeleteSelfProfileCustomFieldMutationResult, {fieldId: string}>(
      DeleteSelfProfileCustomFieldMutation,
      {
        fetchPolicy: 'no-cache',
      },
    );

    let error: DeleteProfileFieldError = null;

    if (result[1]?.error?.networkError) {
      error = 'networkError';
    }

    return {
      deleteProfileField: result[0],
      ...result[1],
      error,
    };
  };

  return {
    useFetchSelfProfileFields,
    useFetchUserProfileFields,
    useFetchProfileFieldsSchema,
    useCreateProfileFieldsSection,
    useCreateProfileFields,
    useUpdateProfileField,
    useDeleteProfileField,
  };
};
