import {SearchMessagesChat} from 'src/types';
import getParsedAuthInfo from 'src/utils/localStorageHandler';

export const getTotalMembers = (chat: SearchMessagesChat, charLimit: number, isTitle: boolean) => {
  const {members, title} = chat;
  if (title && isTitle) {
    return title.substring(0, Math.min(title.length, charLimit + 6)) + (title.length < 20 ? '' : '...');
  } else {
    const parsedAuthInfo = getParsedAuthInfo();
    const selfID = parsedAuthInfo ? parsedAuthInfo.user.id : null;
    const allMembers = members
      .filter((val, index) => index < 2)
      .map((member, i) => {
        return selfID === member.id ? 'You' : member.firstName + ' ' + member.lastName;
      });
    const remainingMembers = members.length - allMembers.length;
    const remaining = remainingMembers > 0 ? `, ${remainingMembers}+` : '';
    const member = allMembers.join(', ');

    if (member.length > charLimit) {
      return member.substring(0, Math.min(member.length, charLimit)) + `...${remaining}`;
    }

    return member.substring(0, Math.min(member.length, charLimit)) + `${remaining}`;
  }
};
