import gql from 'graphql-tag';

export type ChangeAddressLabelMutationResponse = {
  data: {
    self: {
      updateAddress: {
        updateLabel: boolean;
      };
    };
  };
};

export const CHANGE_ADDRESS_LABEL_MUTATION = gql`
  mutation UpdateLabelAccess($address: String!, $addressType: AddressType!, $addressLabel: AddressLabel!) {
    self {
      updateAddress(address: $address, type: $addressType) {
        updateLabel(label: $addressLabel)
      }
    }
  }
`;
