import * as R from 'ramda';
import gql from 'graphql-tag';
import ChatFragment from 'src/gql/fragment/ChatFragment';
import {Chat} from 'src/types';
import {userFragmentString} from 'src/gql/fragment/GeneralUserFragment';

export default gql`
  query ArchiveChatsQuery(
    $priority: MessagePriority = regular
    $continuationId: ID
  ) {
    archived_chats: archivedChats(
      priority: $priority
      continuationId: $continuationId
    ) @connection(key: "archived_chats", filter: ["priority"]) {
      continuationId
      chats {
        ...ChatFragment
        members {
          privilege
          ${userFragmentString}
        }
      }
    }
  }
  ${ChatFragment}
`;

export const archivedChatPath = ['archived_chats', 'chats'];

export const getArchivedChats = (chatsQuery: any): Chat[] => {
  return R.pathOr([], archivedChatPath, chatsQuery) as Chat[];
};
