import React from 'react';

export const CloseButtonV2 = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1562_465)">
      <path
        d="M17.475 7.575C17.7649 7.28505 17.7649 6.81495 17.475 6.525C17.1851 6.23505 16.715 6.23505 16.425 6.525L12 10.95L7.575 6.525C7.28505 6.23505 6.81495 6.23505 6.525 6.525C6.23505 6.81495 6.23505 7.28505 6.525 7.575L10.95 12L6.525 16.425C6.23505 16.7149 6.23505 17.1851 6.525 17.475C6.81495 17.7649 7.28505 17.7649 7.575 17.475L12 13.05L16.425 17.475C16.7149 17.7649 17.1851 17.7649 17.475 17.475C17.7649 17.1851 17.7649 16.715 17.475 16.425L13.05 12L17.475 7.575Z"
        fill="#000000"
      />
    </g>
    <defs>
      <clipPath id="clip0_1562_465">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
