import gql from 'graphql-tag';
import {UpdateAddressPreferenceResultFragment} from 'src/gql/v2/fragment/UpdateAddressPreferenceResultFragment';

export const UPDATE_ADDRESS_PREFERENCE = gql`
  mutation UpdateAddressPreference($address: [String]!) {
    selfMutation {
      updateAddressPreference(address: $address) {
        __typename
        ... on Error {
          __typename
          message
        }
        ...UpdateAddressPreferenceResultFragment
        __typename
      }
    }
  }
  ${UpdateAddressPreferenceResultFragment}
`;
