import gql from 'graphql-tag';

export const MESSAGES_DELIVERED_WO_CHATID_MUTATION = gql`
  mutation DeliveredMessagesWithoutChatId($messageIds: [Int!]!) {
    chatsMutation {
      __typename
      ... on ChatsMutation {
        messagesDelivered(ids: $messageIds) {
          __typename
          ... on AsyncResponse {
            requestId
          }
          ... on Error {
            message
          }
        }
      }
    }
  }
`;
