import React from 'react';
import {
  EscalationLadderPreviewWrapper,
  OverrideEscalationLadderPreviewWrapper,
  EscalationLadderPreviewInfoHolder,
  EscalationType,
  EscalationBannerTitle,
  EscalationLadderSubTextHolder,
} from 'src/styles/styled-components/Escalation';

interface Props {
  type: 'department' | 'individual';
  isInChat: boolean;
  isActive: boolean;
  departmentName: string;
  message: string;
  handleOnClick: () => void;
}

const ActiveEscalationPlaceholderBanner = ({
  type,
  isActive,
  message,
  isInChat,
  departmentName,
  handleOnClick,
}: Props) => {
  const DynamicWrapper = isInChat ? OverrideEscalationLadderPreviewWrapper : EscalationLadderPreviewWrapper;
  return (
    <DynamicWrapper $isActive={isActive} onClick={handleOnClick}>
      <EscalationLadderPreviewInfoHolder>
        <EscalationType>
          {type === 'department' ? 'DEPARTMENT' : 'INDIVIDUAL'} ESCALATION
          {type === 'department' && ` - ${departmentName}`}
        </EscalationType>
        <EscalationBannerTitle>{message}</EscalationBannerTitle>
        <EscalationLadderSubTextHolder>
          {isActive ? 'Starting escalation...' : 'Escalation has ended'}
        </EscalationLadderSubTextHolder>
      </EscalationLadderPreviewInfoHolder>
    </DynamicWrapper>
  );
};

export default ActiveEscalationPlaceholderBanner;
