import gql from 'graphql-tag';

export const UserFragment = gql`
  fragment UserFragment on PublicUser {
    id
    firstname
    lastname
    username
  }
`;

export const AddressFragment = gql`
  fragment AddressFragment on Address {
    id
    address
    type
    ordinal
    access
    verified
    organizationId
  }
`;
export const OrganizationFragment = gql`
  fragment OrganizationFragment on Organization {
    id
    name
  }
`;

export default gql`
  fragment UserInviteFragment on UserInvite {
    id
    user {
      ...UserFragment
    }
    address {
      ...AddressFragment
    }
    organization {
      ...OrganizationFragment
    }
    invitedBy {
      ...UserFragment
    }
    history {
      action
      createdAt
    }
    status
    inviteStatus
    createdAt
    updatedAt
  }
  ${UserFragment}
  ${AddressFragment}
  ${OrganizationFragment}
`;
